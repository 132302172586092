import {
  adminGOIDsFetcher,
  cloudAccountsFetcher,
  regionsFetcher,
  resourceGroupsFetcher,
  subscriptionsFetcher,
  virtualNetworksFetcher,
} from "state/cluster/services/create";
import clusterFormActions from "../form";

export function selectVirtualNetwork(vnetName) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vnetName,
          vnetCidrBlock: "",
          controlPlaneSubnet: "",
          workerSubnet: "",
        },
      })
    );
  };
}

export function selectSubscription(subscriptionId) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          subscriptionId,
          region: "",
          resourceGroup: "",
          vnetName: "",
          vnetCidrBlock: "",
          controlPlaneSubnet: "",
          workerSubnet: "",
        },
      })
    );

    dispatch(regionsFetcher.fetch());
  };
}

function onChangeStaticPlacement(value) {
  return (dispatch) => {
    if (!value) {
      dispatch(
        clusterFormActions.batchChange({
          updates: {
            vnetName: undefined,
            vnetCidrBlock: undefined,
            controlPlaneSubnet: undefined,
            workerSubnet: undefined,
          },
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "vnetName",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "vnetCidrBlock",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "controlPlaneSubnet",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "workerSubnet",
          module: "cluster",
        })
      );
    }
  };
}

function selectCredentialEffect() {
  return (dispatch, getState) => {
    const cloudAccounts = cloudAccountsFetcher.selector(getState())?.result;
    const credential = getState().forms?.cluster?.data?.credential;
    const selectedCloudAccount = cloudAccounts?.find(
      (ca) => ca.metadata.uid === credential
    );

    const hasTenantName = selectedCloudAccount?.spec?.tenantName;

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          region: "",
          subscriptionId: "",
          vnetName: "",
          vnetCidrBlock: "",
          resourceGroup: "",
          hasTenantName,
          managedAD: true,
          adminGroupObjectIDs: [],
        },
      })
    );

    hasTenantName && dispatch(adminGOIDsFetcher.fetch());
    dispatch(subscriptionsFetcher.fetch());
  };
}

function selectRegionEffect() {
  return function (dispatch) {
    dispatch(virtualNetworksFetcher.fetch());
    dispatch(resourceGroupsFetcher.fetch());
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vnetName: "",
          vnetCidrBlock: "",
          resourceGroup: "",
          controlPlaneSubnet: "",
          workerSubnet: "",
        },
      })
    );
  };
}

const effects = {
  onChangeStaticPlacement,
  selectCredentialEffect,
  selectRegionEffect,
};

export default effects;
