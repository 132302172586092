import React from "react";
import { Route } from "react-router";

import createNamespace from "services/language";
import { AUTH } from "utils/constants/routes";
const Root = React.lazy(() => import("./index"));
const SetPassword = React.lazy(() => import("./flows/SetPassword"));
const ForgotPassword = React.lazy(() => import("./flows/ForgotPassword"));
const PasswordUpdate = React.lazy(() => import("./flows/PasswordUpdate"));
const Register = React.lazy(() => import("./flows/Register"));

export default createNamespace({
  namespace: "auth",
  Component: function Main() {
    return (
      <>
        <Route exact path={AUTH.ROOT} component={Root} />
        <Route exact path={AUTH.SIGNUP} component={Register} />
        <Route path={AUTH.ACTIVATE_PASSWORD} component={SetPassword} />
        <Route path={AUTH.RESET_PASSWORD} component={SetPassword} />
        <Route path={AUTH.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={AUTH.UPDATE_PASSWORD} component={PasswordUpdate} />
      </>
    );
  },
});
