import { v4 as uuid } from "uuid";
import api from "services/api";
import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import * as YAML from "yaml";

import {
  PackSchema,
  RepositorySchema,
  HelmRepositorySchema,
  PackVersionSchema,
  HelmRegistriesSchema,
  OCIRegistriesSchema,
} from "utils/schemas";
import { createContext } from "react";
import i18next from "i18next";
import notifications from "services/notifications";
import Validator from "services/validator";
import { generateEditorYamlSchema } from "utils/presenters";
import { ApplyIf, Missing } from "services/validator/rules";

export const ProfileBuilderContext = createContext();

export const PROFILE_TYPES_FORM_MODULES = {
  cluster: "clusterprofile",
  infra: "clusterprofile",
  "add-on": "clusterprofile",
  system: "systemProfile",
};

export const MODULES = {
  PACK_MODULE: "layerPack",
  IMPORT_MODULE: "importedPacks",
  MANIFEST_MODULE: "manifest",
  PACKS_EDITOR_MODULE: "packsEditor",
  PACK_LIST_MODULE: "packsList",
  SYSTEM_MODULE: "systemPacks",
};

export const FORM_TYPES = {
  PACK: MODULES.PACK_MODULE,
  IMPORT: MODULES.IMPORT_MODULE,
  MANIFEST: MODULES.MANIFEST_MODULE,
  SYSTEM: MODULES.SYSTEM_MODULE,
};

export const clusterPacksFetcher = keyedDataFetcher({
  selectors: ["selectedCluster"],
  async fetchData([_, selectedClusterId]) {
    try {
      const response = await api.get(
        `v1/spectroclusters/${selectedClusterId}/features/helmCharts`
      );
      return response.charts?.reduce((acc, layer) => {
        return [
          ...acc,
          {
            guid: uuid(),
            type: "helmChart",
            isDraft: true,
            formType: MODULES.PACK_MODULE,
            matchedRegistries: layer.matchedRegistries,
            config: {
              registryUid:
                layer.matchedRegistries.length === 1
                  ? layer.matchedRegistries[0]?.uid
                  : null,
              name: layer.name,
              packUid: layer.uid,
              uid: layer.uid,
              tag: layer.version,
              values: layer.values || "",
              selectedPresets: [],
              schema: [],
              manifests: [],
            },
          },
        ];
      }, []);
    } catch (e) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while retrieving the helm charts"
        ),
        description: e.message,
      });
    }
  },
});

export const repositoriesFetcher = dataFetcher({
  selectors: ["repositories"],
  schema: [RepositorySchema],
  async fetchData() {
    const response = await api.get("v1/registries/pack");
    return response?.items || [];
  },
});

export const helmRepositoriesFetcher = dataFetcher({
  selectors: ["helmRepositories"],
  schema: [HelmRepositorySchema],
  async fetchData() {
    const response = await api.get("v1/registries/helm");
    return response?.items || [];
  },
});

export const ociRepositoriesFetcher = dataFetcher({
  selectors: ["ociRepositories"],
  schema: [OCIRegistriesSchema],
  async fetchData() {
    const response = await api.get("v1/registries/oci/summary");
    return response?.items || [];
  },
});

async function fetchPackItems({ filter, sort, continueToken, limit }) {
  const response = await api.post(
    `v1/packs/search?limit=${limit}${
      continueToken ? `&continue=${continueToken}` : ""
    }`,
    { filter, sort }
  );

  return (
    response?.items?.sort((packA, packB) => {
      const packADisabled =
        packA.spec?.registries?.[0]?.annotations?.disabled === "true";
      const packBDisabled =
        packB.spec?.registries?.[0]?.annotations?.disabled === "true";
      return packADisabled - packBDisabled;
    }) || []
  );
}

export const packsListSearchFetcher = dataFetcher({
  selectors: [
    "packsListSearch",
    (state) => state.forms.clusterprofile?.data?.cloudType,
    (state) => state.forms.layerPack?.data?.packType,
    (state) => state.forms.layerPack?.data?.repository,
  ],
  async fetchData(
    [_, cloudType, layerType, registryUid],
    { limit, continue: continueToken, search }
  ) {
    const isAddon = !["os", "cni", "csi", "k8s"].includes(layerType);
    const layer = isAddon ? "addon" : layerType;
    const filter = {
      displayName: {
        contains: search,
      },
      ignoreCase: true,
      type: [layerType === "helmChart" ? "helm" : "spectro"],
      layer: [layer],
      environment: [isAddon ? "all" : cloudType],
      addOnType: isAddon ? [layerType] : undefined,
      registryUid: [registryUid],
    };
    const sort = [
      {
        field: "displayName",
        order: "asc",
      },
    ];

    const items = await fetchPackItems({
      filter,
      sort,
      limit,
      continueToken,
    });

    return { items, cloudType };
  },
});

export const systemChartNamesFetcher = dataFetcher({
  selectors: [
    "systemPacksListSearch",
    (state) => state.forms[PROFILE_TYPES_FORM_MODULES.system]?.data?.cloudType,
    (state) => state.forms[MODULES.SYSTEM_MODULE]?.data?.repository,
  ],
  async fetchData(
    [_, cloudType, registryUid],
    { limit, continue: continueToken, search }
  ) {
    const filter = {
      displayName: {
        contains: search,
      },
      ignoreCase: true,
      type: ["helm"],
      layer: ["addon"],
      environment: ["all"],
      addOnType: ["helmChart"],
      registryUid: [registryUid],
    };
    const sort = [
      {
        field: "displayName",
        order: "asc",
      },
    ];
    const items = await fetchPackItems({
      sort,
      filter,
      limit,
      continueToken,
    });

    return { items, cloudType };
  },
});

export const packVersionsFetcher = dataFetcher({
  selectors: [
    "packVersions",
    (state) => state.forms.clusterprofile?.data?.cloudType,
  ],
  schema: [PackVersionSchema],
  async fetchData([_, cloudType], { repositoryUid, packName, layerType }) {
    const isBasicLayer = ["os", "cni", "csi", "k8s"].includes(layerType);

    const promise = api.get(
      `v1/packs/${packName}/registries/${repositoryUid}?cloudType=${
        isBasicLayer ? cloudType : "all"
      }&layer=${layerType}`
    );

    const response = await promise;

    return response?.tags.map((tag) => ({
      ...tag,
      logoUrl: response.logoUrl,
      name: tag.packUid,
      packName: response.name,
    }));
  },
});

export const clustersFetcher = dataFetcher({
  selectors: ["selectedClusterId"],
  async fetchData(_, { search = "" } = {}) {
    const filters = {
      filter: {
        name: {
          contains: search,
        },
        state: "Running",
        isImported: true,
      },
      sort: "name",
    };

    const response = await api.post(
      `v1/dashboard/spectroclusters/metadata`,
      filters
    );

    return response?.items || [];
  },
});

export const helmRegistryFetcher = dataFetcher({
  selectors: ["helmRegistryList"],
  async fetchData() {
    const response = await api.get("v1/registries/helm/summary");
    return response.items;
  },
  schema: [HelmRegistriesSchema],
});

export const packValuesFetcher = dataFetcher({
  selectors: [
    "packValues",
    (state) => state.forms.clusterprofile?.data?.cloudType,
  ],
  schema: [PackSchema],
  async fetchData(_, packUid) {
    const response = await api.get(`v1/packs/${packUid}`);
    return (
      response?.packValues?.map(({ packUid, ...spec }) => ({
        metadata: { uid: packUid, name: packUid },
        spec,
      })) || []
    );
  },
});

export const packListActions = new ListActions({
  debounceDelay: 200,
  dataFetcher: packsListSearchFetcher,
  schema: [PackSchema],
  initialQuery: () => ({
    limit: 20,
    search: "",
  }),
});

export const systemChartsListActions = new ListActions({
  debounceDelay: 200,
  dataFetcher: systemChartNamesFetcher,
  schema: [PackSchema],
  initialQuery: () => ({
    limit: 20,
    search: "",
  }),
});

async function validateYamlSchema(value, _, data) {
  const schema = generateEditorYamlSchema(data.schema, {
    ignorePasswords: false,
  });
  if (typeof value === "undefined") {
    return false;
  }

  const yamlFiles = `${value}\n`
    .replace(/\n---([\s]+)?\n/g, "\n---\n")
    .split("\n---\n");

  const Ajv = (await import("ajv/dist/2019")).default;
  const ajv = new Ajv({ strict: false });

  const errors = [];
  for (const file of yamlFiles) {
    try {
      const valuesAsJson = YAML.parse(file, { strict: false });
      if (schema) {
        const validate = ajv.compile(schema);
        const isSchemaCompliant = validate(valuesAsJson);
        if (!isSchemaCompliant) {
          errors.push("YAML values don't match the provided schema");
        }
      }
    } catch (err) {
      errors.push(`Invalid YAML configuration`);
    }
  }

  return errors.length ? errors[0] : false;
}

function isSystemManifest(data) {
  return data.formType === MODULES.SYSTEM_MODULE;
}

export const configValidator = new Validator();
export const importPacksValidator = new Validator();
export const manifestsValidator = new Validator();
export const packActionsValidator = new Validator();
export const editorValidator = new Validator();

importPacksValidator.addRule("clusterId", Missing());

packActionsValidator.addRule(
  ["repository", "pack", "version"],
  ApplyIf(
    (value, key, data) => data.formType !== MODULES.SYSTEM_MODULE,
    Missing()
  )
);

packActionsValidator.addRule(
  ["packType"],
  ApplyIf(
    (value, key, data) => data.formType !== MODULES.SYSTEM_MODULE,
    Missing()
  )
);

packActionsValidator.addRule(
  ["name"],
  ApplyIf((value, key, data) => isSystemManifest(data), Missing())
);

packActionsValidator.addRule(
  ["manifests"],
  ApplyIf((value, key, data) => isSystemManifest(data), Missing())
);

packActionsValidator.addRule(
  ["content"],
  ApplyIf((value, key, data) => isSystemManifest(data), Missing())
);

editorValidator.addRule(["values"], validateYamlSchema);

configValidator.addRule(
  "values",
  ApplyIf((value, key, data) => data.type !== "manifest", validateYamlSchema)
);
manifestsValidator.addRule("content", validateYamlSchema);
manifestsValidator.addRule("content", Missing());
manifestsValidator.addRule("name", Missing());
manifestsValidator.addRule(["manifests"], Missing());

configValidator.addRule("manifests", manifestsValidator);
editorValidator.addRule("manifests", manifestsValidator);

const fetchers = {
  clustersFetcher,
  clusterPacksFetcher,
  helmRegistryFetcher,
  packVersionsFetcher,
  packsListSearchFetcher,
  systemChartNamesFetcher,
  repositoriesFetcher,
  helmRepositoriesFetcher,
  ociRepositoriesFetcher,
  packValuesFetcher,
};

export default fetchers;
