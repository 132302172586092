import * as YAML from "yaml";
import merge from "lodash/merge";
import { v4 as uuid } from "uuid";

function extractComments(doc) {
  const comments = {};
  function commentsFromItems(items = [], prefixKey = "") {
    items.forEach((item, index) => {
      if (!item?.key && !prefixKey) {
        return;
      }

      // needed for an item that has an array as value
      if (!item?.key && prefixKey) {
        commentsFromItems(item?.items || [], `${prefixKey}.${index}`);
        return;
      }

      const key = prefixKey
        ? `${prefixKey}.${item?.key?.value}`
        : item.key.value;

      comments[key] = {
        keyCommentBefore: item.key.commentBefore,
        valueCommentBefore: item.value?.commentBefore,
        comment: item.value?.comment,
        key: item.key.value,
        valueType: item?.value?.type,
      };

      commentsFromItems(item.value?.items || [], key);
    });

    return comments;
  }

  return commentsFromItems(doc?.contents?.items || []);
}

function addComments(doc, comments) {
  function addCommentsToItems(items, prefixKey) {
    items.forEach((item, index) => {
      if (!item?.key && !prefixKey) {
        return;
      }

      // needed for an item that has an array as value
      if (!item?.key && prefixKey) {
        addCommentsToItems(item?.items || [], `${prefixKey}.${index}`);
        return;
      }

      const key = prefixKey
        ? `${prefixKey}.${item?.key?.value}`
        : item.key.value;

      if (item.value?.items) {
        addCommentsToItems(item.value?.items || [], key);
      }

      item.key.commentBefore = comments[key]?.keyCommentBefore;
      item.value.comment = comments[key]?.comment;

      if (item.value) {
        item.value.commentBefore = comments[key]?.valueCommentBefore;
      }

      if (
        comments[key] &&
        item.value?.type &&
        comments[key].valueType &&
        comments[key].valueType !== item.value.type
      ) {
        item.value.type = comments[key].valueType;
      }
    });
  }

  addCommentsToItems(doc.contents.items);
}

// FIXME: this is a hack to prevent the
// yaml library from transforming an object full of null values
// into ? [key]
// this generates an UID that will be removed from the content of the
// toString() function of the parsedYaml document
const uid = uuid();
export function removeNullHax(content) {
  const regex = new RegExp(`\\s+${uid}: ${uid}`, "g");

  return content.replace(regex, "");
}

function checkForNull(obj) {
  const keys = Object.keys(obj || {});
  if (keys.length === 0) {
    return;
  }

  let allNull = true;

  keys.forEach((key) => {
    const value = obj[key];
    if (value !== null) {
      allNull = false;
    }
    if (value !== null && typeof value === "object") {
      checkForNull(value);
    }
  });

  if (allNull) {
    obj[uid] = uid;
  }
}

export function mergeYaml(base, ...overwrites) {
  if (overwrites.length === 0) {
    return base;
  }

  const baseDoc = YAML.parseDocument(base);
  const parsedBase = baseDoc.toJS();
  let comments = extractComments(baseDoc);

  const mergedObject = overwrites.reduce((accumulator, overwrite) => {
    const overwriteDoc = YAML.parseDocument(overwrite);
    const parsedOverwrite = overwriteDoc.toJS();
    const overwriteDocComments = extractComments(overwriteDoc);

    comments = merge(comments, overwriteDocComments);

    return merge(accumulator, parsedOverwrite);
  }, parsedBase);

  checkForNull(mergedObject);

  const outputDoc = YAML.parseDocument(YAML.stringify(mergedObject));

  addComments(outputDoc, comments);

  const originalToString = outputDoc.toString.bind(outputDoc);
  outputDoc.toString = () => {
    const contents = originalToString({
      nullStr: "",
      lineWidth: 0,
    });
    const cleanContent = removeNullHax(contents);

    // handle overwrites that have multiple documents
    return overwrites.reduce((accumulator, overwrite) => {
      const parts = overwrite.split(`\n---`);
      parts.shift();

      return [accumulator.replace(/\n$/, ""), ...parts].join(`\n---`);
    }, cleanContent);
  };

  outputDoc.commentBefore = baseDoc.comment || baseDoc.commentBefore;
  return outputDoc;
}

function addPackValueToYaml(values, overwrites) {
  const mergedDoc = mergeYaml(values, overwrites.trim());
  const packValuesIndex = mergedDoc.contents.items.findIndex(
    (item) => item.key.value === "pack" || item.key === "pack"
  );

  if (packValuesIndex !== -1 && packValuesIndex !== 0) {
    const packValues = mergedDoc.contents.items[packValuesIndex];
    mergedDoc.contents.items.splice(packValuesIndex, 1);
    mergedDoc.contents.items.splice(0, 0, packValues);
  }

  return mergedDoc;
}

export function extractInstallOrder(
  config = {},
  path = ["pack", "spectrocloud.com/install-priority"]
) {
  if (!config) {
    return undefined;
  }

  if (config?.installOrder !== undefined) {
    return config.installOrder;
  }

  try {
    const yamlDoc =
      YAML.parseDocument(config?.values || "") || new YAML.Document();
    return yamlDoc.getIn(path);
  } catch (e) {
    return null;
  }
}

// TODO: try use parseYAMLValues function
export function updateInstallOrder(
  { values = "", installOrder },
  path = ["pack", "spectrocloud.com/install-priority"]
) {
  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();
    const hasInstallOrderValue = !!yamlDoc.getIn(path);

    if (
      hasInstallOrderValue &&
      (!installOrder || typeof installOrder === "undefined")
    ) {
      if (yamlDoc.get("pack")?.items?.length > 1) {
        yamlDoc.deleteIn(path);
      } else {
        if (yamlDoc.contents?.items?.length > 1) {
          yamlDoc.delete("pack");
        } else {
          return "";
        }
      }

      const yamlString = yamlDoc.toString();
      return yamlString.replace(/>\n/g, "|\n").replace(/\n$/, "");
    }

    if (!hasInstallOrderValue && !installOrder) {
      return values;
    }

    if (yamlDoc.getIn(path) !== installOrder) {
      const overwrite = `
       pack:
         spectrocloud.com/install-priority: "${installOrder}"
     `;

      const mergedDoc = addPackValueToYaml(values, overwrite);

      const yamlString = mergedDoc.toString();
      return yamlString.replace(/>\n/g, "|\n").replace(/\n$/, "");
    }

    return values;
  } catch (err) {
    return values;
  }
}

export function extractUbuntuAdvantagePresetOptions(values) {
  const paths = {
    token: ["ubuntu-advantage", "token"],
    cis: ["ubuntu-advantage", "services", "cis", "enabled"],
    "esm-infra": ["ubuntu-advantage", "services", "esm-infra", "enabled"],
    fips: ["ubuntu-advantage", "services", "fips", "enabled"],
    livepatch: ["ubuntu-advantage", "services", "livepatch", "enabled"],
  };

  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();
    const presets = Object.keys(paths).reduce(
      (acc, key) => ({
        ...acc,
        [key]: yamlDoc.getIn(paths[key]),
      }),
      {}
    );
    return {
      ...presets,
      token: presets.token || "",
      enabled: !!yamlDoc.getIn(["ubuntu-advantage"]),
    };
  } catch (err) {
    return {};
  }
}

function getUbuntuAdvantageYAMLContent(formData) {
  return `ubuntu-advantage:
  # Free Personal Token for up to 3 machines with UAI
  # eg: C13RaHQDqgvvG3YsnXT6kQJqqLGxyz
  token: "${formData.token || ""}"
  services:
    # Get access to OpenSCAP-based tooling that automates both hardening and auditing
    # with certified content based off of the published CIS benchmarks.
    cis:
      enabled: ${!!formData.cis}
    # Continue to receive security updates for the Ubuntu base OS, critical software packages
    #and infrastructure components with Extended Security Maintenance (ESM).
    # ESM provides five additional years of security maintenance, enabling an organization's continuous vulnerability management.
    esm-infra:
      enabled: ${!!formData["esm-infra"]}
    # FIPS 140 validated cryptography for Linux workloads on Ubuntu
    fips:
      enabled: ${!!formData.fips}
    # Livepatch eliminates the need for unplanned maintenance windows for high and critical severity kernel vulnerabilities
    # by patching the Linux kernel while the system runs
    livepatch:
      enabled: ${!!formData.livepatch}
`;
}

export function getUbuntuAdvantagePresets({ value, field }, formData) {
  const getPath = ({ field, value }) =>
    `ubuntu-advantage:\n  services:\n    ${field}:\n      enabled: ${value}`;

  if (field === "enabled" && value) {
    return {
      add: getUbuntuAdvantageYAMLContent(formData),
      name: "enabled",
      group: "ubuntuAdvantage",
    };
  }
  if (field === "enabled" && !value) {
    return {
      remove: ["ubuntu-advantage"],
      name: "enabled",
      group: "ubuntuAdvantage",
    };
  }
  if (field === "token") {
    return {
      add: `ubuntu-advantage:\n  token: "${value}"`,
      name: "token",
      group: "ubuntuAdvantage",
    };
  }
  return {
    add: getPath({ field, value }),
    name: field,
    group: "ubuntuAdvantage",
  };
}

export function extractManifestType(
  config = {},
  path = ["pack", "spectrocloud.com/manifest-type"]
) {
  if (!config?.values) {
    return undefined;
  }

  try {
    const yamlDoc =
      YAML.parseDocument(config?.values || "") || new YAML.Document();
    return yamlDoc.getIn(path);
  } catch (e) {
    return undefined;
  }
}

// TODO: try use parseYAMLValues function
export function updateManifestTypeValues({
  values = "",
  showType = true,
} = {}) {
  const path = ["pack", "spectrocloud.com/manifest-type"];
  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();

    if (!showType) {
      if (yamlDoc.get("pack")?.items?.length > 1) {
        yamlDoc.deleteIn(path);
      } else {
        if (yamlDoc.contents.items?.length > 1) {
          yamlDoc.delete("pack");
        } else {
          return "";
        }
      }

      const yamlString = yamlDoc.toString();
      return yamlString.replace(/>\n/g, "|\n").replace(/\n$/, "");
    }

    if (yamlDoc.getIn(path) !== "vm") {
      const overwrite = `
      pack:
        spectrocloud.com/manifest-type: "vm"
    `;
      const mergedDoc = addPackValueToYaml(values, overwrite);
      const yamlString = mergedDoc.toString();
      return yamlString.replace(/>\n/g, "|\n").replace(/\n$/, "");
    }
  } catch (e) {
    return values;
  }
}

export function extractPackDisplayName(
  config = {},
  path = ["pack", "spectrocloud.com/display-name"]
) {
  if (config?.displayName !== undefined) {
    return config.displayName;
  }
  const yamlDoc =
    YAML.parseDocument(config?.values || "") || new YAML.Document();

  return yamlDoc.getIn(path);
}
