import i18n from "i18next";
import store from "services/store";

import api from "services/api";
import ModalService from "services/modal";
import historyService from "services/history";
import notifications from "services/notifications";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import AsyncAction from "modules/asyncAction";

import { formatTags, getFullName } from "utils/presenters";
import { MemberSchema } from "utils/schemas";

import createFormActions from "modules/form/actions";
import { WizardActions } from "modules/wizard/actions";
import ListActions from "modules/list/actions";
import { getSelectedMembers } from "state/project/selectors/create";
import { getCurrentUser } from "state/auth/selectors";
import { getRoles, getSelectedMemberRoles } from "state/roles/selectors/list";
import { projectListActions } from "./list";
import { fetchUserContext } from "state/auth/actions";
import { fetchProjectToEdit } from "./edit";
import { getProjectToEdit } from "../selectors/edit";
import history from "services/history";

export const PROJECT_MODULE = "project";

export const addMembersModal = new ModalService("addMembersModal");
export const projectModal = new ModalService("createProject");

export const membersListActions = new ListActions({
  fetchData() {
    return Promise.resolve({
      items: [...store.getState().forms.project.data?.members],
    });
  },
  schema: [MemberSchema],
});

export const validator = new Validator();
function createValidator() {
  validator.removeRules();
  validator.addRule(["name"], Missing());
}

createValidator();

export async function submit(data) {
  const state = store.getState();
  const currentUser = getCurrentUser(state);

  const payload = {
    metadata: {
      labels: formatTags(data.tags),
      annotations: { description: data.description },
      name: data.name,
    },
    specSummary: {},
    status: {},
  };

  const projectToEditId = projectModal.data?.id;

  let response;
  try {
    response = projectToEditId
      ? await api.put(`v1/projects/${projectToEditId}`, payload)
      : await api.post("v1/projects", payload);
  } catch (err) {
    const message = projectToEditId
      ? i18n.t("Something went wrong when editing the project")
      : i18n.t("Something went wrong when creating the project");

    notifications.error({
      message,
      description: err.message,
    });
  }

  projectModal.close();
  historyService.push("/projects");

  if (projectToEditId) {
    const projectToEdit = getProjectToEdit(state);
    const projectEntity = state.entities.project[projectToEdit.guid];

    store.dispatch({
      type: "UPDATE_ENTITY",
      entityType: "project",
      id: projectToEdit.guid,
      updates: {
        ...projectEntity,
        metadata: {
          ...projectEntity.metadata,
          labels: formatTags(data.tags),
          description: data.description,
          name: data.name,
        },
      },
    });

    return;
  }

  if (response) {
    payload.metadata.uid = response.uid;
    store.dispatch(
      projectListActions.addItems({
        module: PROJECT_MODULE,
        items: [
          {
            ...payload,
            spec: { users: [currentUser], teams: [] },
            status: { usage: { clusters: [] } },
          },
        ],
      })
    );
    notifications.success({
      message: `Project "${data.name}" has been created successfully`,
    });
    store.dispatch(fetchUserContext());
  }

  return response;
}

export const projectFormActions = createFormActions({
  validator,
  submit,
  init: async () => {
    if (projectModal.data?.id) {
      const data = await store.dispatch(
        fetchProjectToEdit(projectModal.data.id)
      );

      return Promise.resolve({
        name: data?.metadata.name,
        description: data?.metadata.annotations?.description || "",
        tags: data?.metadata.labels ? Object.keys(data?.metadata.labels) : [],
      });
    }

    const currentUser = getCurrentUser(store.getState());
    const allRoles = getRoles(store.getState());
    const currentUserRoles = allRoles.filter((role) =>
      currentUser.spec.roles.includes(role.metadata.uid)
    );
    return Promise.resolve({
      name: "",
      members: [
        {
          uid: currentUser.metadata.uid,
          name: getFullName(currentUser),
          emailId: currentUser.spec.emailId,
          type: "user",
          roles: [...currentUserRoles],
          guid: currentUser.guid,
        },
      ],
    });
  },
});

export const addMembersValidator = new Validator();
function createAddMembersValidator() {
  addMembersValidator.removeRules();
  addMembersValidator.addRule(["selectedMembers", "roles"], Missing());
}

createAddMembersValidator();

export async function addNewMember(data) {
  const state = store.getState();
  const selectedMembers = getSelectedMembers(state);
  const roles = getSelectedMemberRoles(state);

  selectedMembers.forEach((member) => {
    const state = store.getState();
    const projectMembers = state.forms.project.data.members;

    return store.dispatch(
      projectFormActions.onChange({
        name: `members.${projectMembers.length}`,
        value: {
          uid: member.metadata.uid,
          name:
            member.type === "user" ? getFullName(member) : member.metadata.name,
          emailId: member.spec.emailId,
          type: member.type,
          roles: roles,
        },
        module: PROJECT_MODULE,
      })
    );
  });

  store.dispatch(membersListActions.fetchItems("projectMembers"));
}

export const addNewMembersFormActions = createFormActions({
  validator: addMembersValidator,
  submit: addNewMember,
  init: () => {
    return Promise.resolve({
      selectedMembers: [],
      roles: [],
    });
  },
});

export const wizardActions = new WizardActions({
  formActions: projectFormActions,
  fieldsToValidate() {
    return {
      0: ["name"],
      1: ["members"],
    };
  },
  getDescription(step) {
    const formData = store.getState().forms.project.data;

    if (step === 0) {
      return formData.name;
    }

    if (step === 1) {
      if (formData.members.length) {
        return i18n.t("Complete");
      }
    }
  },
  steps: [
    {
      title: () => i18n.t("Project Info"),
    },
    {
      title: () => i18n.t("Roles"),
    },
    {
      title: () => i18n.t("Review"),
    },
  ],
});

export function openAddMembersModal() {
  return (dispatch) => {
    addMembersModal.open().then(async () => {
      return dispatch(
        addNewMembersFormActions.submit({ module: "newMembers" })
      );
    });
  };
}

export const createProjectService = new AsyncAction({
  promise: () => {
    return store.dispatch(
      projectFormActions.submit({ module: PROJECT_MODULE })
    );
  },
});

export function openCreateProjectModal() {
  return (dispatch) => {
    dispatch(projectFormActions.init({ module: PROJECT_MODULE }));
    projectModal.open({ modalType: "create" }).then(
      () => createProjectService.trigger(),
      () => history.push("/projects")
    );
  };
}
