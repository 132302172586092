import dataFetcher from "modules/dataFetcher";
import { nonProjectApi } from "services/api";
import store from "services/store";

export function createMaasFormFactory(
  { formModuleName, formActions, getCloudAccountUid },
  { isOverlord = false, isNodes = false } = {}
) {
  const domainsFetcher = dataFetcher({
    selectors: ["maas", "domains", getCloudAccountUid],
    fetchData: async ([_1, _2, cloudAccountUid]) => {
      const domains = await nonProjectApi.get(
        `v1/cloudaccounts/maas/${cloudAccountUid}/properties/domains`
      );

      return domains;
    },
  });

  const resourcePoolsFetcher = dataFetcher({
    selectors: ["maas", "resourcePools", getCloudAccountUid],
    fetchData: async ([_1, _2, cloudAccountUid]) => {
      const resourcePools = await nonProjectApi.get(
        `v1/cloudaccounts/maas/${cloudAccountUid}/properties/resourcePools`
      );

      return resourcePools;
    },
  });

  const subnetsFetcher = dataFetcher({
    selectors: ["maas", "subnets", getCloudAccountUid],
    fetchData: async ([_1, _2, cloudAccountUid, region, domain, project]) => {
      const subnets = await nonProjectApi.get(
        `v1/cloudaccounts/maas/${cloudAccountUid}/properties/subnets`
      );

      return subnets;
    },
  });

  const azsFetcher = dataFetcher({
    selectors: ["maas", "azs", getCloudAccountUid],
    fetchData: async ([_1, _2, cloudAccountUid]) => {
      const azs = await nonProjectApi.get(
        `v1/cloudaccounts/maas/${cloudAccountUid}/properties/azs`
      );

      return azs;
    },
  });

  const fetchProperties = () => {
    return Promise.allSettled([
      store.dispatch(azsFetcher.fetch()),
      store.dispatch(domainsFetcher.fetch()),
      store.dispatch(resourcePoolsFetcher.fetch()),
    ]);
  };

  return {
    actions: {
      onAccountFieldChange(name, value) {
        const isAccountValidated =
          store.getState().overlord.configure.isAccountValidated;

        store.dispatch(
          formActions.onChange({
            module: formModuleName,
            name,
            value,
          })
        );

        if (isAccountValidated) {
          store.dispatch(
            formActions.onChange({
              module: formModuleName,
              name: "apiKey",
              value: "",
            })
          );
        }
      },
    },
    effects: {
      fetchProperties,
    },
    fetchers: {
      azsFetcher,
      domainsFetcher,
      resourcePoolsFetcher,
      subnetsFetcher,
    },
  };
}
