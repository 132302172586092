import i18n from "i18next";
import {
  clusterProfileFormActions,
  CLUSTER_PROFILE_MODULE,
} from "./layerConfig";
import { getCurrentStep } from "state/clusterprofile/selectors/create";
import { validateClusterProfileNameAndVersion } from "state/clusterprofile/actions/details";

import api from "services/api";
import notifications from "services/notifications";
import historyService from "services/history";
import ModalService from "services/modal";
import createFormActions from "modules/form/actions";

import store, { getStoreEntity } from "services/store";
import {
  cloneClusterProfileModal,
  profileBuilderCreateModule,
  profileListActions,
} from "../services";
import { ClusterProfileSchema } from "utils/schemas";
import Validator from "services/validator";
import { Missing, SemanticVersion } from "services/validator/rules";
import { getLayerConfigurationStatus } from "../selectors/layerConfig";

export const onCancelClusterProfileModal = new ModalService("cancelModal");

const fieldsToValidate = {
  "basic-info": ["name", "version", "tags"],
  "cloud-type": ["cloudType"],
};

function setStepDescription() {
  return (dispatch, getState) => {
    const { stepId } = getState().clusterprofile.create;
    const formData = getState().forms.clusterprofile.data;
    let description = "";
    if (stepId === "basic-info") {
      description = formData.name;
    }
    if (stepId === "cloud-type") {
      description = formData.cloudType;
    }
    if (stepId === "profile-layers") {
      description = "Complete";
    }

    dispatch({
      type: "CLUSTER_PROFILE_SET_DESCRIPTION",
      description,
      stepId,
    });
  };
}

function validateStep() {
  return function thunk(dispatch, getState) {
    const stepId = getState().clusterprofile?.create?.stepId;
    const fields = fieldsToValidate[stepId];

    if (!fields) {
      return [];
    }

    return dispatch(
      clusterProfileFormActions.validateField({
        name: fields,
        module: CLUSTER_PROFILE_MODULE,
      })
    );
  };
}

export function nextStep() {
  return async (dispatch, getState) => {
    let stepId = getState().clusterprofile.create.stepId;
    const formData = getState().forms.clusterprofile.data;
    if (stepId === "profile-layers") {
      try {
        await profileBuilderCreateModule.actions.validateProfilePacks(formData);
      } catch (err) {
        return;
      }
    }
    const errors = await dispatch(validateStep());
    const isIncomplete =
      getLayerConfigurationStatus(getState()) === "incomplete";

    if (isIncomplete || errors.length > 0) {
      return;
    }

    dispatch(setStepDescription());
    dispatch({ type: "CLUSTER_PROFILE_NEXT_STEP" });
    stepId = getState().clusterprofile.create.stepId;
    if (stepId === "profile-layers") {
      profileBuilderCreateModule.actions.startConfiguration();
    }
  };
}

export function prevStep() {
  return (dispatch) => {
    dispatch(setStepDescription());
    dispatch({ type: "CLUSTER_PROFILE_PREV_STEP" });
    dispatch(
      clusterProfileFormActions.clearErrors({ module: CLUSTER_PROFILE_MODULE })
    );
  };
}

export function changeStep(step) {
  return (dispatch, getState) => {
    if (step <= getCurrentStep(getState())) {
      dispatch({
        type: "CLUSTER_PROFILE_CHANGE_STEP",
        stepNumber: step,
      });
    }
  };
}

export function submitClusterProfile() {
  return async function thunk(dispatch) {
    dispatch(
      clusterProfileFormActions.submit({ module: CLUSTER_PROFILE_MODULE })
    );
  };
}

export function onModalClose() {
  const { tab } = store.getState().location.params;

  const formData = store.getState().forms.clusterprofile.data;

  if (formData.name === "") {
    historyService.push(`/profiles/${tab}`);
    return;
  }

  onCancelClusterProfileModal.open().then(async () => {
    historyService.push(`/profiles/${tab}`);
  });
}

const cloneProfileValidator = new Validator();
cloneProfileValidator.addRule(["name", "version"], Missing());
cloneProfileValidator.addRule("version", SemanticVersion());

export async function cloneProfile({ guid, name, version = "1.0.0" }) {
  const clusterProfile = getStoreEntity(guid, ClusterProfileSchema);

  const promise = api.post(
    `v1/clusterprofiles/${clusterProfile?.metadata?.uid}/clone`,
    {
      metadata: {
        name,
        version,
      },
    }
  );

  return promise;
}

export const cloneClusterProfileFormActions = createFormActions({
  validator: cloneProfileValidator,
  init: () => {
    const clusterProfile = getStoreEntity(
      cloneClusterProfileModal.data?.profileGuid,
      ClusterProfileSchema
    );
    return Promise.resolve({
      name: `${clusterProfile.metadata.name}-copy` || "",
      version: "1.0.0",
    });
  },
  submit: async (data) => {
    const profileGuid = cloneClusterProfileModal.data?.profileGuid;

    const error = await validateClusterProfileNameAndVersion({
      name: data.name,
      version: data.version,
    });
    if (error) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: error,
      });
      return Promise.reject();
    }

    const promise = cloneProfile({
      guid: profileGuid,
      name: data.name,
      version: data.version,
    });

    try {
      await promise;

      notifications.success({
        message: i18n.t(
          "Cluster profile '{{profileName}}' was created successfully",
          { profileName: data.name }
        ),
      });

      store.dispatch(profileListActions.initialize("clusterprofiles"));

      return promise;
    } catch (error) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while trying to clone the cluster profile"
        ),
        description: error?.message,
      });
    }
  },
});

export function openCloneClusterProfileModal(guid) {
  return (dispatch) => {
    cloneClusterProfileModal.open({ profileGuid: guid }).then(async () => {
      return dispatch(
        cloneClusterProfileFormActions.submit({ module: "cloneClusterProfile" })
      );
    });
  };
}
