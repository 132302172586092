import { createOpenstackFormFactory } from "modules/cluster/openstack";
import clusterFormActions from "../form";
import { getSelectedCredential } from "state/cluster/selectors/create";

export const openstackCloudForm = createOpenstackFormFactory({
  formActions: clusterFormActions,
  formModuleName: "cluster",
  getCloudAccountUid(state) {
    return state.forms.cluster.data.credential;
  },
});

function selectCredentialEffect() {
  return async function (dispatch, getState) {
    const account = getSelectedCredential(getState());
    await openstackCloudForm.actions.onAccountSelected("credential", account);
    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: ["domain", "region", "project"],
      })
    );
  };
}

const effects = {
  selectCredentialEffect,
};

export default effects;
