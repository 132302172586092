import React from "react";
import { Tabs as TabsList, Tooltip } from "antd";
import styled, { css } from "styled-components";

import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as colors from "utils/constants/colors";
import Link from "components/common/History/Link";

const StyledNavLink = styled(Link)`
  color: ${(props) => (props.selected ? colors.blue : colors.regentGray)};
  :hover {
    color: ${colors.blue};
  }

  height: 100%;
`;

const StyledTabsList = styled(TabsList)`
  .ant-tabs-tab {
    padding: 0;
  }
`;

const SimpleTab = styled.span`
  display: inline-block;
  padding: 16px 0;
  font-weight: 500;
  ${(props) =>
    props.disabled &&
    css`
      color: ${colors.silver};
    `}
`;

const TabLabelWrap = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 8px;
  }
  > svg {
    font-size: 17px;
    color: ${colors.silver};
  }
`;

export function getTabLabel({ info, label }) {
  if (!info) {
    return label;
  }
  return (
    <Tooltip placement="bottom" title={info}>
      <TabLabelWrap>
        <span>{label}</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </TabLabelWrap>
    </Tooltip>
  );
}

const Tabs = ({ tabs, children, activeKey, changeTab, ...rest }) => {
  const renderTabs = (item, index) => {
    let label = (
      <SimpleTab disabled={item.disabled}>{getTabLabel(item)}</SimpleTab>
    );
    if (item.href && !item.disabled) {
      label = (
        <StyledNavLink
          disabled={item.disabled}
          selected={activeKey === item.key}
          to={item.href}
          data-qa-value={item.key}
          data-qa-index={index}
        >
          {label}
        </StyledNavLink>
      );
    }
    return (
      <TabsList.TabPane
        key={item.key}
        tab={label}
        data-qa-value={item.key}
        data-qa-index={index}
        disabled={item.disabled}
      >
        {activeKey === item.key && children}
      </TabsList.TabPane>
    );
  };

  return (
    <StyledTabsList
      {...rest}
      activeKey={activeKey}
      onChange={changeTab}
      tabBarGutter={32}
    >
      {tabs.map(renderTabs)}
    </StyledTabsList>
  );
};

export default Tabs;
