import createFormActions from "modules/form/actions";
import api from "services/api";
import store from "services/store";
import Validator from "services/validator";
import { EmailType, Missing } from "services/validator/rules";

const forgotPasswordValidator = new Validator();
forgotPasswordValidator.addRule(["emailId"], EmailType());
forgotPasswordValidator.addRule(["emailId"], Missing());

export const forgetPasswordActions = createFormActions({
  validator: forgotPasswordValidator,
  init: () => Promise.resolve({ emailId: "" }),
  submit(data) {
    const redirectUrl =
      store.getState().auth.currentOrganization?.redirectUrl || "/";

    const promise = api.post("v1/auth/user/password/reset", {
      emailId: data.emailId,
    });

    store.dispatch({
      type: "SET_REDIRECT_URL",
      url: redirectUrl,
    });

    return promise;
  },
});
