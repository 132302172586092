import React, { useState } from "react";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

import * as colors from "utils/constants/colors";
import { TextButton } from "components/ui/Button";
import Descriptions, { ItemContainer } from "components/common/Descriptions";
import Drawer from "components/ui/Drawer";
import Tabs from "components/common/Tabs";
import Switch from "components/common/Switch";
import Icon from "components/ui/Icon";
import Link from "components/common/History/Link";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import createProjectRoleManagement from "modules/roleManagement/ProjectRoles";
import createTenantRoleManagement from "modules/roleManagement/TenantRoles";
import createWorkspaceRoleManagement from "modules/roleManagement/WorkspaceRoles";

import { teamDetailsModal } from "state/teams/actions";
import {
  projectRolesListActions,
  updateData,
} from "state/roleManagement/actions/projectRoles";

import {
  updateData as tenantRolesUpdateData,
  getSelectedEntityRoles,
  tenantRolesListActions,
} from "state/roleManagement/actions/tenantRoles";

import {
  updateData as workspaceRolesUpdateData,
  workspaceRolesListActions,
} from "state/roleManagement/actions/workspaceRoles";

import { getFullName } from "utils/presenters";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const DetailsContainer = styled.div`
  width: 320px;
  height: 100%;
  border-right: 1px solid ${colors.lightMidGray};
  display: flex;
  flex-direction: column;

  > div:first-child {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }

  ${ItemContainer} {
    margin-bottom: 0;
    padding: 13px 0 15px;
    margin: 0 24px;

    :nth-child(n + 2) {
      border-top: 1px solid ${colors.concreteWhite};
    }

    :last-child {
      border-top: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-bottom: 16px;
      overflow: hidden;
      margin: 0;
      padding: 0;

      > div:first-child {
        margin: 0 24px 15px;
        padding-top: 13px;
        border-top: 1px solid ${colors.concreteWhite};
      }

      > div:last-child {
        padding-left: 24px;
        overflow: auto;
      }
    }
  }
`;

const TabsContainer = styled.div`
  flex-grow: 1;
  background: white;
  padding: 24px;
`;

const GroupedButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  margin: 0 24px;
  border-top: 1px solid ${colors.concreteWhite};
`;

export const ProjectRolesManagement = createProjectRoleManagement({
  listActions: projectRolesListActions,
  updateData,
});

const TenantRolesManagement = createTenantRoleManagement({
  updateData: tenantRolesUpdateData,
  getSelectedEntityRoles,
  entityType: "team",
  listActions: tenantRolesListActions,
});

const UsersWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const UserEmail = styled.div`
  color: ${colors.greenGray};
  font-size: 12px;
`;

const UserContainer = styled.div`
  margin-bottom: 8px;
`;

function renderTeamMembers({ users }) {
  return (
    <UsersWrapper>
      {users.map((user) => {
        return (
          <UserContainer key={user.guid}>
            <div>{getFullName(user)}</div>
            <UserEmail>{user.spec.emailId}</UserEmail>
          </UserContainer>
        );
      })}
    </UsersWrapper>
  );
}

const WorkspaceRolesManagement = createWorkspaceRoleManagement({
  updateData: workspaceRolesUpdateData,
  listActions: workspaceRolesListActions,
});

const TeamInformation = ({ team, openTeamFormModal, onTeamRemove }) => {
  const { t } = useTranslation();
  const { name } = team.metadata;
  const { emailId, users = [] } = team.spec;
  const [activeKey, setActiveTab] = useState("project-roles");
  const onTabChange = (activeKey) => setActiveTab(activeKey);

  const userOptions = [
    {
      label: "Name",
      value: name || "-",
    },
    {
      label: t("Email"),
      value: emailId || "-",
    },
    {
      label: t("Team Members"),
      value: !isEmpty(users) ? renderTeamMembers({ users }) : "-",
    },
  ];

  const tabs = [
    {
      label: t("Project Roles"),
      key: "project-roles",
    },
    {
      label: t("Tenant Roles"),
      key: "tenant-roles",
    },
    {
      label: t("Workspace Roles"),
      key: "workspace-roles",
    },
  ];

  return (
    <Drawer
      width="1200"
      title={t("Team Details")}
      footer={null}
      hasSidebar
      service={teamDetailsModal}
    >
      <Container>
        <DetailsContainer>
          <Descriptions options={userOptions} variation="drawer-side-details" />
          <GroupedButtons>
            <Link to="/management/teams/edit">
              <TextButton
                secondary
                data-qa="edit_team"
                onClick={() => openTeamFormModal("edit")}
              >
                <Icon awesome={faEdit} />
                {t("Edit Team")}
              </TextButton>
            </Link>
            <TextButton danger data-qa="remove_team" onClick={onTeamRemove}>
              <Icon awesome={faTrashAlt} />
              {t("Delete Team")}
            </TextButton>
          </GroupedButtons>
        </DetailsContainer>
        <TabsContainer>
          <Tabs tabs={tabs} activeKey={activeKey} changeTab={onTabChange}>
            <Switch value={activeKey}>
              <Switch.Case value="project-roles">
                <ProjectRolesManagement />
              </Switch.Case>
            </Switch>
            <Switch value={activeKey}>
              <Switch.Case value="tenant-roles">
                <TenantRolesManagement />
              </Switch.Case>
            </Switch>
            <Switch value={activeKey}>
              <Switch.Case value="workspace-roles">
                <WorkspaceRolesManagement />
              </Switch.Case>
            </Switch>
          </Tabs>
        </TabsContainer>
      </Container>
    </Drawer>
  );
};

export default TeamInformation;
