import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled from "styled-components";
import { lavender } from "utils/constants/colors";

const Wrap = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.color || lavender};
`;

const SmallLoaderWrap = styled(LoadingOutlined)`
  color: ${(props) => props.color || lavender};
`;

const smallLoader = <SmallLoaderWrap style={{ fontSize: 15 }} spin />;

export default function Loader(props) {
  let content = (
    <Main color={props.color}>
      <LoadingOutlined style={{ fontSize: 40 }} spin />
    </Main>
  );

  if (props.small) {
    content = <Spin indicator={smallLoader} />;
  }

  return <Wrap data-qa="loading-state">{content}</Wrap>;
}

Loader.defaultProps = {
  color: lavender,
  size: 10,
};

Spin.setDefaultIndicator(smallLoader);
