import dataFetcher from "modules/dataFetcher";
import api from "services/api";
import { UserSchema } from "utils/schemas";
import store from "services/store";
import { getCurrentContext } from "state/auth/selectors";

export const usersFetcher = dataFetcher({
  selectors: ["users"],
  schema: [UserSchema],
  async fetchData() {
    const isAdmin = getCurrentContext(store.getState()).isAdmin;
    if (isAdmin) {
      const res = await api.get("v1/users");
      return res.items;
    }

    return [];
  },
});

export const usersMetaFetcher = dataFetcher({
  selectors: ["users"],
  schema: [UserSchema],
  async fetchData() {
    const isAdmin = getCurrentContext(store.getState()).isAdmin;
    if (isAdmin) {
      const res = await api.get("v1/users/meta");

      return (res?.items || []).map((user) => ({
        metadata: { uid: user.uid, name: user?.name },
        spec: { emailId: user?.emailId },
      }));
    }

    return [];
  },
});
