import i18n from "i18next";
import api from "services/api";
import store from "services/store";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import notifications from "services/notifications";

import createFormActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";
import { cardRef } from "components/common/CardNumber";

import { getCurrentUser } from "state/auth/selectors";
import {
  customerFetcher,
  subscriptionFetcher,
  paymentSuccessModal,
} from "state/plandetails/services";
import { handle3dSecureAuth } from "state/plandetails/actions";

import {
  PAYMENT_CARD_MODULE,
  CARD_SELECTION_MODULE,
  cardFormModal,
  stripeService,
  paymentCardsFetcher,
  invoiceModal,
  invoiceFetcher,
  invoicesFetcher,
} from "./services";
import {
  getDefaultPaymentMethod,
  shouldRetryPayment,
  getPaymentFailedInvoice,
} from "./selectors";

const validator = new Validator();
validator.addRule(["firstname", "lastname", "cardName"], Missing());

export const paymentCardFormActions = createFormActions({
  init: () => {
    return Promise.resolve({
      firstname: "",
      lastname: "",
      cardName: "",
      paymentMethod: null,
    });
  },
  validator,
  submit: async () => {
    const state = store.getState();
    const shouldRetry = shouldRetryPayment(state);
    const paymentMethod = await store.dispatch(createStripePaymentMethod());
    if (shouldRetry) {
      // Retry api will create and set the card as default
      await store.dispatch(retryPayment(paymentMethod.id));
      store.dispatch(refreshBillingDetails());
      return;
    }
    await store.dispatch(createPaymentMethod(paymentMethod));
    await store.dispatch(setDefaultPaymentMethod(paymentMethod.id));
    store.dispatch(paymentCardsFetcher.fetch());
  },
});

function refreshBillingDetails() {
  return (dispatch) => {
    dispatch(subscriptionFetcher.fetch());
    dispatch(paymentCardsFetcher.fetch());
    dispatch(invoicesFetcher.fetch());
  };
}

export const cardsSelectorFormActions = createFormActions({
  init() {
    const defaultPaymentMethod = getDefaultPaymentMethod(store.getState());
    return Promise.resolve({
      paymentMethod: defaultPaymentMethod,
    });
  },
});

export function onCardPaymentChange(paymentMethod) {
  return async (dispatch, getState) => {
    const defaultPayment = getState().forms?.cardSelection?.data?.paymentMethod;
    if (paymentMethod === defaultPayment) return;
    const error = await dispatch(setDefaultPaymentMethod(paymentMethod));
    if (!error) {
      dispatch(
        cardsSelectorFormActions.batchChange({
          module: CARD_SELECTION_MODULE,
          updates: {
            paymentMethod,
          },
        })
      );
      notifications.success({
        message: i18n.t("Default card has been successfully changed"),
      });
    }
  };
}

export const createCardAsyncAction = new AsyncAction({
  promise: () => {
    return store.dispatch(
      paymentCardFormActions.submit({ module: PAYMENT_CARD_MODULE })
    );
  },
});

export const removeCardAsyncAction = new AsyncAction({
  promise: async (paymentMethodId) => {
    const promise = api.delete(
      `v1/payments/stripe/paymentMethods/${paymentMethodId}`
    );

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while removing the payment method"
        ),
        description: err?.message,
      });
      return Promise.reject(err);
    }

    store.dispatch(paymentCardsFetcher.fetch());
  },
});

export function onCardDelete(paymentMethodId) {
  removeCardAsyncAction.key(paymentMethodId).trigger();
}

export function openPaymentCardModal() {
  return async (dispatch) => {
    cardFormModal.open().then(createCardAsyncAction.trigger);
    dispatch(paymentCardFormActions.init({ module: PAYMENT_CARD_MODULE }));
  };
}

export function createStripePaymentMethod() {
  return async (dispatch, getState) => {
    if (!cardRef?.current) return;

    const state = getState();
    const formData = state.forms.paymentCard?.data || {};
    const { firstname, lastname, cardName } = formData;

    const { error, paymentMethod } =
      await stripeService.stripe.createPaymentMethod({
        type: "card",
        card: cardRef.current,
        metadata: {
          firstname,
          lastname,
        },
        billing_details: {
          name: cardName,
        },
      });

    if (error) {
      return Promise.reject(error);
    }

    return paymentMethod;
  };
}

export function createPaymentMethod(paymentMethod) {
  return async (dispatch) => {
    const promise = api.post(
      `v1/payments/stripe/paymentMethods/${paymentMethod.id}`
    );
    try {
      await promise;
      dispatch(
        paymentCardFormActions.batchChange({
          module: PAYMENT_CARD_MODULE,
          updates: {
            paymentMethod,
          },
        })
      );
      return paymentMethod;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while creating the payment method"
        ),
        description: err?.message,
      });
      return Promise.reject(err);
    }
  };
}

export function setDefaultPaymentMethod(paymentMethodId) {
  return async (dispatch) => {
    const promise = api.put(
      `v1/payments/stripe/paymentMethods/default/${paymentMethodId}`
    );
    dispatch({
      type: "SET_DEFAULT_PAYMENT",
      promise,
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while updating the payment method"
        ),
        description: error?.message,
      });
      return error;
    }
  };
}

export function openInvoiceDetails(invoiceUid) {
  return (dispatch) => {
    invoiceModal.open({ invoiceUid });
    dispatch(invoiceFetcher.fetch());
  };
}

export function retryPayment(paymentMethodId) {
  return async (dispatch, getState) => {
    const state = getState();
    const invoice = getPaymentFailedInvoice(state);
    const tenantUid = getCurrentUser(state)?.metadata?.annotations?.tenantUid;
    const customerId = customerFetcher.selector(state)?.result;
    const payload = {
      customerId,
      invoiceUid: invoice?.metadata?.uid,
      paymentMethodId,
    };
    const promise = api.post(`v1/tenants/${tenantUid}/invoice/retry`, payload);

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t("Something went wrong while retrying the payment"),
        description: error?.message,
      });
      return;
    }
    paymentSuccessModal.open();
  };
}

export function completePayment() {
  return (dispatch, getState) => {
    const state = getState();
    const subscription = subscriptionFetcher.selector(store.getState())?.result;
    const paymentMethodId = getDefaultPaymentMethod(state);
    handle3dSecureAuth(subscription, paymentMethodId);
  };
}
