import moment from "moment";

import createFormActions from "modules/form/actions";
import {
  clustersCostsFetcher,
  clustersConsumptionFetcher,
  PROJECT_OVERVIEW_FILTERS_MODULE,
} from "./services";
import { getSelectedKey } from "./selectors";

export const projectOverviewFilterFormActions = createFormActions({
  init: () => {
    return Promise.resolve({
      query: {},
      filter: {
        value: 7,
        unit: "days",
        type: "period",
      },
      consumptionType: "cpu",
      groupBy: "cluster",
      filterBy: [],
    });
  },
});

export function initProjectOverview() {
  return async (dispatch, getState) => {
    await dispatch(
      projectOverviewFilterFormActions.init({
        module: PROJECT_OVERVIEW_FILTERS_MODULE,
      })
    );

    const state = getState();
    const timePeriod = getSelectedKey(state);
    const selectedMonth =
      state.forms?.[PROJECT_OVERVIEW_FILTERS_MODULE]?.data?.query?.endTime;

    if (timePeriod === "calendar") {
      dispatch(overviewCalendarChange(selectedMonth));
    } else {
      dispatch(overviewTimePeriodChange(timePeriod));
    }
  };
}

export function overviewTimePeriodChange(timePeriod) {
  return (dispatch) => {
    const [value, unit] = timePeriod.split(" ");
    const query = {
      startTime: moment().subtract(value - 1, unit),
      endTime: moment(),
    };

    dispatch(
      projectOverviewFilterFormActions.batchChange({
        module: PROJECT_OVERVIEW_FILTERS_MODULE,
        updates: {
          query,
          filter: { value, unit, type: "period" },
        },
      })
    );

    dispatch(clustersCostsFetcher.fetch(query));
    dispatch(clustersConsumptionFetcher.fetch(query));
  };
}

export function overviewCalendarChange(date = moment()) {
  return (dispatch) => {
    const startOfMonth = moment(date).startOf("month");
    const endOfMonth = moment(date).endOf("month");

    const query = {
      startTime: startOfMonth,
      endTime: endOfMonth,
    };

    dispatch(
      projectOverviewFilterFormActions.batchChange({
        module: PROJECT_OVERVIEW_FILTERS_MODULE,
        updates: {
          query,
          filter: {
            value: moment(date).daysInMonth(),
            unit: "days",
            type: "calendar",
          },
        },
      })
    );

    dispatch(clustersCostsFetcher.fetch(query));
    dispatch(clustersConsumptionFetcher.fetch(query));
  };
}

export function onConsumptionFilterChange(filter) {
  return async (dispatch) => {
    dispatch(
      projectOverviewFilterFormActions.onChange({
        module: PROJECT_OVERVIEW_FILTERS_MODULE,
        name: "consumptionType",
        value: filter,
      })
    );
  };
}

export function onClusterSelect(name, value) {
  return (dispatch) => {
    dispatch(
      projectOverviewFilterFormActions.onChange({
        module: PROJECT_OVERVIEW_FILTERS_MODULE,
        name,
        value,
      })
    );
  };
}

export function onClustersCostGroupBySelect(value) {
  return (dispatch, getState) => {
    dispatch(
      projectOverviewFilterFormActions.onChange({
        module: PROJECT_OVERVIEW_FILTERS_MODULE,
        name: "groupBy",
        value,
      })
    );

    const query = getState().forms[PROJECT_OVERVIEW_FILTERS_MODULE].data.query;

    dispatch(clustersCostsFetcher.fetch(query));
    dispatch(clustersConsumptionFetcher.fetch(query));
    dispatch(
      projectOverviewFilterFormActions.onChange({
        module: PROJECT_OVERVIEW_FILTERS_MODULE,
        name: "filterBy",
        value: [],
      })
    );
  };
}
