import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import observeHistory from "services/history/observer";
import App from "./App";
import store from "services/store";
import { initApp, initAuthApp } from "state/auth/actions";
import history from "services/history";
import { updateFullStoryCookie } from "utils/fullstory";
import { enableMapSet, setAutoFreeze } from "immer";
import { hookHistoryWithContext } from "services/history/context";
// if (process.env.NODE_ENV !== "production") {
//   var axe = require("react-axe");
//   axe(React, ReactDOM, 1000);
// }

enableMapSet();
setAutoFreeze(false);

hookHistoryWithContext();

ReactDOM.render(<App />, document.getElementById("root"), async () => {
  updateFullStoryCookie();
  if (history.location.pathname.startsWith("/auth")) {
    observeHistory();
    await store.dispatch(initAuthApp());
    return;
  }
  await store.dispatch(initApp());

  observeHistory();
});
