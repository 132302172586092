export function presentNamespaces(namespaces = []) {
  return namespaces.reduce((acc, ns, index) => {
    return [
      ...acc,
      {
        ...ns,
        children: (ns.children || []).map((child) => ({
          ...child,
          parentIndex: index,
        })),
      },
    ];
  }, []);
}
