import { createSelector } from "reselect";

import { clustersFetcher } from "state/workspaces/services/create";

import {
  EDIT_CLUSTERS_MODULE,
  EDIT_NAMESPACES_MODULE,
} from "state/workspaces/services/edit";
import { presentNamespaces } from "./utils";

export const getSelectedClustersList = createSelector(
  (state) => state.forms[EDIT_CLUSTERS_MODULE]?.data?.clusters,
  clustersFetcher.selector,
  (clusterUids, { result }) => {
    const clusters = result?.filter((cluster) =>
      clusterUids.includes(cluster?.metadata?.uid)
    );
    return (clusters || []).map((item) => ({
      ...item,
      key: item.guid,
      health: item.status.health?.state,
      metrics: item.status.metrics,
      isBrownfield:
        item?.status?.clusterImport?.isBrownfield ||
        !!item?.status?.clusterImport?.state,
    }));
  }
);

export const getSelectedNamespaces = createSelector(
  (state) => state.forms[EDIT_NAMESPACES_MODULE]?.data.namespaces || [],
  (namespaces) => {
    return presentNamespaces(namespaces);
  }
);

export const getNamespaceSelectOptions = createSelector(
  (state) => state.forms[EDIT_NAMESPACES_MODULE]?.data?.namespaces,
  (namespaces) => {
    return (namespaces || [])
      .filter((ns) => !ns.isChild)
      .map((ns) => ({
        label: ns.namespaceName,
        value: ns.namespaceName,
      }));
  }
);
