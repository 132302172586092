import { createSelector } from "reselect";

import { getSelectedUser } from "state/roleManagement/selectors";
import { TeamSchema, UserSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { USERS_MODULE } from "./actions";

export const getTenantAdmin = createSelector(
  getSelectedUser,
  (selectedUser) => {
    return selectedUser?.spec?.roles.find(
      (role) => role.metadata.name === "TenantAdmin"
    );
  }
);

export const getTeams = getEntity(
  (state) => state.list.teams?.items.initial,
  [TeamSchema]
);

export const getAllUsers = getEntity(
  (state) => {
    const usersListState = state?.list?.[USERS_MODULE];

    return Array.from(usersListState?.pages || new Set()).flatMap(
      (key) => usersListState?.items?.[key]
    );
  },
  [UserSchema]
);

export const getAllActiveUsersFromList = createSelector(
  getAllUsers,
  (users) => {
    return (users || []).filter((user) => user?.status?.isActive);
  }
);
