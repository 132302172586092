import store from "services/store";

import Validator from "services/validator";
import {
  isKubernetesName,
  Missing,
  MaxLength,
  ApplyIf,
} from "services/validator/rules";
import api, { nonProjectApi } from "services/api";

import createActions from "modules/form/actions";
import {
  CLOUD_ACCOUNTS_MODULE,
  cloudAccountFormModal,
  cloudAccountFetcher,
} from "state/cloudaccounts/services";
import dataFetcher from "modules/dataFetcher";
import { createAccount } from "state/cloudaccounts/actions/create";

const validator = new Validator();
validator.addRule(
  [
    "name",
    "username",
    "password",
    "parentRegion",
    "defaultDomain",
    "identityEndpoint",
    "overlordUid",
  ],
  Missing()
);
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));
validator.addRule(
  ["caCert"],
  ApplyIf((value, key, data) => !data.insecure, Missing())
);

export const openstackAccountFormActions = createActions({
  validator,
  init: async () => {
    let data;
    if (cloudAccountFormModal.data?.uid) {
      data = await store.dispatch(cloudAccountFetcher.fetch());

      store.dispatch({
        type: "SET_ACCOUNT_AS_VALID",
      });
    }
    const insecure = !!data?.spec?.insecure;

    return Promise.resolve({
      name: data?.metadata.name || "",
      overlordUid: data?.metadata?.annotations?.overlordUid || "",
      username: data?.spec.username || "",
      password: data?.spec.password || "",
      insecure,
      caCert: !insecure ? data?.spec?.caCert?.trim() || "" : undefined,
      defaultDomain: data?.spec?.defaultDomain || "",
      identityEndpoint: data?.spec?.identityEndpoint || "",
      parentRegion: data?.spec?.parentRegion || "",
      defaultProject: data?.spec?.defaultProject || "",
    });
  },
  submit: async (data) => {
    const {
      name,
      insecure,
      username,
      password,
      overlordUid,
      caCert,
      defaultDomain,
      identityEndpoint,
      parentRegion,
      defaultProject,
    } = data;

    const payload = {
      metadata: {
        name,
        annotations: {
          overlordUid: overlordUid,
        },
      },
      spec: {
        caCert,
        defaultDomain,
        defaultProject,
        identityEndpoint,
        parentRegion,
        insecure,
        username,
        password,
      },
    };

    return store.dispatch(createAccount("openstack", payload));
  },
});

export const selectedOverlordFetcher = dataFetcher({
  selectors: [
    "overlords",
    (state) => state.forms[CLOUD_ACCOUNTS_MODULE]?.data?.overlordUid,
  ],
  async fetchData([_, overlordUid]) {
    const overlord = await nonProjectApi.get(`v1/overlords/${overlordUid}`);
    const cloudAccount = api.get(
      `v1/cloudaccounts/openstack/${overlord.spec.cloudAccountUid}`
    );
    return cloudAccount;
  },
});

export function onOverlordSelect(value) {
  return async function thunk(dispatch) {
    dispatch(
      openstackAccountFormActions.onChange({
        name: "overlordUid",
        value,
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );

    dispatch({ type: "SET_ACCOUNT_AS_INVALID" });

    const cloudAccount = await dispatch(selectedOverlordFetcher.fetch());
    dispatch(
      openstackAccountFormActions.batchChange({
        module: CLOUD_ACCOUNTS_MODULE,
        updates: {
          caCert: cloudAccount?.spec?.caCert,
          defaultDomain: cloudAccount?.spec?.defaultDomain,
          defaultProject: cloudAccount?.spec?.defaultProject,
          identityEndpoint: cloudAccount?.spec?.identityEndpoint,
          parentRegion: cloudAccount?.spec?.parentRegion,
          insecure: cloudAccount?.spec?.insecure,
        },
      })
    );
  };
}
