import React from "react";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import { Link } from "react-router-dom";
import {
  downloadBugReport,
  generateBugReportedContent,
} from "services/store/bugReporter";
import styled from "styled-components";
import { lightYellow, yellow } from "utils/constants/colors";
import { FLAGS } from "utils/constants/flags";
import Button from "./ui/Button";
import Icon from "./ui/Icon";
import logger from "services/logger";
import flags from "services/flags";
import history from "services/history";

const ErrorWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.div`
  text-align: center;
  max-width: 412px;
  padding: 21px;
  border-radius: 5px;
  border: 1px solid ${yellow};
  background-color: ${lightYellow};

  ${Button} {
    margin-bottom: 14px;
  }
`;

const IconWrap = styled.div`
  text-align: center;
  color: ${yellow};
  font-size: 25px;
`;

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    logger.error(error);
    const reportContent = generateBugReportedContent({
      message: error.message,
      stack: error?.stack?.toString(),
      location: history.location,
    });
    this.setState({
      hasError: true,
      reportContent,
    });
  }

  resetError = () => {
    this.setState({
      hasError: false,
    });
  };

  render() {
    let message = `Sorry but it seems that there's a unhandled UI error. An error has been logged in our system and we are going to fix this as soon as possible.`;

    const renderQAMessage = () => {
      if (!flags.has(FLAGS.ALL)) {
        return null;
      }

      return (
        <>
          <p>
            Please open up a bug in Jira (attach the file resulted from clicking
            the button bellow)
          </p>
        </>
      );
    };

    if (this.state.hasError) {
      return (
        <ErrorWrap>
          <ErrorMessage>
            <IconWrap>
              <Icon awesome={faExclamationTriangle} />
            </IconWrap>
            <h1>Something went wrong</h1>
            <p>{message}</p>
            <div>{renderQAMessage()}</div>
            <div>
              <Link to="/" onClick={this.resetError}>
                <Button data-qa="error-home">Home</Button>
              </Link>
            </div>
            <div>
              <Button
                secondary
                data-qa="error-report"
                onClick={() => {
                  downloadBugReport(this.state.reportContent);
                }}
              >
                Download Report
              </Button>
            </div>
          </ErrorMessage>
        </ErrorWrap>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
