import i18n from "i18next";

import store from "services/store";
import api from "services/api";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import notifications from "services/notifications";

import createActions from "modules/form/actions";
import AsyncAction from "modules/asyncAction";

import {
  dnsFormModal,
  DNS_MAPPING_MODULE,
  dnsFetcher,
  dnsMappingsFetcher,
  networksFetcher,
  overlordsFetcher,
  datacenterFetcher,
  clusterFetcher,
  vsphereCloudAccountsFetcher,
} from "../services";
import { dnsListActions } from "./list";
import { isOnPremApplication } from "state/auth/selectors";

const validator = new Validator();
validator.addRule(["dnsName", "datacenter", "network"], Missing());

export const dnsFormActions = createActions({
  validator,
  init: async () => {
    let data;
    let overlord;
    const locationUid = store.getState().location.params.uid;

    if (dnsFormModal?.data?.uid) {
      data = await store.dispatch(dnsFetcher.fetch());
    }

    if (locationUid) {
      overlord = await api.get(`v1/overlords/${locationUid}`);
    }

    return Promise.resolve({
      dnsName: data?.spec?.dnsName || "",
      datacenter: data?.spec?.datacenter || "",
      network: data?.spec?.network || "",
      privateGatewayUid:
        data?.spec?.privateGatewayUid || store.getState().location.params.uid,
      isSystemOverlord: !!overlord?.spec?.isSystem,
    });
  },
  submit: async (data) => {
    const payload = {
      metadata: {
        name: data.dnsName,
      },
      spec: data,
    };
    const dnsUid = dnsFormModal.data?.uid;
    const promise = dnsUid
      ? api.put(`v1/users/assets/vsphere/dnsMappings/${dnsUid}`, payload)
      : api.post("v1/users/assets/vsphere/dnsMappings", payload);
    let response;

    try {
      response = await promise;
    } catch (err) {
      const message = dnsUid
        ? i18n.t("Something went wrong when editing the DNS mapping")
        : i18n.t("Something went wrong when creating the DNS mapping");

      notifications.error({
        message,
        description: err.message,
      });
      return Promise.reject(err);
    }

    dnsFormModal.close();

    notifications.success({
      message: dnsUid
        ? i18n.t(`DNS mapping "{{dnsName}}" has been updated successfully`, {
            dnsName: data?.dnsName,
          })
        : i18n.t(`DNS mapping "{{dnsName}}" has been created successfully`, {
            dnsName: data?.dnsName,
          }),
    });
    return response;
  },
});

export const dnsFormSubmit = new AsyncAction({
  promise: () => {
    return store.dispatch(
      dnsFormActions.submit({ module: DNS_MAPPING_MODULE })
    );
  },
});
export function openDNSModal(uid) {
  return async (dispatch, getState) => {
    dnsFormModal.open({ uid, inline: !!uid }).then(async () => {
      await dnsFormSubmit.trigger();
      dispatch(dnsListActions.initialize(DNS_MAPPING_MODULE));
    });
    await dispatch(dnsFormActions.init({ module: DNS_MAPPING_MODULE }));
    await dispatch(overlordsFetcher.fetch());
    if (!uid) {
      if (
        getState().forms?.[DNS_MAPPING_MODULE]?.data?.isSystemOverlord &&
        isOnPremApplication(getState())
      ) {
        dispatch(vsphereCloudAccountsFetcher.fetch());
        return;
      }

      dispatch(datacenterFetcher.fetch());
    }
  };
}

export function openInlineModal(onUpdate = () => {}, uid) {
  return async function thunk(dispatch, getState) {
    dnsFormModal.open({ inline: true, uid }).then(async () => {
      const response = await dnsFormSubmit.trigger();
      await dispatch(dnsMappingsFetcher.fetch());

      const entities = dnsMappingsFetcher.selector(getState());
      const selectedEntity = entities?.result?.find(
        (entity) => entity.metadata.uid === (response?.uid || uid)
      );
      selectedEntity && onUpdate(selectedEntity);
    });

    await dispatch(dnsFormActions.init({ module: DNS_MAPPING_MODULE }));
    dispatch(overlordsFetcher.fetch());
  };
}

export function onCloudAccountChange(account) {
  return function (dispatch) {
    dispatch(
      dnsFormActions.onChange({
        module: DNS_MAPPING_MODULE,
        name: "cloudAccount",
        value: account,
      })
    );

    dispatch(datacenterFetcher.fetch());
  };
}

export function onDatacenterChange(datacenter) {
  return function thunk(dispatch) {
    dispatch(
      dnsFormActions.batchChange({
        module: DNS_MAPPING_MODULE,
        updates: {
          datacenter,
          cluster: "",
          network: "",
        },
      })
    );
    dispatch(clusterFetcher.key(datacenter).fetch());
  };
}

export function onClusterChange(cluster) {
  return function thunk(dispatch) {
    dispatch(
      dnsFormActions.batchChange({
        module: DNS_MAPPING_MODULE,
        updates: {
          cluster,
          network: "",
        },
      })
    );
    dispatch(networksFetcher.key(cluster).fetch());
  };
}
