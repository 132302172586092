import React from "react";
import styled, { css } from "styled-components";
import Color from "color";
import i18n from "i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { Radio, Tooltip } from "antd";

import Card from "components/styled/Card";
import RadioGroup from "components/ui/RadioGroup";

import * as colors from "utils/constants/colors";
import { getThemeValue, createTheme } from "utils/theme";

export const defaultTheme = createTheme({
  vMargin: "14px",
  hMargin: "27px",
});

const CardWrap = styled(Card)`
  background: ${colors.white};
  box-sizing: border-box;
  border-radius: 8px;
  width: ${(props) => (props.width ? props.width : "400px")};
  min-height: 64px;
  padding: 8px 16px;
  display: flex;
  flex-direction: ${(props) => (props.hasExtraContent ? "column" : "row")};
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  border: 1px solid ${colors.concreteWhite};
  margin: ${getThemeValue(defaultTheme, "vMargin")}
    ${getThemeValue(defaultTheme, "hMargin")};
  vertical-align: top;
  ${(props) =>
    props.selected &&
    css`
      background: #f5fbff;
    `}

  ${(props) =>
    props.hideRadio &&
    css`
      .ant-radio {
        visibility: hidden;
      }
    `}
`;

const Label = styled.span`
  font-style: normal;
  font-size: 15px;
  line-height: 23px;
  color: ${colors.midGray};
`;

const Icon = styled.div`
  height: 40px;
  width: 50px;
  margin-left: auto;

  img {
    color: transparent;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const CardType = styled.div`
  min-height: 30px;
  text-align: center;
  display: grid;
  align-items: center;
`;

const Field = styled(Radio)`
  height: 20px;
  width: 20px;
`;

const MainWrap = styled.div`
  margin: 0 -${getThemeValue(defaultTheme, "hMargin")};

  ${(props) =>
    props.hideRadio &&
    css`
      .ant-radio {
        display: none;
      }
    `}

  ${(props) =>
    props.layout === "horizontal" &&
    css`
      > div {
        display: flex;
        flex-flow: row wrap;
      }
    `}
`;
const RIBBON_COLOR = "#78909C";
const ComingSoonRibbon = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
  span {
    width: 130px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    top: 6px;
    right: -10px;
    z-index: 2;
    overflow: hidden;
    transform: rotate(25deg);
    box-shadow: 0 0 0 3px ${RIBBON_COLOR}, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
    background: ${RIBBON_COLOR};
    text-align: center;
    color: ${Color(RIBBON_COLOR).lighten(0.6).hex()};
    z-index: 2;
    text-indent: 31px;
    font-size: 12px;
    letter-spacing: -0.6px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 34px;
    right: 0px;
    top: 28px;
    background: ${Color(RIBBON_COLOR).darken(0.5).hex()};
    border-radius: 0px 8px 8px 0px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 11px;
    right: 83px;
    top: -3px;
    background: ${Color(RIBBON_COLOR).darken(0.5).hex()};
    border-radius: 10px 10px 0px 0px;
  }
`;

const HasNoAccountIcon = styled(FontAwesomeIcon)`
  color: ${colors.orange};
  margin-left: 20px;
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CardOption = ({
  data,
  onClick,
  selected,
  disabled,
  hideRadio,
  children,
  width,
  ...rest
}) => {
  const name = data.label || data.name;
  const logo = data.logoPath || data.logo;

  function renderImage() {
    if (!logo) {
      return null;
    }

    return (
      <Icon>
        <img src={logo} alt={name} />
      </Icon>
    );
  }

  function renderComingSoon() {
    if (!data.comingSoon) {
      return null;
    }

    return (
      <ComingSoonRibbon>
        <span>{i18n.t("Coming Soon")}</span>
      </ComingSoonRibbon>
    );
  }

  function renderAccountTooltip() {
    if (data.comingSoon) {
      return null;
    }

    if (data.hasAccount === false) {
      return (
        <Tooltip
          title={i18n.t(
            "You have no {{name}} cloud account configured. To create a new one, click on the card",
            { name }
          )}
          placement={"top"}
        >
          <HasNoAccountIcon icon={faExclamationCircle} />
        </Tooltip>
      );
    }
  }

  return (
    <CardWrap
      onClick={onClick}
      selected={selected}
      disabled={disabled}
      hideRadio={hideRadio}
      hasExtraContent={!!children}
      width={width}
      {...rest}
    >
      <CardContent>
        {renderAccountTooltip()}
        <Field data-qa={data.value} checked={selected} />
        <CardType>
          <Label>{name}</Label>
        </CardType>
        {renderImage()}
        {renderComingSoon()}
      </CardContent>
      {children}
    </CardWrap>
  );
};

export default function CardSelector({
  options,
  hideRadio,
  children,
  layout,
  ...rest
}) {
  function renderCard(data) {
    return (
      <CardOption
        data={data}
        value={data.value}
        key={data.value}
        disabled={data.disabled}
      />
    );
  }

  let content = children || options.map(renderCard);

  return (
    <MainWrap hideRadio={hideRadio} layout={layout}>
      <RadioGroup {...rest}>{content}</RadioGroup>
    </MainWrap>
  );
}
