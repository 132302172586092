import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import store from "services/store";

import { ProjectRolesSchema } from "utils/schemas";

import Modal from "components/ui/Modal";
import Form from "./components/Form";
import Enumeration from "components/common/Enumeration";
import ActionMenu from "components/common/ActionMenu";
import AddNewItem from "components/styled/AddNewItem";
import InfoTooltip from "./components/InfoTooltip";

import createFormActions from "modules/form/actions";
import { getAllProjects } from "state/auth/selectors";
import {
  getSelectedProjectRoleEntity,
  getCurrentProjectRoles,
  getSelectedTeam,
  getSelectedUser,
} from "state/roleManagement/selectors";

import { createForm } from "modules/form";
import createList, { Blocks } from "modules/list";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import {
  projectRolesModal,
  onProjectRolesRemove,
} from "state/roleManagement/actions/projectRoles";
import { getProjectScopedRoles } from "state/roleManagement/selectors";

import { getFullName } from "utils/presenters";
import { red } from "utils/constants/colors";

function hasProjectPermissions(role) {
  return role.spec.permissions.includes("project.get");
}
export default function createProjectRoleManagement({
  listActions,
  updateData,
}) {
  const moduleName = "projectRoles";
  const projectRolesValidator = new Validator();
  projectRolesValidator.addRule(["projectsUids", "roles"], Missing());

  projectRolesValidator.addRule("roles", (_1, _2, data) => {
    const projectScopedRoles = getProjectScopedRoles(store.getState());
    const selectedRoles = projectScopedRoles.filter((role) =>
      data?.roles.includes(role.guid)
    );
    const alreadyHasPermissions = selectedRoles.some(hasProjectPermissions);

    if (alreadyHasPermissions) {
      return false;
    }

    const projectOptions = projectScopedRoles
      .filter(hasProjectPermissions)
      .map((role) => role.metadata.name);

    return `User must have at least one of these roles: ${projectOptions.join(
      ", "
    )}`;
  });

  const formActions = createFormActions({
    validator: projectRolesValidator,
    submit: async (data) => {
      await updateData(data);
      store.dispatch(listActions.initialize(moduleName));
    },
    init: () => {
      const selectedProjectRole = getSelectedProjectRoleEntity(
        store.getState()
      );

      return Promise.resolve({
        projectsUids: selectedProjectRole?.project?.metadata?.uid
          ? [selectedProjectRole?.project?.metadata?.uid]
          : [],
        roles: (selectedProjectRole?.roles || []).map((role) => role.guid),
        isEdit: !!selectedProjectRole?.project?.metadata?.uid,
      });
    },
  });

  const ConnectedForm = connect((state) => {
    const projects = getAllProjects(state);
    const selectedProjectRoleId =
      state.modal.data[projectRolesModal.guid]?.selectedProjectRoleId;
    const userProjectRoles = getCurrentProjectRoles(state);

    const parsedProjects = projects
      .filter(
        (project) =>
          selectedProjectRoleId ||
          !userProjectRoles.some(
            (projectRole) =>
              projectRole.project.guid === project.guid &&
              !!projectRole.roles?.length
          )
      )
      .map((project) => ({
        value: project.metadata.uid,
        label: project.metadata.name,
      }));

    return {
      projects: parsedProjects,
      selectedProjectRoleId,
    };
  })(Form);

  const ProjectRolesForm = createForm({
    Component: ConnectedForm,
    actions: formActions,
  });

  function openModal(selectedProjectRoleId) {
    return (dispatch) => {
      projectRolesModal.open({ selectedProjectRoleId }).then(() => {
        return dispatch(formActions.submit({ module: moduleName }));
      });

      dispatch(formActions.init({ module: moduleName }));
    };
  }

  const ListModule = createList({
    actions: listActions,
    schema: [ProjectRolesSchema],
  });

  function TableRowActions({ selectedEntity, openModal, onRemove }) {
    const { t } = useTranslation();
    const { guid, inheritedRoles } = selectedEntity;

    let options = [
      {
        key: "edit",
        label: t("Edit"),
        onClick: openModal(guid),
      },
    ];

    if (!inheritedRoles.length) {
      options = [
        ...options,
        {
          key: "remove",
          label: t("Remove"),
          color: red,
          onClick: onRemove(guid),
        },
      ];
    }
    return <ActionMenu options={options} />;
  }

  const ConnectedTableRowActions = connect(null, (dispatch) => ({
    openModal: (guid) => () => dispatch(openModal(guid)),
    onRemove: (guid) => () => dispatch(onProjectRolesRemove(guid)),
  }))(TableRowActions);

  function ProjectRolesModule({
    openModal,
    selectedEntity,
    projectScopedRoles,
  }) {
    const { t } = useTranslation();

    const renderInheritedRoles = ({ roles, inheritedRoles }) => {
      const rolesNames = roles.map((role) => role.metadata.name);

      const inheritedRoleNames = [
        ...new Set(inheritedRoles.map((role) => role.metadata.name)),
      ].filter((roleName) => !rolesNames.includes(roleName));

      return inheritedRoleNames.length ? (
        <Enumeration items={inheritedRoleNames} />
      ) : (
        "-"
      );
    };

    const columns = [
      {
        title: t("Project Name"),
        key: ["name"],
        render: function renderName({ project, roles, inheritedRoles }) {
          const alreadyHasPermissions =
            roles.some(hasProjectPermissions) ||
            inheritedRoles.some(hasProjectPermissions);

          const projectOptions = projectScopedRoles
            ?.filter(hasProjectPermissions)
            .map((role) => role.metadata.name);

          return (
            <>
              <span>{project.metadata.name}</span>
              <InfoTooltip
                showTooltip={!alreadyHasPermissions}
                title={`User must have at least one of these roles: ${projectOptions.join(
                  ", "
                )}`}
              />
            </>
          );
        },
      },
      {
        title: t("Role"),
        key: "role",
        render: function renderRoles({ roles }) {
          const rolesNames = roles.map((role) => role.metadata.name);
          return <Enumeration items={rolesNames} />;
        },
      },
      {
        title: t("Team inherited"),
        render: renderInheritedRoles,
        key: "inherited-roles",
      },
      {
        title: "",
        render: function renderTableRowActions(data) {
          return <ConnectedTableRowActions selectedEntity={data} />;
        },
        key: "actions",
      },
    ];

    return (
      <>
        <ListModule module={moduleName}>
          <Blocks.Table columns={columns} />
        </ListModule>
        <AddNewItem onClick={() => openModal(null)} data-qa="add_project_role">
          {t("New Project Role")}
        </AddNewItem>
        <Modal
          service={projectRolesModal}
          title={t("Add Roles to {{name}}", {
            name: selectedEntity.metadata.name || getFullName(selectedEntity),
          })}
        >
          <ProjectRolesForm module={moduleName} />
        </Modal>
      </>
    );
  }

  return connect(
    (state) => ({
      selectedEntity:
        state.roleManagement.entityType === "user"
          ? getSelectedUser(state)
          : getSelectedTeam(state),
      projectScopedRoles: getProjectScopedRoles(state),
    }),
    {
      openModal,
    }
  )(ProjectRolesModule);
}
