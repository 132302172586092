import { v4 as uuid } from "uuid";
import store, { getStoreEntity } from "services/store";
import api from "services/api";
import fetchers, { MODULES } from "../services";
import { mapFormDataToLayers } from "../utils";
import { ManifestSchema } from "utils/schemas";

import { getRawClusterProfile } from "state/clusterprofile/selectors/details";

function parseManifest(manifest) {
  return {
    uid: manifest.metadata.uid,
    name: manifest.metadata.name,
    content: manifest.spec?.draft?.content || manifest.spec?.published?.content,
  };
}

export default function manifestsActions(
  guid,
  { forms, toggleNewManifestEditor, onEditorSelectLayer, validateLayer }
) {
  const getState = () => {
    return store.getState().profileBuilder[guid];
  };

  const dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid,
    });
  };

  const toggleAttachedManifestEditor = (manifestIndex) => {
    const { currentAttachedManifestGuid, isEditorExpanded, formType } =
      getState();

    const manifest =
      store.getState().forms[formType].data.manifests[manifestIndex];

    const shouldCollapseEditor =
      isEditorExpanded && manifest.guid === currentAttachedManifestGuid;

    dispatch({
      type: "TOGGLE_ATTACHED_MANIFEST_EDITOR",
      manifestGuid: manifest.guid,
      isEditorExpanded: !shouldCollapseEditor,
    });
  };

  return {
    toggleAttachedManifestEditor,
    toggleNewManifestEditor,
    attachManifest: () => {
      const { formType } = getState();
      const { manifests } = store.getState().forms[formType]?.data;
      forms.packFieldChange({
        name: "manifests",
        value: [
          ...manifests,
          {
            guid: uuid(),
            name: "",
            content: "",
            persisted: false,
          },
        ],
      });
    },

    detachManifest: (index) => {
      const {
        currentAttachedManifestGuid,
        formType,
        selectedLayer,
        draftLayers,
        errors,
      } = getState();
      const { manifests } = store.getState().forms[formType]?.data;

      const manifestsCopy = [...manifests];
      manifestsCopy.splice(index, 1);

      if (manifests[index].guid === currentAttachedManifestGuid) {
        toggleAttachedManifestEditor(index);
      }

      forms.packFieldChange({
        name: "manifests",
        value: manifestsCopy,
      });

      const parentLayerGuid = selectedLayer || draftLayers?.[0];
      const layerErrors = errors[parentLayerGuid];
      if (layerErrors?.length) {
        const filteredErrors = layerErrors.filter(
          (err) => !err.field.startsWith(`manifests.${index}`)
        );
        dispatch({
          type: "PROFILE_BUILDER_UPDATE_PACKS_ERRORS",
          errors: {
            ...errors,
            [parentLayerGuid]: filteredErrors,
          },
        });
      }
    },

    applyManifest: (index) => {
      const { formType } = getState();
      let manifests = store.getState().forms[formType].data.manifests;

      forms.packFieldChange({
        name: "manifests",
        value: manifests.map((manifest) => {
          if (!manifest.name) {
            return {
              ...manifest,
              name: `manifest-${index + 1}`,
            };
          }
          return manifest;
        }),
      });

      toggleAttachedManifestEditor(index);

      dispatch({
        type: "PROFILE_BUILDER_UPDATE_LAYER",
        layer: mapFormDataToLayers(store.getState().forms[formType]?.data),
      });

      dispatch({
        type: "APPLY_MANIFEST_NAME",
        manifestGuid: manifests[index].guid,
        isEditorExpanded: true,
      });
    },

    onManifestValueChange: (value) => {
      const { formType, errors, selectedLayer, layers, draftLayers } =
        getState();
      const layerGuid = selectedLayer || draftLayers?.[0];
      const manifests = [...store.getState().forms[formType].data.manifests];

      const index = manifests.findIndex(
        (manifest) => manifest.guid === getState().currentAttachedManifestGuid
      );

      manifests[index] = {
        ...manifests[index],
        content: value,
      };
      const hasError = errors[layerGuid]?.find(
        (err) => err.field === `manifests.${index}.content` && err.result
      );
      if (hasError) {
        const layer = layers.find((layer) => layer.guid === layerGuid);
        validateLayer(layer);
      }

      forms.packFieldChange({
        name: "manifests",
        value: manifests,
      });
    },

    onAttachedManifestSelect: async (
      manifestGuid,
      layerGuid,
      attachedManifest = true
    ) => {
      const packEditor = store.getState().forms.packsEditor?.data?.[layerGuid];
      const manifest = packEditor?.manifests?.find(
        (manifest) => manifest.guid === manifestGuid
      );

      const { packsEditor } = getState();

      let entityGuid =
        attachedManifest || packEditor.type === "manifest"
          ? manifestGuid
          : layerGuid;

      onEditorSelectLayer(entityGuid);

      if (manifest && manifest.content === undefined) {
        const currentProfile = getRawClusterProfile(store.getState());

        const promise = api
          .get(
            `v1/clusterprofiles/${currentProfile.metadata.uid}/packs/${layerGuid}/manifests/${manifest.uid}`
          )
          .then((manifest) => parseManifest(manifest));

        const currentName = packsEditor.currentName;

        await store.dispatch({
          type: "FETCH_ATTACHED_MANIFEST",
          promise,
          schema: ManifestSchema,
        });

        store.dispatch(
          forms.editorActions.onChange({
            module: MODULES.PACKS_EDITOR_MODULE,
            name: currentName,
            value: getStoreEntity(manifestGuid, ManifestSchema)?.content,
          })
        );
      }
    },
    // There is no manifest preset fetcher ATM
    onManifestPresetSelect: (presetGuid) => {
      const manifestPresets =
        fetchers.manifestsPresetsFetcher.selector(store.getState())?.result ||
        [];

      const selectedPreset = manifestPresets.find(
        (preset) => preset.guid === presetGuid
      );

      forms.newManifestFieldChange({
        name: "preset",
        value: presetGuid,
      });

      forms.newManifestFieldChange({
        name: "values",
        value: selectedPreset?.spec?.value,
      });

      if (getState().openedEditor !== "new-manifest") {
        toggleNewManifestEditor();
      }
    },

    onManifestPresetRevert: () => {
      const manifestFormData = store.getState().forms?.manifest?.data;
      const selectedPreset = (
        fetchers.manifestsPresetsFetcher.selector(store.getState())?.result ||
        []
      ).find((preset) => preset.guid === manifestFormData?.preset);

      if (selectedPreset?.metadata?.name) {
        forms.newManifestFieldChange({
          name: "values",
          value: selectedPreset.spec.value,
        });
      }
    },
  };
}
