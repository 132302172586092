import dataFetcher from "modules/dataFetcher";
import { CloudAccountSchema, OverlordSchema } from "utils/schemas";

import ModalService from "services/modal";
import api, { nonProjectApi } from "services/api";

export const CLOUD_ACCOUNTS_MODULE = "cloudAccounts";

export const cloudAccountFormModal = new ModalService("cloudAccountForm");
export const cloudAccountTypeModal = new ModalService("cloudAccountType");

export const cloudAccountFetcher = dataFetcher({
  selectors: [
    "cloudaccounts",
    () => cloudAccountFormModal.data?.cloudType || "",
    () => cloudAccountFormModal.data?.uid || "",
  ],
  schema: CloudAccountSchema,
  async fetchData([_, cloudType, uid]) {
    return await api.get(`v1/cloudaccounts/${cloudType}/${uid}`);
  },
});

export const vmWareOverlordsFetcher = dataFetcher({
  selectors: ["vmWare", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const response = await nonProjectApi.get("v1/overlords");
    return (
      response?.items?.filter((item) => {
        const { kind = "vsphere", status, spec } = item;
        if (kind !== "vsphere") {
          return false;
        }

        return (
          status?.state === "Running" ||
          (spec?.isSystem && status?.state === "Active")
        );
      }) || []
    );
  },
});

export const maasOverlordsFetcher = dataFetcher({
  selectors: ["maas", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const response = await nonProjectApi.get("v1/overlords");

    return (
      response?.items?.filter((item) => {
        const { kind = "maas", status, spec } = item;
        if (kind !== "maas") {
          return false;
        }

        return (
          status?.state === "Running" ||
          (spec?.isSystem && status?.state === "Active")
        );
      }) || []
    );
  },
});

export const openstackOverlordsFetcher = dataFetcher({
  selectors: ["openstack", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const overlords = await nonProjectApi.get("v1/overlords");
    const openstackOverlords = overlords?.items?.filter((overlord) => {
      return overlord?.kind === "openstack";
    });
    return openstackOverlords || [];
  },
});
