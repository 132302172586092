import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import Fields from "components/ui/Fields";
import { FieldWrap } from "components/styled/Field";
import AddNewItem from "components/styled/AddNewItem";
import { TextButton } from "../Button";
import { midGray } from "utils/constants/colors";

const InputContainer = styled.div`
  display: flex;
`;

const InputsWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  ${FieldWrap} {
    margin-top: 0;
    margin-bottom: 3px;
  }

  svg {
    color: ${midGray};
    font-size: 18px;
    margin-left: 4px;
    margin-top: 10px;
  }
`;

function MultipleKeyValuePairs({
  name,
  value = [{ key: "", value: "" }],
  onChange,
  onRowRemove,
  addNewButtonTitle = name.substring(0, name.length - 1),
}) {
  const { t } = useTranslation();

  const keyDuplicates = value.filter((obj, index, self) =>
    self.some((o, selfIndex) => index !== selfIndex && o.key === obj.key)
  );

  const onAdd = () => onChange([...value, { key: "", value: "" }]);

  const onRemove = (index) => {
    if (onRowRemove) {
      onRowRemove(index);
      return;
    }

    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);

    if (newValue.length === 0) {
      onChange([{ key: "", value: "" }]);
    }
  };

  return (
    <div>
      {value.map((_, index) => {
        const keyInputName = `${name}.${index}.key`;
        const valueInputName = `${name}.${index}.value`;

        return (
          <InputsWrapper key={index}>
            <InputContainer>
              <Fields.Input
                name={keyInputName}
                data-qa={keyInputName}
                placeholder={t("Key")}
              />
              <Fields.Input
                placeholder={t("Value")}
                name={valueInputName}
                data-qa={valueInputName}
              />
            </InputContainer>
            <TextButton onClick={onRemove.bind(null, index)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </TextButton>
          </InputsWrapper>
        );
      })}
      <AddNewItem disabled={keyDuplicates.length > 0} onClick={onAdd}>
        {t(`Add new ${addNewButtonTitle}`)}
      </AddNewItem>
    </div>
  );
}

export default MultipleKeyValuePairs;
