import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Link from "components/common/History/Link";
import { Trans, useTranslation } from "react-i18next";
import { round } from "utils/number";

import { TextButton } from "components/ui/Button";
import {
  adminBlue,
  darkGray,
  PROGRESS_BAR_COLORS,
} from "utils/constants/colors";
import Icon from "components/ui/Icon";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import {
  UsageBarWrapper,
  UsageProgressBar,
} from "components/common/ProgressBar";
import { Tooltip } from "antd";
import { formatToUSCurrency } from "utils/number";
import { getCloudCreditUsage, getFreemiumUsage } from "state/auth/selectors";

const UpgradeButton = styled(TextButton)`
  flex-grow: 1;
  background-color: #6ddcb4;
  margin: 8px 0 8px 0;
  display: inline-block;
  line-height: 32px;

  &:focus,
  &:hover {
    color: ${adminBlue};
    background-color: #6ddcb4;
  }
`;

const ExclamationIconWrapper = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  font-size: 16px;
  color: #efa96b;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${ExclamationIconWrapper} {
    margin-left: 16px;
  }
`;

const ToolTipWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  color: ${darkGray};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  div {
    padding: 6px;
  }

  > a {
    text-decoration: none !important;
    color: ${adminBlue} !important;
  }

  ${ExclamationIconWrapper} {
    margin-right: 8px;
  }
`;

const Warning = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  background: #fdf6f0;
  border: 1px solid #f9ddc4;
  border-radius: 4px;
  padding: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  ${UsageBarWrapper} {
    margin-left: 16px;
  }
`;

const FreemiumAndCloudCredit = ({
  freemiumUsage,
  cloudCreditUsage,
  appFeatures,
}) => {
  const { t } = useTranslation();

  if (!appFeatures?.freemium?.isFreemium && !appFeatures?.freeCloudCredit) {
    return null;
  }

  let costUsage = null;

  if (cloudCreditUsage) {
    const { cloudCreditUsed, cloudCreditLimit, cloudCreditPercent } =
      cloudCreditUsage;

    costUsage = (
      <Tooltip
        title={
          <ToolTipWrapper>
            <div>
              <Trans>
                <strong>
                  {formatToUSCurrency(
                    Math.max(cloudCreditLimit - cloudCreditUsed, 0),
                    "usd",
                    2
                  )}
                </strong>{" "}
                credit available out of{" "}
                <strong>{formatToUSCurrency(cloudCreditLimit, "usd")}</strong>{" "}
                (Spectro Cloud Free Credit)
              </Trans>
            </div>
          </ToolTipWrapper>
        }
        color="#fff"
        placement="bottom"
        overlayStyle={{ maxWidth: "500px" }}
      >
        <BarWrapper>
          <UsageProgressBar
            label={t(`{{used}} out of {{limit}}`, {
              used: formatToUSCurrency(cloudCreditUsed, "usd"),
              limit: formatToUSCurrency(cloudCreditLimit, "usd"),
            })}
            percent={cloudCreditPercent}
            strokeColor={PROGRESS_BAR_COLORS.malibu}
          />
        </BarWrapper>
      </Tooltip>
    );
  }

  let planUsage = null;

  if (freemiumUsage) {
    const { customerUsage, usageLimit, usagePercent } = freemiumUsage;

    planUsage = (
      <Tooltip
        title={
          <ToolTipWrapper>
            {usagePercent > 100 ? (
              <Warning>
                <ExclamationIconWrapper>
                  <Icon awesome={faExclamationCircle} />
                </ExclamationIconWrapper>
                <div>
                  {t(
                    `You have exceeded your free plan limit this month by {{usage}} kCh`,
                    { usage: round(Math.abs(usageLimit - customerUsage), 2) }
                  )}
                </div>
              </Warning>
            ) : (
              <div>
                <Trans>
                  <strong>{{ customerUsage }}</strong> killocore-hours used out
                  of <strong>{{ usageLimit }}</strong>/month (Free Plan Usage)
                </Trans>
              </div>
            )}
            <UpgradeButton as={Link} to="/admin/settings/plandetails">
              {t("Go on-demand")}
            </UpgradeButton>
          </ToolTipWrapper>
        }
        color="#fff"
        placement="bottom"
        overlayStyle={{ maxWidth: "500px" }}
      >
        <BarWrapper>
          <UsageProgressBar
            label={`${customerUsage}/${usageLimit}kCh`}
            percent={usagePercent}
          />
          {usagePercent > 100 && (
            <ExclamationIconWrapper>
              <Icon awesome={faExclamationCircle} />
            </ExclamationIconWrapper>
          )}
        </BarWrapper>
      </Tooltip>
    );
  }

  return (
    <Wrapper>
      {costUsage}
      {planUsage}
    </Wrapper>
  );
};

export default connect((state) => ({
  appFeatures: state.auth.appFeatures,
  freemiumUsage: getFreemiumUsage(state),
  cloudCreditUsage: getCloudCreditUsage(state),
}))(FreemiumAndCloudCredit);
