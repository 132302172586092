import i18next from "i18next";

import api from "services/api";
import historyService from "services/history";
import ModalService from "services/modal";
import store, { getStoreEntity } from "services/store";
import notifications from "services/notifications";
import Validator from "services/validator";
import { ApplyIf, Missing } from "services/validator/rules";

import { BackupLocationSchema } from "utils/schemas";
import { getBoolean } from "utils/parsers";

import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import { getRawCluster } from "state/cluster/selectors/details";

export const BACKUP_LOCATIONS_MODULE = "backuplocations";
export const BACKUP_LOCATION_FORM_MODULE = "backuplocationmodal";

export const deleteConfirmService = new ModalService();
export const backupLocationModal = new ModalService();

const backupLocationValidator = new Validator();
backupLocationValidator.addRule(["name", "bucket"], Missing());
backupLocationValidator.addRule(
  ["region"],
  ApplyIf(
    (value, key, data) => ["s3", "minio"].includes(data.provider),
    Missing()
  )
);
backupLocationValidator.addRule(
  ["certificate"],
  ApplyIf((value, key, data) => data.provider === "gcp", Missing())
);
backupLocationValidator.addRule(
  ["accessKey", "secretKey"],
  ApplyIf(
    (value, key, data) =>
      ["s3", "minio"].includes(data.provider) && data.credentialType !== "sts",
    Missing()
  )
);
backupLocationValidator.addRule(
  ["arn"],
  ApplyIf(
    (value, key, data) =>
      ["s3", "minio"].includes(data.provider) && data.credentialType === "sts",
    Missing()
  )
);
backupLocationValidator.addRule(
  ["s3Url"],
  ApplyIf((value, key, data) => data.provider === "minio", Missing())
);

export const fetchBackupLocationsFetcher = dataFetcher({
  selectors: ["backuplocations"],
  async fetchData() {
    const response = await api.get("v1/users/assets/locations");
    return {
      items:
        response?.items?.map((item) => ({
          ...item,
          spec: {
            ...item.spec,
            isDefault: !!item.spec.isDefault,
          },
        })) || [],
    };
  },
});

export const fetchBackupNamespacesFetcher = dataFetcher({
  selectors: ["backupnamespaces"],
  async fetchData() {
    const cluster = getRawCluster(store.getState());
    const promise = api.get(
      `v1/spectroclusters/${cluster.metadata.uid}/namespaces`
    );

    let response;
    try {
      response = await promise;
    } catch (error) {}

    return {
      items: response?.namespaces?.reduce(
        (acc, item) => [...acc, item.namespace],
        []
      ),
    };
  },
});

export const backupLocationsListActions = new ListActions({
  dataFetcher: fetchBackupLocationsFetcher,
  schema: [BackupLocationSchema],
});

export function onBackupLocationDelete(guid) {
  return function thunk(dispatch) {
    deleteConfirmService.open({ guid }).then(async () => {
      const entity = getStoreEntity(guid, BackupLocationSchema);
      const { uid, name } = entity?.metadata || {};
      const promise = api.delete(`v1/users/assets/locations/${uid}`);
      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18next.t(
            "Something went wrong while deleting the backup location."
          ),
          description: error.message,
        });
        dispatch(
          backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE)
        );
        return;
      }
      notifications.success({
        message: i18next.t(
          "Backup location '{{name}}' was deleted successfully",
          {
            name,
          }
        ),
      });
      dispatch(backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE));
    });
  };
}

export function onSetDefaultLocation(guid) {
  return async function thunk(dispatch) {
    const entity = getStoreEntity(guid, BackupLocationSchema);
    const promise = api.patch(
      `v1/users/assets/locations/${entity?.spec?.storage}/${entity?.metadata?.uid}/default`
    );
    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t("Something went wrong."),
        description: error.message,
      });
      return;
    }
    notifications.success({
      message: i18next.t(
        "Default backup location has been successfully changed"
      ),
    });
    dispatch(backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE));
  };
}

function getPayload(data, uid) {
  const {
    name,
    bucket,
    certificate,
    region,
    s3ForcePathStyle,
    s3Url,
    accessKey,
    secretKey,
    isDefault,
    arn,
    externalId,
    credentialType,
    provider,
  } = data;

  let config = {
    region,
    s3Url,
    s3ForcePathStyle: getBoolean(s3ForcePathStyle),
    bucketName: bucket,
    caCert: certificate,
    ...(credentialType === "secret"
      ? {
          credentials: {
            accessKey,
            secretKey,
            credentialType,
          },
        }
      : {
          credentials: {
            credentialType,
            sts: {
              arn,
              externalId,
            },
          },
        }),
  };

  if (provider === "gcp") {
    config = {
      bucketName: bucket,
      credentials: {
        jsonCredentials: certificate,
      },
    };
  }

  return {
    metadata: {
      name,
      uid,
    },
    spec: {
      type: provider,
      isDefault,
      config,
    },
  };
}

export const backupLocationFormActions = createFormActions({
  init: async () => {
    const locationUid = backupLocationModal?.data?.uid;
    const locationProvider = backupLocationModal?.data?.provider || "s3";
    let data;
    let config;

    if (locationUid) {
      data = await api.get(
        `v1/users/assets/locations/${locationProvider}/${locationUid}`
      );
      config = data?.spec?.config;
      store.dispatch({
        type: "VALIDATE_CREDENTIALS_SUCCESS",
      });
    }

    const stsData = await api.get("v1/clouds/aws/account/sts");

    if (locationProvider === "gcp") {
      return Promise.resolve({
        name: data?.metadata?.name || "",
        provider: locationProvider,
        bucket: config?.bucketName || "",
        certificate: config?.credentials?.jsonCredentials || "",
      });
    }

    return Promise.resolve({
      name: data?.metadata?.name || "",
      provider: locationProvider,
      certificate: config?.caCert || "",
      bucket: config?.bucketName || "",
      credentialType: config?.credentials?.credentialType || "secret",
      region: config?.region || "",
      s3ForcePathStyle: !!config?.s3ForcePathStyle,
      s3Url: config?.s3Url || "",
      accessKey: config?.credentials?.accessKey || "",
      secretKey: config?.credentials?.secretKey || "",
      arn: config?.credentials?.sts?.arn || "",
      externalId:
        config?.credentials?.sts?.externalId || stsData?.externalId || "",
      accountId: stsData?.accountId,
      isDefault: !!data?.spec?.isDefault,
    });
  },
  validator: backupLocationValidator,
  submit: async (data) => {
    const locationUid = backupLocationModal?.data?.uid;
    const locationProvider = data.provider;
    const payload = getPayload(data, locationUid);
    let promise;

    if (locationUid) {
      promise = api.put(
        `v1/users/assets/locations/${locationProvider}/${locationUid}`,
        payload
      );
    } else {
      promise = api.post(
        `v1/users/assets/locations/${locationProvider}`,
        payload
      );
    }

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: locationUid
          ? i18next.t(
              "Something went wrong while updating the backup location."
            )
          : i18next.t(
              "Something went wrong while creating the backup location."
            ),
        description: error.message,
      });
      return Promise.reject(error);
    }

    notifications.success({
      message: locationUid
        ? i18next.t("Backup location '{{name}}' was updated successfully", {
            name: data?.name,
          })
        : i18next.t("Backup location '{{name}}' was created successfully", {
            name: data?.name,
          }),
    });
  },
});

export function openBackupLocationModal({ uid, provider } = {}) {
  return (dispatch) => {
    function fallback() {
      historyService.push("/settings/backuplocations");
      dispatch({
        type: "VALIDATE_CREDENTIALS_FAILURE",
      });
    }
    backupLocationModal.open({ uid, provider }).then(async () => {
      await dispatch(
        backupLocationFormActions.submit({
          module: BACKUP_LOCATION_FORM_MODULE,
        })
      );
      fallback();
    }, fallback);
    dispatch(
      backupLocationFormActions.init({ module: BACKUP_LOCATION_FORM_MODULE })
    );
  };
}

function getCredentialsPayload({
  credentialType,
  accessKey,
  secretKey,
  arn,
  externalId,
} = {}) {
  if (credentialType === "secret") {
    return {
      accessKey,
      credentialType,
      secretKey,
    };
  }
  return {
    credentialType,
    sts: {
      arn,
      externalId,
    },
  };
}

export function validateCredentials() {
  return async (dispatch, getState) => {
    const formData = getState().forms[BACKUP_LOCATION_FORM_MODULE]?.data || {};
    const errors = await dispatch(
      backupLocationFormActions.validateForm({
        module: BACKUP_LOCATION_FORM_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const credentials = getCredentialsPayload(formData);
    const data = {
      s3: {
        payload: {
          credentials,
          bucket: formData?.bucket,
          region: formData?.region,
        },
        apiUrl: "v1/clouds/aws/s3/validate",
      },
      minio: {
        payload: {
          credentials,
          bucket: formData?.bucket,
          region: formData?.region,
        },
        apiUrl: "v1/clouds/aws/s3/validate",
      },
      gcp: {
        payload: {
          projectId: formData?.projectId,
          bucketName: formData?.bucket,
          credentials: {
            jsonCredentials: formData?.certificate,
          },
        },
        apiUrl: "v1/clouds/gcp/bucketname/validate",
      },
    };

    const providerData = data[formData.provider];

    const promise = api.post(providerData.apiUrl, providerData.payload);

    dispatch({
      type: "VALIDATE_CREDENTIALS",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err.message,
      });
    }
  };
}

export function onChangeField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: BACKUP_LOCATION_FORM_MODULE };
    const isValid = getState().backupLocations.isValid;

    if (isValid) {
      dispatch({
        type: "VALIDATE_CREDENTIALS_FAILURE",
      });
    }

    dispatch(backupLocationFormActions.onChange(props));

    if (name === "provider") {
      dispatch(
        backupLocationFormActions.clearErrors({
          module: BACKUP_LOCATION_FORM_MODULE,
        })
      );
    }
  };
}
