import React from "react";

import createNamespace from "services/language";
import Route, {
  ADMIN_PREFIX_WITH_PROJECT,
} from "components/common/History/Route";
import { CLUSTERS } from "utils/constants/routes";
import ProjectContextProvider from "components/common/History/ProjectContextProvider";
import { newClusterModal } from "state/newcluster/services";

const Index = React.lazy(() => import("./index"));
const Create = React.lazy(() => import("./create"));
const Details = React.lazy(() => import("./details"));
const Certificates = React.lazy(() => import("./certificates"));
const NewClusterModal = React.lazy(() =>
  import("../../components/common/NewCluster")
);
const ApplianceDetails = React.lazy(() => import("./appliances/details"));

export default createNamespace({
  namespace: "clusters",
  Component: function Main() {
    return (
      <>
        <Route adminEnabled exact path={CLUSTERS.ROOT} component={Index} />
        <Route
          exact
          path={CLUSTERS.APPLIANCES_OVERVIEW}
          component={ApplianceDetails}
        />
        <Route exact path={CLUSTERS.CREATE_CLUSTER} component={Create} />
        <Route
          exact
          path={CLUSTERS.NEW_CLUSTER}
          component={(props) => {
            if (newClusterModal?.isOpened) {
              return null;
            }
            return <NewClusterModal />;
          }}
        />

        <Route
          exact
          path={CLUSTERS.CREATE_CLUSTER_WITH_SELECTED_PROFILE}
          component={Create}
        />
        <Route
          exact
          adminEnabled
          path={CLUSTERS.CLUSTER_DETAILS}
          component={(props) => {
            if (
              ["overview", "appliances", "new"].includes(
                props.match?.params?.id
              )
            ) {
              return null;
            }
            return <Details {...props} />;
          }}
        />
        <Route
          exact
          adminOnly
          path={`${ADMIN_PREFIX_WITH_PROJECT.replace("/admin", "")}${
            CLUSTERS.TAB_DETAILS
          }`}
          component={(props) => (
            <ProjectContextProvider>
              <Details {...props} />
            </ProjectContextProvider>
          )}
        />
        <Route
          adminEnabled
          path={`${ADMIN_PREFIX_WITH_PROJECT.replace("/admin", "")}${
            CLUSTERS.CERTIFICATES
          }`}
          component={(props) => (
            <ProjectContextProvider>
              <Certificates {...props} />
            </ProjectContextProvider>
          )}
        />
        <Route
          exact
          adminEnabled
          path={CLUSTERS.TAB_DETAILS}
          component={Details}
        />
        <Route
          exact
          adminEnabled
          path={CLUSTERS.SCAN_DETAILS}
          component={Details}
        />
        <Route
          adminEnabled
          path={CLUSTERS.CERTIFICATES}
          component={Certificates}
        />
        <Route
          exact
          adminEnabled
          path={CLUSTERS.IPAM_DNS_DETAILS}
          component={Details}
        />
      </>
    );
  },
});
