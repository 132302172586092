import moment from "moment";
import { createSelector } from "reselect";
import { getAllProjects } from "state/auth/selectors";
import { BAREMETAL_ENVS, ENVIRONMENTS } from "utils/constants";
import { parseResourcesChartData } from "utils/parsers";
import {
  ORGANIZATION_OVERVIEW_FILTERS_MODULE,
  projectsCostFetcher,
  projectsConsumptionFetcher,
} from "./services";

export const CHART_CLOUDS = ENVIRONMENTS.filter(
  (env) => !BAREMETAL_ENVS.includes(env.apiKey)
).map((env) => ({ metadata: { uid: env.apiKey, name: env.name } }));

export const getProjectCostsData = createSelector(
  projectsCostFetcher.selector,
  getAllProjects,
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data,
  (
    { result },
    projects,
    { filter = {}, query = {}, filterBy = [], groupBy } = {}
  ) => {
    if (!result) {
      return null;
    }

    let chartResources = [...(groupBy === "project" ? projects : CHART_CLOUDS)];

    if (filterBy.length > 0) {
      chartResources = chartResources.filter((resource) =>
        filterBy.includes(resource.metadata.uid)
      );
    }

    const resources = chartResources.map((resource) => {
      const existingData = (result?.resources || []).find(
        (data) =>
          (data.entity?.uid || data.entity.name) === resource.metadata.uid
      );

      return {
        entity: {
          name: resource.metadata.name,
          uid: resource.metadata.uid,
        },
        data: Array.from({ length: filter?.value || 0 }).reduce(
          (acc, _, index) => {
            const currentXValue = moment(query.startTime).add(index, "days");
            const currentXValueToMs = currentXValue.valueOf() * 1000000;

            const isPastCurrentDay = moment().diff(currentXValue) < 0;

            if (isPastCurrentDay) {
              acc[index] = {
                timestamp: currentXValueToMs,
                total: null,
              };
              return acc;
            }

            if (!existingData || existingData?.total?.total === 0) {
              acc[index] = {
                timestamp: currentXValueToMs,
                total: 0,
              };
              return acc;
            }

            const dayCost =
              (existingData.data || []).find((data) =>
                moment(data.timestamp / 1000000).isSame(currentXValue, "day")
              )?.total || 0;

            acc[index] = {
              timestamp: currentXValueToMs,
              total: index > 0 ? acc[index - 1]?.total + dayCost : dayCost,
            };
            return acc;
          },
          []
        ),
      };
    });

    const dailyUsage = parseResourcesChartData({
      result: resources,
      getY: (item) => item?.total,
      isCumulative: true,
    });

    return dailyUsage;
  }
);

export const getProjectConsumptionData = createSelector(
  projectsConsumptionFetcher.selector,
  getAllProjects,
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data,
  (
    { result },
    projects,
    { filter = {}, query = {}, filterBy = [], consumptionType, groupBy } = {}
  ) => {
    if (!result) {
      return null;
    }

    let chartResources = [...(groupBy === "project" ? projects : CHART_CLOUDS)];

    if (filterBy.length > 0) {
      chartResources = chartResources.filter((resource) =>
        filterBy.includes(resource.metadata.uid)
      );
    }

    const entities = chartResources.map((resource) => {
      const existingData = (result?.resources || []).find((data) => {
        return (data.entity?.uid || data.entity.name) === resource.metadata.uid;
      });

      return {
        entity: {
          name: resource.metadata.name,
          uid: resource.metadata.uid,
        },
        data: Array.from({ length: filter?.value || 0 }).map((_, index) => {
          const currentXValue = moment(query.startTime).add(index, "days");
          const currentXValueToMs = currentXValue.valueOf() * 1000000;

          const isPastCurrentDay = moment().diff(currentXValue) < 0;

          if (isPastCurrentDay) {
            return {
              timestamp: currentXValueToMs,
              usage: null,
            };
          }

          if (!existingData) {
            return {
              timestamp: currentXValueToMs,
              usage: 0,
            };
          }

          const conversion = consumptionType === "cpu" ? 1000000 : 1048576;
          const dayConsumption =
            (existingData?.data || []).find((dayUsage) =>
              moment(dayUsage.timestamp / 1000000).isSame(currentXValue, "day")
            )?.[consumptionType] / conversion || 0;

          return {
            timestamp: currentXValueToMs,
            usage: dayConsumption,
          };
        }),
      };
    });

    const dailyUsage = parseResourcesChartData({
      result: entities,
      getY: (item) => item?.usage,
    });

    return dailyUsage;
  }
);

export const getOrganizationGroupBySelector = createSelector(
  (state) => state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data?.groupBy,
  getAllProjects,
  (groupBy, projects) => {
    const getOptions = (items) => {
      return (items || []).map((item) => ({
        label: item.metadata.name,
        value: item.metadata.uid,
      }));
    };

    if (groupBy === "project") {
      return getOptions(projects);
    }

    return getOptions(CHART_CLOUDS);
  }
);
