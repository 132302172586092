import React from "react";

import createNamespace from "services/language";
import { SETTINGS } from "utils/constants/routes";

import SettingsLayout from "./SettingsLayout";

import Loader from "components/ui/Loader";
import Route from "components/common/History/Route";
import { useParams } from "react-router";
import Switch from "components/common/Switch";

const CloudAccountForm = React.lazy(() => import("./cloudaccounts/create"));
const ViewCloudAccount = React.lazy(() =>
  import("./cloudaccounts/create").then((module) => ({
    default: module.ViewOnlyForm,
  }))
);
const CloudAccounts = React.lazy(() => import("./cloudaccounts"));

const SSHKeys = React.lazy(() => import("./sshKeys"));
const CreateSSHKey = React.lazy(() => import("./sshKeys/create"));
const BackupLocation = React.lazy(() => import("./backuplocations/create"));

const MaintenancePolicies = React.lazy(() => import("./maintenancepolicies"));
const CreateMaintenancePolicy = React.lazy(() =>
  import("./maintenancepolicies/create")
);

const Registries = React.lazy(() => import("./registries"));
const CreateHelmRegistry = React.lazy(() =>
  import("./registries/helmregistries/create")
);
const CreatePackRegistry = React.lazy(() =>
  import("./registries/packregistries/create")
);
const CreateOCIRegistry = React.lazy(() => import("./registries/oci/create"));

const PrivateCloudGateways = React.lazy(() => import("./overlords"));
const OverlordsCreate = React.lazy(() => import("./overlords/create"));
const OverlordConfigureModal = React.lazy(() =>
  import("./overlords/configure")
);
const SetOverlordNodesModal = React.lazy(() => import("./overlords/setNodes"));
const SSO = React.lazy(() => import("./sso"));
const PasswordPolicy = React.lazy(() => import("./passwordpolicy"));
const BillingDetails = React.lazy(() => import("./billingdetails"));
const PlanDetails = React.lazy(() => import("./plandetails"));
const BackupLocations = React.lazy(() => import("./backuplocations"));
const Certificates = React.lazy(() => import("./certificates"));
const Certificate = React.lazy(() => import("./certificates/create"));
const AuditTrail = React.lazy(() => import("./audittrails/create"));
const AuditTrails = React.lazy(() => import("./audittrails"));
const ApiKeys = React.lazy(() => import("./apikeys"));
const Alerts = React.lazy(() => import("./alerts"));
const CloudRates = React.lazy(() => import("./cloudrates"));
const Macros = React.lazy(() => import("./macros"));

const root = "settings";

function RegistryCreateOrEdit() {
  const params = useParams();
  return (
    <Switch value={params.tab}>
      <Switch.Case value="pack">
        <CreatePackRegistry />
      </Switch.Case>
      <Switch.Case value="helm">
        <CreateHelmRegistry />
      </Switch.Case>
      <Switch.Case value="oci">
        <CreateOCIRegistry />
      </Switch.Case>
    </Switch>
  );
}

function SubRoutes() {
  return (
    <SettingsLayout>
      <React.Suspense fallback={<Loader />}>
        <Route
          adminEnabled
          exact
          path={SETTINGS.ROOT}
          component={CloudAccounts}
        />
        <Route
          adminEnabled
          path={SETTINGS.CLOUD_ACCOUNTS}
          component={CloudAccounts}
        />
        <Route
          exact
          adminEnabled
          path={SETTINGS.CLOUD_ACCOUNT}
          component={ViewCloudAccount}
        />
        <Route
          exact
          adminEnabled
          path={SETTINGS.CREATE_CLOUD_ACCOUNT}
          component={CloudAccountForm}
        />
        <Route
          exact
          adminEnabled
          path={SETTINGS.EDIT_CLOUD_ACCOUNT}
          component={CloudAccountForm}
        />
        <Route
          adminOnly
          exact
          path={SETTINGS.CLOUD_RATES}
          component={CloudRates}
        />
        <Route path={SETTINGS.SSH_KEYS} component={SSHKeys} />
        <Route path={SETTINGS.CREATE_SSH_KEY} component={CreateSSHKey} />
        <Route path={SETTINGS.EDIT_SSH_KEY} component={CreateSSHKey} />
        <Route
          path={SETTINGS.MAINTENANCE_POLICIES}
          component={MaintenancePolicies}
        />
        <Route
          adminOnly
          path={SETTINGS.CREATE_MAINTENANCE_POLICY}
          component={CreateMaintenancePolicy}
        />
        <Route adminEnabled path={SETTINGS.ALERTS} component={Alerts} />

        <Route adminOnly path={SETTINGS.REGISTRIES} component={Registries} />
        <Route
          adminOnly
          path={SETTINGS.EDIT_REGISTRY}
          component={RegistryCreateOrEdit}
        />
        <Route
          adminOnly
          path={SETTINGS.CREATE_REGISTRY}
          component={RegistryCreateOrEdit}
        />

        <Route
          adminOnly
          path={SETTINGS.PRIVATE_CLOUD_GATEWAYS}
          component={PrivateCloudGateways}
        />
        <Route
          adminOnly
          exact
          path={SETTINGS.CREATE_PRIVATE_CLOUD_GATEWAY}
          component={OverlordsCreate}
        />
        <Route
          adminOnly
          exact
          path={SETTINGS.EDIT_PRIVATE_CLOUD_GATEWAY}
          component={OverlordConfigureModal}
        />
        <Route
          adminOnly
          exact
          path={SETTINGS.PRIVATE_CLOUD_GATEWAY_NODES}
          component={SetOverlordNodesModal}
        />
        <Route adminOnly exact path={SETTINGS.SSO} component={SSO} />
        <Route
          adminOnly
          exact
          path={SETTINGS.PASSWORD_POLICY}
          component={PasswordPolicy}
        />
        <Route
          adminOnly
          path={SETTINGS.BILLING_DETAILS}
          component={BillingDetails}
        />
        <Route
          adminEnabled
          path={SETTINGS.BACKUP_LOCATIONS}
          component={BackupLocations}
        />
        <Route
          adminEnabled
          path={SETTINGS.CREATE_BACKUP_LOCATION}
          component={BackupLocation}
        />
        <Route
          adminEnabled
          path={SETTINGS.EDIT_BACKUP_LOCATION}
          component={BackupLocation}
        />
        <Route adminOnly path={SETTINGS.PLAN_DETAILS} component={PlanDetails} />
        <Route
          adminOnly
          path={`/${root}/certificates`}
          component={Certificates}
        />
        <Route
          adminEnabled
          path={`/${root}/certificates/create`}
          component={Certificate}
        />
        <Route
          adminEnabled
          path={`/${root}/certificates/:uid/edit`}
          component={Certificate}
        />
        <Route
          adminEnabled
          path={SETTINGS.AUDIT_TRAILS}
          component={AuditTrails}
        />
        <Route adminOnly path={SETTINGS.API_KEYS} component={ApiKeys} />
        <Route
          adminOnly
          path={SETTINGS.CREATE_AUDIT_TRAILS}
          component={AuditTrail}
        />
        <Route
          adminOnly
          path={SETTINGS.EDIT_AUDIT_TRAILS}
          component={AuditTrail}
        />
        <Route adminEnabled path={SETTINGS.MACROS} component={Macros} />
      </React.Suspense>
    </SettingsLayout>
  );
}

export default createNamespace({
  namespace: "settings",
  Component: function Main() {
    return <Route adminEnabled path={SETTINGS.ROOT} component={SubRoutes} />;
  },
});
