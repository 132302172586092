import store from "services/store";
import { VpcidSchema } from "utils/schemas";

import { isSupervizedEnvironment } from "state/cluster/selectors/create";
import api from "services/api";
import { fetchAwsCloudConfigParams } from "../../nodes";
import { regionsFetcher, sshFetcher } from "state/cluster/services/create";

import clusterFormActions from "../form";

function onChangeStaticPlacement(value) {
  return (dispatch) => {
    if (!value) {
      dispatch(
        clusterFormActions.onChange({
          name: "vpcid",
          module: "cluster",
          value: "",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "vpcid",
          module: "cluster",
        })
      );
      dispatch(
        clusterFormActions.validateField({
          name: "ssh",
          module: "cluster",
        })
      );
    }
    dispatch(
      clusterFormActions.validateField({
        name: "ssh",
        module: "cluster",
      })
    );
  };
}

function selectCredentialEffect() {
  return async (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          ssh: "",
          region: "",
          vpcid: "",
        },
      })
    );

    dispatch(regionsFetcher.fetch());
  };
}

function selectRegionEffect(region) {
  return (dispatch, getState) => {
    const accountUid = getState().forms.cluster.data.credential;
    const selectedCloud = getState().cluster.create.selectedCloud;

    // TODO: to be changed with a selectors
    const isSupervized = isSupervizedEnvironment(getState());

    const vpcids = api
      .get(
        `v1/clouds/${selectedCloud}/regions/${region}/vpcs?cloudAccountUid=${accountUid}`
      )
      .then((res) => res.vpcs);

    if (isSupervized) {
      store.dispatch(fetchAwsCloudConfigParams({ type: "create" }));
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          ssh: "",
          vpcid: "",
        },
      })
    );

    dispatch({
      type: "CLUSTER_CLOUD_SETTINGS_VPCS",
      promise: vpcids,
      schema: [VpcidSchema],
    });

    dispatch(sshFetcher.fetch());
  };
}

export function onVpcIdChange(value) {
  return function thunk(dispatch, getState) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          vpcid: value,
          controlPlane: {
            azs: [],
          },
        },
      })
    );

    const formErrors = getState().forms.cluster.errors;
    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: formErrors.map((error) => {
          if (error.field.startsWith("controlPlane")) {
            return {
              ...error,
              result: false,
            };
          }

          return error;
        }),
      })
    );
  };
}

export function onAzToggle(value) {
  return function thunk(dispatch, getState) {
    const fieldData = getState().forms.cluster.data.controlPlane;
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          controlPlane: {
            ...fieldData,
            azs: value,
          },
        },
      })
    );

    const formErrors = getState().forms.cluster.errors;
    const acceptedErrors = value.map((val) => `controlPlane.subnet_${val}`);
    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: formErrors.map((error) => {
          if (
            error.field.startsWith("controlPlane.subnet_") &&
            !acceptedErrors.includes(error.field)
          ) {
            return {
              ...error,
              result: false,
            };
          }

          return error;
        }),
      })
    );
  };
}

export function onInstanceOptionChange(value, poolIndex) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      instanceOption: value,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${poolIndex}.maxPricePercentage`,
      })
    );
  };
}

const effects = {
  selectCredentialEffect,
  selectRegionEffect,
  onChangeStaticPlacement,
};

export default effects;
