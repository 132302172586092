import { newClusterModal } from "state/newcluster/services";
import { productTourModal } from "./services";

export function openProductTourModal({ onboarding }) {
  return (dispatch, getState) => {
    productTourModal.open({ onboarding });
  };
}

export function setTourNextStep() {
  return async (dispatch, getState) => {
    const state = getState();
    const currentStep = state.productTour.stepNumber;
    const totalSteps = state.productTour.totalSteps;

    if (currentStep === totalSteps) {
      if (productTourModal.data.onboarding) {
        const clusterProfileListFetcher = (
          await import("state/clusterprofile/services")
        ).clusterProfileListFetcher;

        dispatch(clusterProfileListFetcher.fetch());
        newClusterModal.open();
      }

      productTourModal.close();
      dispatch({
        type: "RESET_TOUR_MODAL",
      });

      dispatch({
        type: "SET_FINISHED_TOUR",
        finishedTour: true,
      });

      return;
    }

    dispatch({
      type: "TOUR_MODAL_NEXT_STEP",
    });
  };
}

export function setTourPreviousStep() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = state.productTour.stepNumber;

    if (currentStep > 1) {
      dispatch({
        type: "TOUR_MODAL_PREVIOUS_STEP",
      });
    }
  };
}
