import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Modal from "components/ui/Modal";
import { TenantRoleSelector } from "components/common/ScopedRolesSelector";
import ActionMenu from "components/common/ActionMenu";
import AddNewItem from "components/styled/AddNewItem";

import createFormActions from "modules/form/actions";

import { createForm } from "modules/form";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";

import {
  tenantRolesModal,
  onTenantRoleRemove,
} from "state/roleManagement/actions/tenantRoles";
import createList, { Blocks } from "modules/list";

import { RoleSchema } from "utils/schemas";
import { red } from "utils/constants/colors";

export default function createTenantRoleManagement({
  updateData,
  getSelectedEntityRoles,
  listActions,
}) {
  const moduleName = "tenantRoles";
  const tenantRolesValidator = new Validator();
  tenantRolesValidator.addRule(["roles"], Missing());

  const formActions = createFormActions({
    validator: tenantRolesValidator,
    submit: updateData,
    init: () => {
      return Promise.resolve({
        roles: getSelectedEntityRoles().map((role) => role.guid),
      });
    },
  });

  const TenantRolesForm = createForm({
    Component: function Form() {
      return <TenantRoleSelector name="roles" />;
    },
    actions: formActions,
  });

  function openModal() {
    return (dispatch) => {
      dispatch(formActions.init({ module: moduleName }));

      tenantRolesModal.open().then(() => {
        return dispatch(formActions.submit({ module: moduleName }));
      });
    };
  }

  const ListModule = createList({
    actions: listActions,
    schema: [RoleSchema],
  });

  function TableRowActions({ selectedEntity, onRemove }) {
    const { t } = useTranslation();
    const { guid } = selectedEntity;

    return selectedEntity.metadata.inherited ? null : (
      <ActionMenu
        options={[
          {
            key: "remove",
            label: t("Remove"),
            color: red,
            onClick: () => onRemove(guid),
          },
        ]}
      />
    );
  }

  const ConnectedTableRowActions = connect(() => ({}), {
    onRemove: onTenantRoleRemove,
  })(TableRowActions);

  function TenantRolesModule({ openModal }) {
    const { t } = useTranslation();

    const columns = [
      {
        title: t("Tenant Role"),
        key: ["tenant-role"],
        render: function renderRole(data) {
          return (
            <span>
              {data.metadata.name}{" "}
              {data.metadata?.inherited && `(${t("team inherited")})`}
            </span>
          );
        },
      },
      {
        title: "",
        render: function renderTableRowActions(data) {
          return <ConnectedTableRowActions selectedEntity={data} />;
        },
        key: "actions",
      },
    ];

    return (
      <>
        <ListModule module={moduleName}>
          <Blocks.Table columns={columns} />
        </ListModule>
        <AddNewItem onClick={() => openModal(null)} data-qa="add_tenant_role">
          {t("Add Tenant Role")}
        </AddNewItem>
        <Modal service={tenantRolesModal} title={t("Add Roles")}>
          <TenantRolesForm module={moduleName} />
        </Modal>
      </>
    );
  }

  return connect(null, {
    openModal,
  })(TenantRolesModule);
}
