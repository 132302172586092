import _ from "lodash";
import store, { getStoreEntity } from "services/store";
import fetchers from "./services";
import { getPackValuesWithPresetsComment } from "utils/parsers";
import { PackSchema } from "utils/schemas";
import { UBUNTU_ADVANTAGE_SCHEMAS } from "utils/constants/schemas";
import { getUbuntuAdvantagePresets } from "utils/yaml";
import { presentUniquePackName } from "utils/presenters";

export function generateUbuntuAdvantagePresets(data) {
  if (!data) {
    return;
  }
  if (!data.enabled) {
    return [
      getUbuntuAdvantagePresets({ field: "enabled", value: false }, data),
    ];
  }
  return Object.keys(data).map((field) =>
    getUbuntuAdvantagePresets({ field, value: data[field] }, data)
  );
}

export function mapFormDataToLayers(formData = {}) {
  if (formData.type === "manifest") {
    const { type, persisted, ...rest } = formData;
    return {
      type,
      config: {
        ...rest,
      },
    };
  }

  const {
    packType,
    version,
    pack,
    repository,
    values,
    packUid,
    presets,
    manifests,
    installOrder,
    displayName,
    isOci,
    name,
    logo,
  } = formData;
  const packVersions = fetchers.packVersionsFetcher.selector(
    store.getState()
  )?.result;
  const selectedPackVersion = packVersions?.find(
    (pack) => pack.tag === version
  );
  const packGuid = selectedPackVersion?.pack?.guid;
  const packValues = getStoreEntity(packGuid, PackSchema);
  const packName = formData.packType === "manifest" ? name : pack || name;

  return {
    type: packType,
    config: {
      registryUid: repository,
      name: packName,
      tag: version,
      isOci,
      values,
      packUid,
      logo: selectedPackVersion?.logoUrl || logo,
      presets: packValues?.spec?.presets,
      ubuntuAdvantagePresets: generateUbuntuAdvantagePresets(
        presets?.ubuntuAdvantage
      ),
      schema: [
        ...(packValues?.spec?.schema || formData.schema || []),
        ...(presets?.ubuntuAdvantage?.enabled ? UBUNTU_ADVANTAGE_SCHEMAS : []),
      ],
      packVersionGuid: selectedPackVersion?.guid,
      selectedPresets: presets,
      installOrder,
      displayName,
      manifests: (manifests || []).filter((manifest) => !!manifest?.name),
    },
  };
}

export function extractPayload(layers = []) {
  return layers.reduce((acc, layer) => {
    let type = "spectro";
    const config = layer.config;
    const packName = presentUniquePackName(config);

    if (config) {
      let manifests = config?.manifests
        ?.filter((manifest) => (manifest.uid ? true : !!manifest?.name))
        .map((manifest) => ({
          // On layer editing: if we already have some manifests and change the pack name,
          // we cannot use the manifest uids as they are linked to the old pack name
          uid: packName === layer.guid ? manifest.uid : undefined,
          name: manifest?.name,
          content: manifest?.content,
        }));

      const values = getPackValuesWithPresetsComment(
        layer.config.values,
        layer.config.selectedPresets
      );

      if (layer.type === "manifest") {
        type = "manifest";

        return [
          ...acc,
          {
            installOrder: layer.config.installOrder || "0",
            type,
            name: layer.config.name,
            uid: layer.config.packUid || "spectro-manifest-pack",
            packUid: layer.config.packUid,
            manifests,
            values,
          },
        ];
      }

      if (layer.type === "helmChart") {
        type = "helm";
      }

      if (config.isOci) {
        type = "oci";
      }
      const isBasicLayer = ["os", "cni", "csi", "k8s"].includes(layer.type);
      return [
        ...acc,
        {
          installOrder: isBasicLayer ? undefined : layer.installOrder || "0",
          layer: config.isOci ? "addon" : config.layer,
          ..._.omit(config, [
            "presets",
            "schema",
            "packVersionGuid",
            "selectedPresets",
            "isOci",
            "ubuntuAdvantagePresets",
            "displayName",
          ]),
          type,
          uid: layer.config.packUid || "",
          name: packName,
          values,
          manifests,
        },
      ];
    }

    return acc;
  }, []);
}
