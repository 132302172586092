import api from "services/api";
import dataFetcher from "modules/dataFetcher";
import { ClusterSchema } from "utils/schemas";
import ModalService from "services/modal";
import store from "services/store";

export const WORKSPACES_FORM_MODULE = "workspace";
export const WIZARD_MODULE = "workspace";
export const CLUSTER_RBAC_FORM_MODULE = "clusterRbac";
export const NAMESPACE_RBAC_FORM_MODULE = "namespaceRbac";

export const cancelCreateWorkspaceModal = new ModalService();
export const removeRoleConfirmModal = new ModalService();
export const clusterRoleBindingsModal = new ModalService("clusterRoleBindings");
export const namespaceRoleBindingsModal = new ModalService(
  "namespaceRoleBindings"
);

export const clustersFetcher = dataFetcher({
  selectors: ["spectroclusters"],
  schema: [ClusterSchema],
  async fetchData(_, query) {
    const filters = {
      filter: {
        environment: [],
        clusterName: { contains: query?.search || "" },
        clusterState: "Running",
        limit: 50,
      },
    };
    const response = await api.post("v1/dashboard/spectroclusters", filters);

    return response?.items || [];
  },
});

export const workspaceClustersFetcher = dataFetcher({
  selectors: ["workspace", "clusters"],
  schema: [ClusterSchema],
  async fetchData(_) {
    const params = store.getState().location.params;
    const response = await api.get(`v1/workspaces/${params.id}`);

    return response?.spec?.clusterRefs || [];
  },
});
