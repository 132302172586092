import { createSelector } from "reselect";
import reverse from "lodash/reverse";

import { parseKiBToGB, round } from "utils/number";
import {
  clustersCostsFetcher,
  clustersConsumptionFetcher,
  PROJECT_OVERVIEW_FILTERS_MODULE,
} from "./services";
import moment from "moment";
import { parseResourcesChartData } from "utils/parsers";
import { CHART_CLOUDS } from "state/organization/selectors";

export const getSelectedKey = createSelector(
  (state) => state.forms?.[PROJECT_OVERVIEW_FILTERS_MODULE]?.data?.filter,
  (filter) => {
    return filter.type === "calendar"
      ? filter.type
      : `${filter.value} ${filter.unit}`;
  }
);

export const getClusterGroupByResources = (fetcher) =>
  createSelector(
    fetcher.selector,
    (state) => state.forms?.[PROJECT_OVERVIEW_FILTERS_MODULE]?.data?.groupBy,
    ({ result }, groupBy) => {
      return [
        ...(groupBy === "cluster"
          ? result || []
          : CHART_CLOUDS.map((cloud) => ({
              entity: {
                ...cloud.metadata,
              },
              data:
                (result || []).find(
                  ({ entity }) => entity.name === cloud.metadata.uid
                )?.data || [],
            }))),
      ];
    }
  );

export const getClusterCostsChartData = createSelector(
  getClusterGroupByResources(clustersCostsFetcher),
  (state) => state.forms?.[PROJECT_OVERVIEW_FILTERS_MODULE]?.data,
  (result, { filter = {}, query = {}, filterBy = [] } = {}) => {
    let chartResources = [...result];

    if (filterBy.length > 0) {
      chartResources = chartResources.filter((resource) =>
        filterBy.includes(resource.entity.uid)
      );
    }

    const resources = (chartResources || []).map(({ entity, data }) => {
      return {
        entity,
        data: Array.from({ length: filter?.value || 0 }).reduce(
          (acc, _, index) => {
            const currentXValue = moment(query.startTime).add(index, "days");
            const currentXValueToMs = currentXValue.valueOf() * 1000000;

            const isPastCurrentDay = moment().diff(currentXValue) < 0;

            if (isPastCurrentDay) {
              acc[index] = {
                timestamp: currentXValueToMs,
                total: null,
              };
              return acc;
            }

            const dayCost =
              data.find((clusterCost) =>
                moment(clusterCost.timestamp / 1000000).isSame(
                  currentXValue,
                  "day"
                )
              )?.total || 0;

            acc[index] = {
              timestamp: currentXValueToMs,
              total: index > 0 ? acc[index - 1]?.total + dayCost : dayCost,
            };
            return acc;
          },
          []
        ),
      };
    });

    return parseResourcesChartData({
      result: resources,
      getY: (item) => item?.total,
      isCumulative: true,
    });
  }
);

const getCpuInCores = (item) => round(item.cpu / 1000000, 5);
const getMemoryInGb = (item) => parseKiBToGB(item.memory);

export const getClusterConsumptionChartData = createSelector(
  getClusterGroupByResources(clustersConsumptionFetcher),
  (state) => state.forms?.[PROJECT_OVERVIEW_FILTERS_MODULE]?.data,
  (
    result,
    { filter = {}, query = {}, filterBy = [], consumptionType = "cpu" } = {}
  ) => {
    let chartResources = [...result];

    if (filterBy.length > 0) {
      chartResources = chartResources.filter((resource) =>
        filterBy.includes(resource.entity.uid)
      );
    }

    const resources = (chartResources || []).map(({ entity, data }) => ({
      entity,
      data: Array.from({ length: filter?.value || 0 }).map((_, index) => {
        const currentXValue = moment(query.startTime).add(index, "days");
        const currentXValueToMs = currentXValue.valueOf() * 1000000;

        const isPastCurrentDay = moment().diff(currentXValue) < 0;

        if (isPastCurrentDay) {
          return {
            timestamp: currentXValueToMs,
            usage: null,
          };
        }

        const dayUsage = data.find((clusterUsage) =>
          moment(clusterUsage.timestamp / 1000000).isSame(currentXValue, "day")
        );

        if (!dayUsage) {
          return {
            timestamp: currentXValueToMs,
            usage: 0,
          };
        }

        return {
          timestamp: currentXValueToMs,
          usage:
            consumptionType === "cpu"
              ? getCpuInCores(dayUsage)
              : getMemoryInGb(dayUsage),
        };
      }),
    }));

    return parseResourcesChartData({
      result: resources,
      getY: (item) => item.usage,
    });
  }
);

export const getCostsLegendItems = createSelector(
  getClusterCostsChartData,
  (chartData) => {
    if (!chartData?.length) {
      return [];
    }
    return reverse(
      chartData.map((data) => ({
        label: data.id,
        color: data.color,
      }))
    );
  }
);

export const getConsumptionLegendItems = createSelector(
  getClusterConsumptionChartData,
  (chartData) => {
    if (!chartData?.length) {
      return [];
    }
    return reverse(
      chartData.map((data) => ({
        label: data.id,
        color: data.color,
      }))
    );
  }
);
