import i18n from "i18next";

import { UserSchema, TeamSchema } from "utils/schemas";
import ListActions from "modules/list/actions";

import createFormActions from "modules/form/actions";
import { getStoreEntity } from "services/store";

import ModalService from "services/modal";
import historyService from "services/history";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import api from "services/api";
import notifications from "services/notifications";

import store from "services/store";
import { projectRolesListActions } from "state/roleManagement/actions/projectRoles";
import { getSelectedUser } from "state/roleManagement/selectors";

import { getEntity } from "utils/entities";
import { getFullName } from "utils/presenters";
import { tenantRolesListActions } from "state/roleManagement/actions/tenantRoles";

export const addNewUserModal = new ModalService();
export const deleteConfirmService = new ModalService();
export const confirmResetPassword = new ModalService("Reset User Password");
export const userDetailsModal = new ModalService();

export const USERS_MODULE = "users";

export const usersListActions = new ListActions({
  async fetchData(query) {
    const { search } = query;
    const data = await api.post(`v1/users/summary`, {
      filter: {
        name: {
          contains: search || "",
        },
      },
    });

    return data;
  },
  schema: [UserSchema],
});

async function submit(data) {
  const teams = getEntity(
    () => (data.teams || []).map((team) => team.key),
    [TeamSchema]
  )(store.getState());
  const payload = {
    metadata: {
      name: `${data.firstName} ${data.lastName}`,
    },
    spec: {
      emailId: data.emailId,
      firstName: data.firstName,
      lastName: data.lastName,
      roles: [],
      teams: teams.map((team) => team.metadata.uid),
    },
  };

  let response;
  try {
    response = await api.post("v1/users", payload);
  } catch (error) {
    notifications.error({
      message: i18n.t("Something went wrong when creating the user"),
      description: error.message,
    });
  }

  if (!response) {
    return;
  }

  notifications.success({
    message: i18n.t('User "{{username}}" has been created successfully', {
      username: `${data.firstName} ${data.lastName}`,
    }),
  });
  payload.metadata.uid = response.uid;
  store.dispatch(usersListActions.fetchItems(USERS_MODULE));
  historyService.push("/management/users");
}

export const validator = new Validator();
validator.addRule(["firstName", "lastName", "emailId"], Missing());

export function openUserModal() {
  return (dispatch) => {
    addNewUserModal.open().then(
      () => {
        return dispatch(createUserFormActions.submit({ module: USERS_MODULE }));
      },
      () => historyService.push("/management/users")
    );
  };
}

export const createUserFormActions = createFormActions({
  submit,
  validator,
});

export function setSelectedUser(user) {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECTED_ENTITY",
      selectedEntityId: user?.guid || null,
      entityType: "user",
    });

    if (user) {
      userDetailsModal.open();
      dispatch(projectRolesListActions.initialize("projectRoles"));
      dispatch(tenantRolesListActions.initialize("tenantRoles"));
    }
  };
}

export function fetchUsers() {
  return {
    type: "FETCH_USERS",
    promise: api.get("v1/users").then((res) => res.items),
    schema: [UserSchema],
  };
}

export function onUserRemove() {
  return (dispatch, getState) => {
    const selectedUserGuid = getSelectedUser(getState());
    deleteConfirmService.open({ guid: selectedUserGuid }).then(async () => {
      const user = getStoreEntity(deleteConfirmService.data.guid, UserSchema);
      const promise = api.delete(`v1/users/${user.metadata.uid}`);

      dispatch({
        type: "DELETE_USER",
        promise,
        schema: UserSchema,
      });

      try {
        await promise;
      } catch (err) {
        notifications.error({
          message: i18n.t(
            "Something went wrong when trying to delete the user"
          ),
          description: err.message,
        });
      }

      notifications.success({
        message: i18n.t('User "{{username}}" has been deleted successfully', {
          username: getFullName(user),
        }),
      });

      dispatch(setSelectedUser(""));
      dispatch(usersListActions.fetchItems(USERS_MODULE));
    });
  };
}

export function resetUserPassword() {
  return async function (dispatch, getState) {
    confirmResetPassword.open().then(async () => {
      const redirectUrl =
        getState().auth.currentOrganization?.redirectUrl || "/";
      const user = getSelectedUser(getState());
      const { emailId } = user.spec;
      const { uid } = user.metadata;

      try {
        const promise = api.patch(`v1/users/${uid}/password/reset`);

        dispatch({
          type: "SET_REDIRECT_URL",
          url: redirectUrl,
        });

        await promise;

        notifications.info({
          message: i18n.t(
            `An email has been sent to ${emailId}. Please follow the instructions to set a new password.`
          ),
        });
      } catch (err) {
        notifications.error({
          message: i18n.t("Something went wrong when trying to send the link."),
        });
      }
    });
  };
}

// TODO find out why we need set timeout here
export function fetchAllUsers() {
  return async (dispatch, getState) => {
    const listState = getState().list[USERS_MODULE];

    if (!listState) {
      await dispatch(usersListActions.initialize(USERS_MODULE));
    } else {
      await dispatch(usersListActions.nextPage(USERS_MODULE));
    }

    const newListState = getState().list[USERS_MODULE];
    if (newListState.nextToken) {
      setTimeout(() => dispatch(fetchAllUsers()), 500);
    }
  };
}
