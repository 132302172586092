import styled from "styled-components";
import { lightGray, midGray } from "utils/constants/colors";

const GreyContainer = styled.div`
  margin: 15px 10px 15px 0;

  > span {
    border-radius: 5px;
    background: ${lightGray};
    color: ${midGray};
    padding: 5px 10px;
    font-family: "Lekton", Arial, "sans-serif";
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
  }
`;

export default GreyContainer;
