import moment from "moment";

import api from "services/api";
import store, { getStoreEntity } from "services/store";
import ModalService from "services/modal";
import history from "services/history";

import {
  CronJobSchema,
  DeploymentSchema,
  PodSchema,
  DaemonSetSchema,
  StatefulSetSchema,
  JobSchema,
} from "utils/schemas";
import { GENERAL_DATE_FORMAT, WORKLOADS_TIME_PERIODS } from "utils/constants";
import { presentWorkloadChartData } from "utils/presenters";
import { getArrayFromQuery } from "utils/parsers";
import ListActions from "modules/list/actions";
import dataFetcher from "modules/dataFetcher";

import { getRawWorkspace } from "state/workspaces/selectors/details";
import { NAMESPACES_MODULE, namespacesListingActions } from "./details";
import { WORKLOAD_SCHEMAS } from "state/cluster/services/workloads";

export const DEPLOYMENTS_MODULE = "workspaceDeployments";
export const CRONJOBS_MODULE = "workspaceCronJobs";
export const PODS_MODULE = "workspacePods";
export const DAEMONSETS_MODULE = "workspaceDaemonSets";
export const STATEFULSETS_MODULE = "workspaceStatefulSets";
export const JOBS_MODULE = "workspaceJobs";
export const WORKSPACE_WORKLOAD_DETAILS = "workspaceWorkloadDetails";

export const workspaceSelectedWorkloadModal = new ModalService(
  WORKSPACE_WORKLOAD_DETAILS
);

export function parseWorkloadsResponse(items, type) {
  return (items?.clusters || [])
    .reduce((acc, item) => {
      const clusterMetadata = item?.metadata;
      const data = (item[type] || []).map((item) => ({
        ...item,
        cluster: clusterMetadata,
        status: {
          ...item.status,
          containers: (item.status?.containers || []).map(
            (statusContainer) => ({
              ...statusContainer,
              resources: (item.spec?.containers || []).find(
                (container) => container.name === statusContainer.name
              )?.resources,
            })
          ),
        },
      }));
      if (data?.length) {
        acc.push(...data);
      }
      return acc;
    }, [])
    .sort((item1, item2) =>
      item1.metadata.namespace > item2.metadata.namespace ? 1 : -1
    );
}
export const cronjobsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getRawWorkspace(store.getState())?.metadata?.uid;
    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/cronjob`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "cronjobs"),
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [CronJobSchema],
});

export const deploymentsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getRawWorkspace(store.getState())?.metadata?.uid;
    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/deployment`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "deployments"),
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [DeploymentSchema],
});

export const podsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getRawWorkspace(store.getState())?.metadata?.uid;
    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/pod`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "pods"),
    };
  },
  initialQuery: () => {
    const query = history.getQuery();
    const namespaces = getArrayFromQuery(query?.namespaces);
    const clusters = getArrayFromQuery(query?.clusters);
    return { namespaces, clusters };
  },
  schema: [PodSchema],
});

export const daemonSetsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getRawWorkspace(store.getState())?.metadata?.uid;
    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/daemonset`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "daemonSets"),
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [DaemonSetSchema],
});

export const statefulSetsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getRawWorkspace(store.getState())?.metadata?.uid;

    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/statefulset`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "statefulSets"),
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [StatefulSetSchema],
});

export const jobsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getRawWorkspace(store.getState())?.metadata?.uid;
    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/job`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "jobs"),
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [JobSchema],
});

export const workspaceSelectedWorkloadUsageFetcher = dataFetcher({
  selectors: [
    "workspace",
    (state) => getRawWorkspace(state)?.metadata.uid,
    (state) => state.location.params.flag,
    () => workspaceSelectedWorkloadModal?.data?.guid,
    "barChartData",
  ],
  fetchData: async ([_1, workspaceUid, currentTab, workloadGuid], filters) => {
    const { timeRange, clusters, namespaces } = filters || {};
    const [unit, unitType] = timeRange.split(" ");
    const period = WORKLOADS_TIME_PERIODS[timeRange].period;
    const workloadEntity = getStoreEntity(
      workloadGuid,
      WORKLOAD_SCHEMAS[currentTab]
    );

    const workloadType = currentTab.substring(0, currentTab.length - 1);
    const isPodWorkload = workloadType === "pod";

    const data = await api.post(
      "v1/dashboard/spectroclusters/resources/usage",
      {
        filter: {
          startTime: moment()
            .subtract(unit, unitType)
            .utc()
            .format(GENERAL_DATE_FORMAT),
          endTime: moment().utc().format(GENERAL_DATE_FORMAT),
          workspaces: [workspaceUid],
          clusters,
          namespaces,
          includeMasterMachines: true,
          workload: isPodWorkload
            ? {}
            : {
                type: workloadType,
                names: [workloadEntity.metadata.entity.name],
              },
          pods: isPodWorkload
            ? [workloadEntity.metadata.entity.name]
            : undefined,
        },
        options: {
          groupBy: workloadType,
          period,
        },
      }
    );

    return presentWorkloadChartData({ data, timeRange });
  },
});

export const workspaceWorkloadUsageFetcher = dataFetcher({
  selectors: [
    "workspace",
    (state) => getRawWorkspace(state)?.metadata.uid,
    (state) => state.location.params.flag,
    "barChartData",
  ],
  fetchData: async ([_1, workspaceUid, workloadTab, _3], filters) => {
    const { timeRange, namespaces = [], clusters = [] } = filters || {};
    const [unit, unitType] = timeRange.split(" ");
    const period = WORKLOADS_TIME_PERIODS[timeRange].period;

    const data = await api.post(
      "v1/dashboard/spectroclusters/resources/usage",
      {
        filter: {
          startTime: moment()
            .subtract(unit, unitType)
            .utc()
            .format(GENERAL_DATE_FORMAT),
          endTime: moment().utc().format(GENERAL_DATE_FORMAT),
          workload: {
            type:
              workloadTab === "pods"
                ? undefined
                : workloadTab.substring(0, workloadTab.length - 1),
          },
          workspaces: [workspaceUid],
          namespaces,
          clusters,
          includeMasterMachines: true,
        },
        options: {
          groupBy: "workspace",
          period,
        },
      }
    );

    return presentWorkloadChartData({ data, timeRange });
  },
});

export const workspacesWorkloadsModules = {
  pods: {
    listActions: podsListingActions,
    module: PODS_MODULE,
  },
  deployments: {
    listActions: deploymentsListingActions,
    module: DEPLOYMENTS_MODULE,
  },
  daemonsets: {
    listActions: daemonSetsListingActions,
    module: DAEMONSETS_MODULE,
  },
  statefulsets: {
    listActions: statefulSetsListingActions,
    module: STATEFULSETS_MODULE,
  },
  jobs: {
    listActions: jobsListingActions,
    module: JOBS_MODULE,
  },
  cronjobs: {
    listActions: cronjobsListingActions,
    module: CRONJOBS_MODULE,
  },
  namespaces: {
    listActions: namespacesListingActions,
    module: NAMESPACES_MODULE,
  },
};

export function initializeWorkloadsByTab(tab) {
  return (dispatch) => {
    const tabItem = workspacesWorkloadsModules?.[tab];
    if (!tabItem) {
      return;
    }
    dispatch(tabItem.listActions.initialize(tabItem.module));
  };
}
