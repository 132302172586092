import ModalService from "services/modal";
import dataFetcher from "modules/dataFetcher";
import ProfileStackModule from "modules/profileStack";
import { ClusterProfileSchema, EdgeMachineSchema } from "utils/schemas";
import api from "services/api";

export const EDGE_MACHINES_MODULE = "edgeMachines";
export const addEdgeMachinesDrawer = new ModalService("addEdgeMachine");
export const deleteEdgeMachine = new ModalService("deleteEdgeMachine");
export const clusterCreateEdgeMachinesDrawer = new ModalService(
  "clusterCreateEdgeMachines"
);
export const disksDetailsModal = new ModalService("disksDetails");

export const applianceProfilesStackModule = new ProfileStackModule({
  name: "appliance-details",
});

export const edgeMachineDetailsFetcher = dataFetcher({
  schema: EdgeMachineSchema,
  selectors: ["edgeMachineDetails"],
  async fetchData(_, applianceId) {
    const data = await api.get(`v1/edgehosts/${applianceId}`);
    return data;
  },
});

export const edgeMachineProfilesFetcher = dataFetcher({
  schema: [ClusterProfileSchema],
  selectors: ["edgeMachineDetails", "profiles"],
  async fetchData(_, applianceId) {
    const profiles = await api
      .get(`v1/edgehosts/${applianceId}/profiles`)
      .then(({ profiles }) =>
        profiles.map((profile) => ({
          ...profile,
          spec: { published: { ...profile.spec } },
        }))
      );

    return profiles || [];
  },
});
