import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Table } from "antd";

import * as COLORS from "utils/constants/colors";

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.layout === "horizontal" &&
    css`
      justify-content: space-between;
      flex-wrap: ${props.noWrap ? "nowrap" : "wrap"};
      flex-direction: row;
    `}
`;

export const ItemContainer = styled.div`
  ${(props) =>
    props.addMargins &&
    css`
      margin-bottom: 30px;
    `}
  width: ${(props) => props.cols && `calc(100% / ${props.cols})`};
`;

const ItemLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: ${COLORS.silver};
  margin-bottom: 5px;
`;

const ItemValue = styled.div`
  font-size: 15px;
  color: ${COLORS.nearBlack};
`;

const DescriptionItem = ({ label, value, layout, cols, addMargins }) => {
  return (
    <ItemContainer layout={layout} cols={cols} addMargins={addMargins}>
      <ItemLabel>{label}</ItemLabel>
      <ItemValue>{value || "-"}</ItemValue>
    </ItemContainer>
  );
};

const Descriptions = ({
  options,
  layout,
  cols,
  addMargins = true,
  noWrap = false,
}) => {
  function renderOptions(item, index) {
    return (
      <DescriptionItem
        {...item}
        key={index}
        layout={layout}
        cols={cols}
        addMargins={addMargins}
      />
    );
  }
  return (
    <DescriptionContainer layout={layout} noWrap={noWrap}>
      {options.map(renderOptions)}
    </DescriptionContainer>
  );
};

const Descriptors = styled(Table)`
  font-style: normal;
  font-weight: normal;
  color: #3e4856;
  font-size: 14px;
  line-height: 22px;

  .ant-table {
    background: transparent;
    thead {
      display: none;
    }
    tr td {
      white-space: nowrap;
      border-bottom: 1px solid #dadee3;
    }
  }

  label {
    font-size: 12px;
    color: #818ea1;
  }
`;

export const DescriptionsV2 = ({ descriptors }) => {
  const columns = useMemo(() => {
    return [
      {
        key: "label",
        render(data) {
          return <label>{data.label}</label>;
        },
      },
      {
        key: "value",
        render(data) {
          return typeof data.value === "function" ? data.value() : data.value;
        },
      },
    ];
  }, []);

  return (
    <Descriptors
      pagination={false}
      columns={columns}
      dataSource={descriptors}
    />
  );
};

export default Descriptions;
