import React from "react";
import styled from "styled-components";
import backgroundPath from "assets/images/login/background.png";
import { concreteWhite } from "utils/constants/colors";

const FullPageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${backgroundPath});
  background-color: ${concreteWhite};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export default function Page({ children }) {
  return <FullPageOverlay>{children}</FullPageOverlay>;
}
