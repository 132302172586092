import { createTencentFormFactory } from "modules/cluster/tencent";

import clusterFormActions from "../form";

export const tencentCloudForm = createTencentFormFactory({
  formActions: clusterFormActions,
  formModuleName: "cluster",
  getCloudAccountUid(state) {
    return state.forms.cluster.data.credential;
  },
});

function selectCredentialEffect() {
  return async function (dispatch, _) {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          sshKeys: [],
          region: "",
          vpcid: "",
        },
      })
    );
  };
}
function selectRegionEffect(region) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          sshKeys: [],
          vpcid: "",
        },
      })
    );
    dispatch(tencentCloudForm.fetchers.sshFetcher.fetch());
    dispatch(tencentCloudForm.fetchers.vpcsFetcher.fetch());
  };
}

const effects = {
  selectCredentialEffect,
  selectRegionEffect,
};

export default effects;
