import api from "services/api";

import {
  PackRegistriesSchema,
  HelmRegistriesSchema,
  OCIRegistriesSchema,
} from "utils/schemas";

import ListActions from "modules/list/actions";
import notifications from "services/notifications";
import i18next from "i18next";

export const packRegistriesActions = new ListActions({
  fetchData() {
    return api.get("v1/registries/pack/summary");
  },
  schema: [PackRegistriesSchema],
});

export const helmRegistriesActions = new ListActions({
  fetchData() {
    return api.get("v1/registries/helm/summary");
  },
  schema: [HelmRegistriesSchema],
});

export const ociRegistriesActions = new ListActions({
  fetchData() {
    return api.get("v1/registries/oci/summary");
  },
  schema: [OCIRegistriesSchema],
});

export function refreshRegistries() {
  return (dispatch, getState) => {
    const tab = getState().location.params.tab;
    const TAB_TO_SCHEMA = {
      helm: HelmRegistriesSchema,
      pack: PackRegistriesSchema,
      oci: OCIRegistriesSchema,
    };

    const promise = api.get(`v1/registries/${tab}/summary`);

    dispatch({
      type: "REFRESH_REGISTRIES",
      promise,
      schema: {
        items: [TAB_TO_SCHEMA[tab]],
      },
    });
  };
}

export function syncRegistry(registryUid, type) {
  return async (dispatch) => {
    try {
      await api.post(`v1/registries/${type}/${registryUid}/sync`);

      notifications.success({
        message: i18next.t("Registry syncing has started"),
      });
    } catch (e) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to sync the pack registry"
        ),
        description: e?.description,
      });
    }

    dispatch(refreshRegistries());
  };
}
