import React, { useEffect, createContext, useContext } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";
import { Select, Tooltip, DatePicker, Input as AntInput } from "antd";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";

import { getEntity } from "utils/entities";
import { gray } from "utils/constants/colors";

import {
  List,
  LoadAsYouScroll,
  SearchBar,
  Table,
  EmptyPage,
  EmptyResult,
  Pagination,
} from "modules/list/components";
import ListActions from "modules/list/actions";

import { FieldWrap, Error } from "components/styled/Field";
import { MaxTagPlaceholder } from "components/ui/Fields";
import Icon from "components/ui/Icon";
import {
  Checkbox as StyledCheckbox,
  CheckboxGroup as StyledCheckboxGroup,
} from "components/styled/Checkbox";

const SearchIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${gray};
  margin-right: 8px;
`;

// TODO: refactor this (it's from ui/Fields)
export function Field(Component) {
  function FieldComponent(
    { required = true, label, validation, ...rest },
    ref
  ) {
    let error = null;
    const { t } = useTranslation();

    if (validation) {
      error = validation[0];
    }

    function renderLabel() {
      if (!label) {
        return null;
      }
      return (
        <label>
          {label} {!required && t("(Optional)")}
        </label>
      );
    }

    return (
      <FieldWrap className={error && `has-${error.status}`}>
        {renderLabel()}
        <Component ref={ref} {...rest} validateStatus={error && error.status} />
        {error && <Error>{error.result}</Error>}
      </FieldWrap>
    );
  }

  FieldComponent.displayName = `Field(${Component.displayName})`;

  return React.forwardRef(FieldComponent);
}

export const ListContext = createContext({});

export function useList() {
  return useContext(ListContext);
}

export default function createList({
  initialQuery,
  parseItems,
  schema,
  actions,
  ...rest
}) {
  const listActions = new ListActions(
    actions || { ...rest, schema, initialQuery }
  );

  function ListModule({
    listState,
    children,
    initialize,
    fetchItems,
    module,
    ...rest
  }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initialize, [module]);

    if (listState === undefined) {
      return null;
    }

    return (
      <ListContext.Provider
        value={{ module, fetchItems, ...listState, ...rest }}
      >
        {children}
      </ListContext.Provider>
    );
  }

  const selectorsCache = {};

  const addReduxContext = connect(
    (state, ownProps) => {
      let itemsSelector = selectorsCache[ownProps.module];
      if (!itemsSelector) {
        const getListOfItems = createSelector(
          (state) => state.list[ownProps.module]?.items || {},
          (state) => state.list[ownProps.module]?.pages || new Set(),
          (state) => state.list[ownProps.module]?.currentPageNumber,
          (items, pages, currentPageNumber) => {
            if (listActions.hasPagination) {
              return items[currentPageNumber] || [];
            }
            const listOfIds = [...pages].reduce((accumulator, page) => {
              return accumulator.concat(items[page] || []);
            }, []);

            return [...new Set(listOfIds)];
          }
        );
        const getItems = getEntity(getListOfItems, schema);
        itemsSelector = getItems;
        if (parseItems) {
          itemsSelector = createSelector(getItems, parseItems);
        }
        selectorsCache[ownProps.module] = itemsSelector;
      }

      return {
        listState: state.list[ownProps.module],
        items: itemsSelector(state),
      };
    },
    (dispatch, ownProps) => {
      function onColumnSort(sorter) {
        return (dispatch, getState) => {
          if (!sorter) {
            return;
          }

          const query = getState().list[ownProps.module]?.query || {};
          const { columnKey, order } = sorter;
          const sortField = order ? columnKey : "";
          const orderTypes = { ascend: "asc", descend: "desc" };
          const sortOrder = orderTypes[order] || "";

          dispatch(
            listActions.batchChangeQuery({
              query: {
                ...query,
                sortField,
                sortOrder,
              },
              module: ownProps.module,
            })
          );
        };
      }

      return {
        initialize: () => {
          if (ownProps.preventInitOnMount) {
            return;
          }
          dispatch(
            listActions.initialize(
              ownProps.module,
              initialQuery && initialQuery()
            )
          );
        },
        fetchItems: () => dispatch(listActions.fetchItems(ownProps.module)),
        nextPage: () => dispatch(listActions.nextPage(ownProps.module)),
        goToPage: (pageNumber) =>
          dispatch(
            listActions.goToPage({ module: ownProps.module, pageNumber })
          ),
        changeQuery: (name, value) => {
          dispatch(
            listActions.changeQuery({ name, value, module: ownProps.module })
          );
        },
        onColumnSort: (arg1, arg2, sorter) => dispatch(onColumnSort(sorter)),
      };
    }
  );
  return addReduxContext(ListModule);
}

export function connectComponent(Component) {
  return function ConnectedListingComponent(props) {
    const context = useContext(ListContext);
    return <Component {...context} {...props} />;
  };
}

export function connectFiltersComponent(Component) {
  return function ConnectedComponent(props) {
    const name = props.name;
    const context = useContext(ListContext);
    return (
      <Component
        value={context.query[name]}
        onChange={context.changeQuery.bind(null, name)}
        {...props}
      />
    );
  };
}

// TODO: refactor this (it's from ui/Fields)
const LabelOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .anticon.anticon-info-circle {
    margin-left: 11px;
  }
`;

// TODO move this in a different component
function SelectWithOptions({ options = [], ...rest }) {
  function renderOption(option) {
    return (
      <Select.Option key={option.value} value={option.value}>
        <LabelOption>
          <span>{option.label}</span>{" "}
          {option.description && (
            <Tooltip title={option.description} placement="right">
              <Icon type="info-circle" />
            </Tooltip>
          )}
        </LabelOption>
      </Select.Option>
    );
  }

  return (
    <Select
      maxTagPlaceholder={(values) => (
        <MaxTagPlaceholder omittedValues={values} />
      )}
      {...rest}
    >
      {options.map(renderOption)}
    </Select>
  );
}

function ConnectedInput({ searchPrefix, name, ...rest }) {
  const context = useContext(ListContext);
  return (
    <AntInput
      value={context.query[name]}
      onChange={(ev) => context.changeQuery(name, ev?.target?.value)}
      prefix={searchPrefix ? <SearchIcon icon={faSearch} /> : null}
      name={name}
      {...rest}
    />
  );
}

function ConnectedCheckbox({ searchPrefix, name, ...rest }) {
  const context = useContext(ListContext);
  return (
    <StyledCheckbox
      checked={context.query[name]}
      onChange={(ev) => context.changeQuery(name, ev?.target?.checked)}
      prefix={searchPrefix ? <SearchIcon icon={faSearch} /> : null}
      name={name}
      {...rest}
    />
  );
}

export const Blocks = {
  List: connectComponent(List),
  LoadAsYouScroll: connectComponent(LoadAsYouScroll),
  Search: connectComponent(SearchBar),
  Table: connectComponent(Table),
  EmptyPage: connectComponent(EmptyPage),
  EmptyResult: connectComponent(EmptyResult),
  Pagination: connectComponent(Pagination),
  FilterFields: {
    Checkbox: Field(ConnectedCheckbox),
    Input: Field(ConnectedInput),
    CheckboxGroup: connectFiltersComponent(Field(StyledCheckboxGroup)),
    Select: connectFiltersComponent(Field(SelectWithOptions)),
    DatePicker: connectFiltersComponent(Field(DatePicker)),
    RangePicker: connectFiltersComponent(Field(DatePicker.RangePicker)),
  },
};
