import store from "services/store";
import api from "services/api";
import { PackVersionSchema } from "utils/schemas";

import fetchers, { MODULES } from "../services";
import { HELM_MODULE } from "state/packregistries/services";

export default function importedPacksActions(
  guid,
  { forms, addNewRepositoryModal }
) {
  const getState = () => {
    return store.getState().profileBuilder[guid];
  };

  const dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid,
    });
  };

  const refreshRepositoriesList = async (clusterId) => {
    await store.dispatch(fetchers.clusterPacksFetcher.key(clusterId).fetch());

    const layers = fetchers.clusterPacksFetcher
      .key(clusterId)
      .selector(store.getState()).result;

    return layers;
  };

  return {
    initImportForm: async () => {
      await store.dispatch(
        forms.importActions.init({
          module: MODULES.IMPORT_MODULE,
        })
      );
    },

    onImportLayerWarningMitigation: (pack) => {
      const errors = pack?.matchedRegistries?.some(
        (registries) => registries.length !== 1
      );
      if (!errors) {
        return;
      }
      dispatch({
        type: "PROFILE_BUILDER_UPDATE_LAYER_REPOS",
        expandedPack: pack,
        isImportClusterLayerExpanded: true,
      });
    },

    onImportLayerWarningMitigationCancel: () => {
      dispatch({
        type: "PROFILE_BUILDER_UPDATE_LAYER_REPOS",
        expandedPack: getState().expandedPack,
        isImportClusterLayerExpanded: false,
      });
    },

    onRepoSelect: (name, value) => {
      forms.importFieldChange(name, value);
    },
    addRegistryToSelectedPack: () => {
      const repoUid = store.getState().forms.importedPacks?.data?.packRepoId;
      dispatch({
        type: "PROFILE_BUILDER_ADD_REPO_TO_EXPANDED_LAYER",
        registryUid: repoUid,
      });

      store.dispatch(
        forms.importActions.onChange({
          module: MODULES.IMPORT_MODULE,
          name: "packRepoId",
          value: null,
        })
      );
    },

    resetSelectedRepo: () => {
      store.dispatch(
        forms.importActions.onChange({
          module: MODULES.IMPORT_MODULE,
          name: "packRepoId",
          value: null,
        })
      );
    },

    onClusterSelect: async (name, value) => {
      dispatch({
        type: "PROFILE_BUILDER_CLEAR_IMPORT_CLUSTER",
      });
      store.dispatch(
        forms.importActions.batchChange({
          module: MODULES.IMPORT_MODULE,
          updates: {
            [name]: value,
            packRepoId: null,
          },
        })
      );

      const layers = await refreshRepositoriesList(value);

      dispatch({
        type: "PROFILE_BUILDER_ADD_DRAFT_LAYERS",
        layers: layers || [],
      });

      const updates = layers?.reduce(
        (acc, pack) => ({
          ...acc,
          [pack.guid]: pack.matchedRegistries.length === 1,
        }),
        {}
      );

      store.dispatch(
        forms.importActions.batchChange({
          module: MODULES.IMPORT_MODULE,
          updates,
        })
      );
    },

    onLayerCheck: (layerGuid, value) => {
      dispatch({
        type: "PROFILE_BUILDER_TOGGLE_DRAFT_LAYERS",
        layerGuid,
        value,
      });
      store.dispatch(
        forms.importActions.onChange({
          module: MODULES.IMPORT_MODULE,
          name: layerGuid,
          value,
        })
      );
    },

    onAddNewRepository: () => {
      addNewRepositoryModal.open().then(async () => {
        await store.dispatch(
          forms.helmRegistryFormActions.submit({
            module: HELM_MODULE,
          })
        );
        store.dispatch(fetchers.helmRepositoriesFetcher.fetch());
      });
    },

    updateImportedPackLayers: async () => {
      const layers = getState().layers;
      const packLayers = getState().packLayers.filter(
        (layer) => layer.config.registryUid
      );

      let packVersions = [];
      const promises = packLayers.map((layer) =>
        api.get(
          `v1/packs/${layer.config.name}/registries/${layer.config.registryUid}?cloudType=all&layer=addOn`
        )
      );

      const responses = await Promise.all(promises);

      dispatch({
        type: "PROFILE_BUILDER_ADD_UIDS_TO_DRAFT_LAYERS",
        layers: layers.map((layer) => {
          if (layer.config?.packUid) {
            return { ...layer, isDraft: false };
          }
          const packLayer = responses.find(
            (pack) => pack.name === layer.config?.name
          );

          if (!packLayer) {
            return { ...layer, isDraft: false };
          }

          const pack =
            packLayer.tags?.find((t) => t.tag === layer.config.tag) || {};
          const packUid = pack?.packUid;
          const packValues =
            packLayer.packValues?.find((p) => p.packUid === packUid) || {};

          packVersions.push({
            ...pack,
            logoUrl: packLayer.logoUrl,
            name: packLayer.name,
          });

          return {
            ...layer,
            isDraft: false,
            config: {
              ...layer.config,
              ...packValues,
              packUid,
              uid: packUid,
              logo: packLayer?.logoUrl,
            },
          };
        }),
      });

      dispatch({
        type: "PROFILE_BUILDER_ADD_PACK_VERSION",
        data: packVersions,
        schema: [PackVersionSchema],
      });
    },
  };
}
