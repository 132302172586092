import history from "services/history";
import store from "services/store";
import { matchPath } from "react-router";
import i18next from "i18next";

import * as tasks from "utils/tasks";
import * as PATHS from "utils/constants/routes";
import { WORKLOADS_WITH_METRICS } from "utils/constants";
import api from "services/api";

import {
  getClusterByUid,
  getGaugeMetrics,
  fetchClusterProfile,
  attachClusterProfile,
  certsListActions,
  CERTS_MODULE,
  appliancesListActions,
} from "state/cluster/actions/details";
import { fetchRealTimeConsumptionData } from "state/cluster/services";
import { initProjectOverview } from "state/projectoverview/actions";

import { openImportProcedureModal } from "state/cluster/actions/edit";
import { displayImportProcedure } from "state/cluster/selectors/details";
import {
  fetchClusterAndNodes,
  fetchClusterEstimatedRate,
} from "state/cluster/actions/nodes";
import { fetchClusterAndPollEvents } from "state/cluster/actions/events";
import {
  cloudAccountsSummaryFetcher,
  clusterListActions,
} from "state/cluster/actions/list/clusters";
import { edgeMachinesListActions } from "state/cluster/actions/list/edgemachines";

import {
  fetchRepositories,
  setAvailableRepositories,
} from "state/clusterprofile/actions/layerConfig";
import { setIsClusterProfileEdit } from "state/clusterprofile/actions/edit";
import { getClusterProfileByUid } from "state/clusterprofile/actions/details";
import { fetchRoles } from "state/roles/actions/list";
import {
  fetchTenantInfo,
  redeemTenantToken,
  redirectToDefaultRoute,
  registerFormActions,
  selectProjectFromRoute,
  setPasswordFormActions,
} from "state/auth/actions";
import {
  projectFormActions,
  PROJECT_MODULE,
  projectModal,
  openCreateProjectModal,
} from "state/project/actions/create";
import { auditListActions } from "state/audit/actions";
import { openAccountModal } from "state/cloudaccounts/actions/create";
import { onCreateOverlordOpen } from "state/overlord/actions/create";
import {
  openOverlordConfigureModal,
  openSetOverlordNodesModal,
} from "state/overlord/actions/configure";
import {
  openPackRegistryModal,
  openHelmRegistryModal,
  openOciRegistryModal,
} from "state/packregistries/actions/create";
import {
  vmWareOverlordsFetcher,
  openstackOverlordsFetcher,
  maasOverlordsFetcher,
} from "state/cloudaccounts/services";
import { usersMetaFetcher } from "state/users/services";
import { overlordsListActions } from "state/overlord/actions/list";
import { OVERLORD_MODULE } from "state/overlord/services/list";
import { getRepositories } from "state/clusterprofile/selectors/layerConfig";
import {
  initCpuCoresChart,
  projectListActions,
} from "state/project/actions/list";
import {
  coresChartTimePeriodInit,
  monthlyCoreHoursChartCalendarChange,
} from "state/project/actions/metrics";
import { ssoIdpsFetcher, defaultTeamsFetcher } from "state/sso/services";
import {
  profileBuilderEditModule,
  profileListActions,
  clusterProfileListFetcher,
} from "state/clusterprofile/services";
import { systemProfileBuilderEditModule } from "state/profiles/appprofiles/services/create";
import {
  getCurrentContext,
  getCurrentUser,
  getFreemiumUsage,
} from "state/auth/selectors";
import { overlordFetcher, IPAM_MODULE } from "state/overlord/services/ipam";
import { ipamListActions } from "state/overlord/actions/ipam";
import { openSSHKeyModal } from "state/sshKeys/actions/create";
import { invoicesFetcher } from "state/billingdetails/services";
import { initAllBillingData } from "state/plandetails/actions";
import { ClusterSchema } from "utils/schemas";
import { dnsListActions } from "state/dns/actions/list";
import { DNS_MAPPING_MODULE } from "state/dns/services";
import {
  loadClusterScan,
  scansCountFetcher,
  watchScansTask,
} from "state/cluster/actions/scan";
import {
  backupLocationsListActions,
  openBackupLocationModal,
  BACKUP_LOCATIONS_MODULE,
} from "state/backuplocations/actions";
import {
  CERTS_LIST_MODULE,
  certificatesListActions,
  openCertificateModal,
} from "state/certificates/actions";
import {
  restoresListActions,
  backupsListActions,
  RESTORE_LIST_MODULE,
  BACKUP_LIST_MODULE,
} from "state/backups/services";
import { samlFormActions } from "state/sso/saml/actions";
import { oidcFormActions } from "state/sso/oidc/actions";
import { domainsFormActions } from "state/domains/actions";
import { getEnabledSSO } from "state/sso/selectors";
import { initClusterCosts } from "state/cluster/actions/costs";
import { AUDIT_TRAILS_LIST_MODULE } from "state/audittrails/services";
import {
  auditTrailsListActions,
  openAuditTrailModal,
} from "state/audittrails/actions";
import {
  projectEmailAlertsFormActions,
  projectWebhookAlertsFormActions,
  webhooksAlertsListing,
} from "state/alerts/actions";
import {
  EMAIL_ALERTS_MODULE,
  webhookAlertModal,
  WEBHOOK_ALERTS_MODULE,
} from "state/alerts/services";
import {
  onOrganizationChartsCalendarChange,
  organizationFilterFormActions,
} from "state/organization/actions";
import { ORGANIZATION_OVERVIEW_FILTERS_MODULE } from "state/organization/services";
import { workspaceListActions } from "state/workspaces/actions/listing";
import {
  filtersFormActions,
  FILTERS_FORM_MODULE,
} from "state/workspaces/actions/listing";
import { getWorkspaceByUid } from "state/workspaces/actions/details";
import { initializeWorkloadsByTab } from "state/workspaces/services/workloads";
import {
  workspaceRestoresListActions,
  workspaceBackupsListActions,
  WORKSPACE_RESTORE_LIST_MODULE,
  WORKSPACE_BACKUP_LIST_MODULE,
} from "state/workspaces/services/backups";
import {
  onPodsChartPeriodChange as onWorkspacePodsPeriodChange,
  workloadsFilters,
  WORKLOADS_FILTERS_MODULE,
} from "state/workspaces/actions/workloads";
import { initWorkspacesUsageCost } from "state/workspaces/actions/usagecost";
import {
  initializeWorkloadsTab,
  workloadsFilterFormActions,
} from "state/cluster/actions/workloads";
import { onChartPeriodChange } from "state/cluster/actions/workloads";
import { namespacesNamesFetcher } from "state/cluster/services/workloads/namespaces";
import { cloudRatesFormActions } from "state/cloudrates/actions";
import {
  applianceProfilesStackModule,
  edgeMachineDetailsFetcher,
  EDGE_MACHINES_MODULE,
} from "state/cluster/services/edgemachines";
import { apikeysListActions, openApiKeyModal } from "state/apikeys/actions";
import { API_KEYS_LIST_MODULE } from "state/apikeys/services";
import {
  ADMIN_PREFIX,
  ADMIN_PREFIX_WITH_PROJECT,
  PROJECT_PREFIX,
} from "components/common/History/Route";
import { openNewClusterModal } from "state/newcluster/actions";
import {
  wizardActions,
  CLUSTER_CREATION_STEPS,
  initClusterWizard,
} from "state/cluster/actions/create/common";
import { macrosFormActions, MACROS_FORM_MODULE } from "state/macros/actions";
import { getRawClusterProfile } from "state/clusterprofile/selectors/details";
import { fetchClusterProfile as fetchClusterProfileByUid } from "state/clusterprofile/actions/details";
import { fetchAllUsers } from "state/users/actions";

const MAP = {
  [PATHS.ROOT]: () => {
    const user = getCurrentUser(store.getState());
    if (user) {
      store.dispatch(redirectToDefaultRoute());
    }
  },
  [PATHS.CLUSTER_PROFILES.ROOT]: async () => {
    store.dispatch(profileListActions.initialize("clusterprofiles"));
  },
  [PATHS.AUTH.PASSWORD_ACTION]: async () => {
    await store.dispatch(redeemTenantToken());
    store.dispatch(setPasswordFormActions.init({ module: "setPassword" }));
  },
  [PATHS.AUTH.SIGNUP]: async () => {
    store.dispatch(registerFormActions.init({ module: "register" }));
  },
  [PATHS.AUTH.FORGOT_PASSWORD]: async () => {
    store.dispatch({
      type: "SET_LOGIN_STEP",
      step: "resetPassword",
    });
  },
  [PATHS.CLUSTER_PROFILES.DETAILS]: async ({ id }) => {
    if (id === "create") {
      return;
    }
    await store.dispatch(getClusterProfileByUid(id));
    await store.dispatch(fetchRepositories());
    store.dispatch(setIsClusterProfileEdit(true));
    const repositories = getRepositories(store.getState());
    store.dispatch(setAvailableRepositories(repositories));
  },
  [PATHS.ORGANIZATION_OVERVIEW.ROOT]: async () => {
    await Promise.allSettled([
      store.dispatch(
        organizationFilterFormActions.init({
          module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
        })
      ),
      projectListActions.initialize(PROJECT_MODULE),
    ]);

    store.dispatch(onOrganizationChartsCalendarChange());
  },
  [PATHS.AUDIT.ROOT]: () => {
    auditListActions.initialize("auditlogs");
    store.dispatch(usersMetaFetcher.fetch());
  },
  [PATHS.CLUSTERS.APPLIANCES_OVERVIEW]: async ({ id }) => {
    const machine = await store.dispatch(edgeMachineDetailsFetcher.fetch(id));
    const profiles = machine.spec.clusterProfileTemplates.map((template) => ({
      ...template,
      metadata: {
        uid: template.uid,
        name: template.name,
      },
      spec: {
        published: {
          ...template,
          packs: (template?.packs || []).map((pack) => {
            return {
              metadata: { uid: pack.packUid, name: pack.name },
              spec: { ...pack },
              ...pack,
            };
          }),
        },
      },
    }));
    applianceProfilesStackModule.actions.initialize({ profiles });
  },
  [PATHS.CLUSTERS.OVERVIEW]: async ({ id }, { isRouteMaintained }) => {
    api.collect();
    store.dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE" });
    await store.dispatch(fetchClusterAndNodes(id));
    // TODO: this should be engineered to happen automatically without the extra boilerplate
    if (!isRouteMaintained()) {
      return;
    }
    store.dispatch(getGaugeMetrics());
    store.dispatch(fetchClusterProfile());
    tasks.poolClusterErrors.start();
    tasks.pollClusterStatus.start();
    tasks.pollClusterNotifications.start();
    if (displayImportProcedure(store.getState())) {
      store.dispatch(openImportProcedureModal());
    }
    store.dispatch(fetchRealTimeConsumptionData());
    api.stopCollect();
  },
  [PATHS.CLUSTERS.BACKUPS]: async ({ id, flag }) => {
    await store.dispatch(getClusterByUid(id));

    if (flag === "restores") {
      await store.dispatch(restoresListActions.initialize(RESTORE_LIST_MODULE));
      tasks.pollRestoreList.start();
    }
    if (flag === "backups") {
      await store.dispatch(backupsListActions.initialize(BACKUP_LIST_MODULE));
      tasks.pollBackupList.start();
    }
  },
  [PATHS.CLUSTERS.NEW_CLUSTER]: () => {
    const hasFreemiumOverUsage =
      getFreemiumUsage(store.getState())?.usagePercent >= 100;

    if (hasFreemiumOverUsage) {
      history.push("/clusters/overview");
      return;
    }
    store.dispatch(clusterProfileListFetcher.fetch());
    store.dispatch(openNewClusterModal());
    store.dispatch(cloudAccountsSummaryFetcher.fetch());
  },
  [PATHS.CLUSTERS.SCANS]: async ({ id }) => {
    await store.dispatch(getClusterByUid(id));
    await store.dispatch(scansCountFetcher.key(id).fetch());
    watchScansTask.start();
  },
  [PATHS.CLUSTERS.SCAN_DETAILS]: async ({ id, scanUid, scanType }) => {
    await store.dispatch(getClusterByUid(id));
    store.dispatch(loadClusterScan(scanType, scanUid));
  },
  [PATHS.CLUSTERS.NODES]: async ({ id }) => {
    await store.dispatch(fetchClusterAndNodes(id));
    store.dispatch(fetchClusterEstimatedRate());
  },
  [PATHS.CLUSTERS.ROOT]: async ({ tab }) => {
    const context = getCurrentContext(store.getState());
    store.dispatch(cloudAccountsSummaryFetcher.fetch());
    async function loadClusters() {
      await store.dispatch(clusterListActions.initialize("cluster"));
      tasks.pollSpectroClusters.start();
    }

    if (context.isAdmin) {
      loadClusters();
      return;
    }

    if (tab === "overview") {
      await loadClusters();
    }
    await store.dispatch(
      edgeMachinesListActions.initialize(EDGE_MACHINES_MODULE)
    );
    tasks.pollAppliances.start();
  },
  [PATHS.CLUSTERS.TAB_DETAILS]: async ({ tab, id }) => {
    if (tab === "appliances") {
      await store.dispatch(fetchClusterAndNodes(id));
      await store.dispatch(
        appliancesListActions.initialize("cluster-appliances")
      );
    }
  },
  [PATHS.CLUSTERS.CREATE_CLUSTER]: async () => {
    await store.dispatch(initClusterWizard());

    if (store.getState().wizard?.cluster?.steps) {
      store.dispatch(
        wizardActions.updateSteps("cluster", CLUSTER_CREATION_STEPS)
      );
    }
  },
  [PATHS.CLUSTERS.CREATE_CLUSTER_WITH_SELECTED_PROFILE]: async ({
    profileUid,
  }) => {
    await store.dispatch(initClusterWizard());
    await store.dispatch(fetchClusterProfileByUid(profileUid));
    const clusterprofile = getRawClusterProfile(store.getState());
    const steps = store.getState().wizard?.cluster?.steps;

    if (clusterprofile.spec.published.type === "add-on") {
      return;
    }

    if (steps?.find((step) => step.id === "infra-profile")) {
      store.dispatch(
        wizardActions.updateSteps(
          "cluster",
          CLUSTER_CREATION_STEPS.filter((step) => step.id !== "infra-profile")
        )
      );
    }
  },
  [PATHS.CLUSTERS.CONFIGURE_LAYER]: async ({ id }) => {
    await store.dispatch(getClusterByUid(id));
    await store.dispatch(fetchClusterProfile());

    const attachedProfileUid = history.getQuery()?.attachedProfile;
    if (attachedProfileUid) {
      await store.dispatch(attachClusterProfile(attachedProfileUid));
    }
  },
  [PATHS.CLUSTERS.WORKLOADS]: async ({ id, flag: workloadTab }) => {
    await store.dispatch(fetchClusterAndNodes(id));
    store.dispatch(fetchClusterEstimatedRate());
    store.dispatch(initializeWorkloadsTab());
    store.dispatch(namespacesNamesFetcher.fetch());

    const pollers = tasks.clusterWorkloadPollersByTab[workloadTab];
    (pollers || []).forEach((polling) => polling.start());
    await store.dispatch(
      workloadsFilterFormActions.init({ module: "workloadsFilters" })
    );

    if (WORKLOADS_WITH_METRICS.includes(workloadTab)) {
      store.dispatch(onChartPeriodChange("6 hours"));
    }
  },
  [PATHS.CLUSTERS.CERTIFICATES]: ({ id }) => {
    store.dispatch(getClusterByUid(id));
    store.dispatch(certsListActions.initialize(CERTS_MODULE));
  },
  [PATHS.CLUSTERS.EVENTS]: ({ id }) =>
    store.dispatch(fetchClusterAndPollEvents(id)),
  [PATHS.CLUSTERS.COSTS]: async ({ id }) => {
    await store.dispatch(getClusterByUid(id));
    store.dispatch(initClusterCosts());
  },
  [PATHS.MANAGEMENT.ROOT]: ({ tab }) => {
    if (tab === "users") {
      store.dispatch(fetchRoles());
    }

    if (tab === "teams") {
      store.dispatch(usersMetaFetcher.fetch());
    }
  },
  [PATHS.AUTH.ROOT]: () => store.dispatch(fetchTenantInfo()),
  [PATHS.SETTINGS.CREATE_CLOUD_ACCOUNT]: ({ cloudType }) => {
    store.dispatch(openAccountModal({ cloudType }));
    if (cloudType === "openstack") {
      store.dispatch(openstackOverlordsFetcher.fetch());
    }
    if (cloudType === "vsphere") {
      store.dispatch(vmWareOverlordsFetcher.fetch());
    }
    if (cloudType === "maas") {
      store.dispatch(maasOverlordsFetcher.fetch());
    }
  },
  [PATHS.SETTINGS.CLOUD_ACCOUNT]: ({ cloudType, uid }) => {
    if (uid === "create") {
      return;
    }
    store.dispatch(openAccountModal({ cloudType, uid, viewMode: true }));
  },
  [PATHS.SETTINGS.CLOUD_RATES]: ({ cloudType, uid }) => {
    store.dispatch(cloudRatesFormActions.init({ module: "cloudrates" }));
  },
  [PATHS.SETTINGS.EDIT_CLOUD_ACCOUNT]: ({ cloudType, uid }) => {
    store.dispatch(openAccountModal({ cloudType, uid }));
    if (cloudType === "openstack") {
      store.dispatch(openstackOverlordsFetcher.fetch());
    }
    if (cloudType === "vsphere") {
      store.dispatch(vmWareOverlordsFetcher.fetch());
    }
    if (cloudType === "maas") {
      store.dispatch(maasOverlordsFetcher.fetch());
    }
  },
  [PATHS.PROJECTS.ROOT]: async () => {
    store.dispatch(coresChartTimePeriodInit());
    await store.dispatch(projectListActions.initialize(PROJECT_MODULE));
    store.dispatch(monthlyCoreHoursChartCalendarChange());

    await store.dispatch(
      organizationFilterFormActions.init({
        module: ORGANIZATION_OVERVIEW_FILTERS_MODULE,
      })
    );
    store.dispatch(initCpuCoresChart());
  },
  [PATHS.PROJECTS.CREATE_PROJECT]: () =>
    store.dispatch(openCreateProjectModal()),
  [PATHS.PROJECTS.EDIT_PROJECT]: async ({ id }) => {
    projectModal.open({ id, modalType: "edit" }).then(
      () => {
        return store.dispatch(
          projectFormActions.submit({ module: PROJECT_MODULE })
        );
      },
      () => {
        history.push(PATHS.PROJECTS.ROOT);
      }
    );
    store.dispatch(projectFormActions.init({ module: PROJECT_MODULE }));
  },
  [PATHS.SETTINGS.PRIVATE_CLOUD_GATEWAYS]: () => {
    store.dispatch(overlordsListActions.initialize(OVERLORD_MODULE));
    tasks.pollDatacenters.start();
  },
  [PATHS.SETTINGS.CREATE_PRIVATE_CLOUD_GATEWAY]: ({ cloudType }) => {
    store.dispatch(onCreateOverlordOpen(cloudType));
  },
  [PATHS.SETTINGS.EDIT_PRIVATE_CLOUD_GATEWAY]: ({ cloudType, uid }) => {
    store.dispatch(openOverlordConfigureModal(uid, cloudType));
  },
  [PATHS.SETTINGS.PRIVATE_CLOUD_GATEWAY_NODES]: ({ cloudType, uid }) => {
    store.dispatch(openSetOverlordNodesModal(uid, cloudType));
  },

  [PATHS.PRIVATE_CLOUD_GATEWAYS.IPAM_DNS_TABS]: ({ id, type, uid, tab }) => {
    if (id === "system") {
      store.dispatch({
        type: "FETCH_CLUSTER",
        promise: Promise.resolve({
          metadata: {
            name: "System Private Gateway",
            uid: "system",
            annotations: {
              overlordUid: uid,
            },
          },
          spec: {
            cloudType: type,
          },
        }),
        schema: ClusterSchema,
      });
    } else {
      store.dispatch(fetchClusterAndPollEvents(id));
    }
    if (tab === "ipam") {
      store.dispatch(ipamListActions.initialize(IPAM_MODULE));
    }
    if (tab === "dns") {
      store.dispatch(dnsListActions.initialize(DNS_MAPPING_MODULE));
    }
    store.dispatch(overlordFetcher.fetch());
  },

  [PATHS.SETTINGS.REGISTRIES]: ({ tab }) => tasks.pollRegistries.start(),
  [PATHS.SETTINGS.EDIT_REGISTRY]: ({ tab, uid, ociType }) => {
    if (tab === "pack") {
      store.dispatch(openPackRegistryModal(uid));
    }
    if (tab === "helm") {
      store.dispatch(openHelmRegistryModal(uid));
    }
    if (tab === "oci") {
      store.dispatch(openOciRegistryModal(uid, ociType));
    }
  },
  [PATHS.SETTINGS.CREATE_REGISTRY]: ({ tab }) => {
    if (tab === "pack") {
      store.dispatch(openPackRegistryModal());
    }
    if (tab === "helm") {
      store.dispatch(openHelmRegistryModal());
    }
    if (tab === "oci") {
      store.dispatch(openOciRegistryModal());
    }
  },

  [PATHS.SETTINGS.SSO]: async () => {
    if (
      Object.keys(store.getState().forms?.saml?.initialData || {}).length === 0
    ) {
      store.dispatch(ssoIdpsFetcher.fetch());
      store.dispatch(defaultTeamsFetcher.fetch());
      await store.dispatch(samlFormActions.init({ module: "saml" }));
      await store.dispatch(oidcFormActions.init({ module: "oidc" }));

      const enabledSso = getEnabledSSO(store.getState());

      store.dispatch({
        type: "SSO_TOGGLE",
        enabledSso,
      });

      store.dispatch(domainsFormActions.init({ module: "domains" }));
    }
  },

  [PATHS.SETTINGS.CREATE_SSH_KEY]: () => store.dispatch(openSSHKeyModal()),
  [PATHS.SETTINGS.EDIT_SSH_KEY]: ({ uid }) =>
    store.dispatch(openSSHKeyModal(uid)),

  [PATHS.SETTINGS.BILLING_DETAILS]: () => {
    store.dispatch(initAllBillingData());
    store.dispatch(invoicesFetcher.fetch());
  },
  [PATHS.SETTINGS.PLAN_DETAILS]: () => {
    store.dispatch(initAllBillingData());
  },
  [PATHS.SETTINGS.BACKUP_LOCATIONS]: () => {
    store.dispatch(
      backupLocationsListActions.initialize(BACKUP_LOCATIONS_MODULE)
    );
  },

  [PATHS.SETTINGS.CREATE_BACKUP_LOCATION]: () =>
    store.dispatch(openBackupLocationModal()),
  [PATHS.SETTINGS.EDIT_BACKUP_LOCATION]: ({ uid, provider }) =>
    store.dispatch(openBackupLocationModal({ uid, provider })),
  [PATHS.SETTINGS.ALERTS]: ({ tab }) => {
    if (tab === "email") {
      store.dispatch(
        projectEmailAlertsFormActions.init({ module: EMAIL_ALERTS_MODULE })
      );
    }

    if (tab === "webhooks") {
      store.dispatch(webhooksAlertsListing.initialize(WEBHOOK_ALERTS_MODULE));
    }
  },
  [PATHS.SETTINGS.CREATE_WEBHOOK_ALERT]: () =>
    webhookAlertModal.open().then(
      async () => {
        await store.dispatch(
          projectWebhookAlertsFormActions.submit({
            module: WEBHOOK_ALERTS_MODULE,
          })
        );
        history.push("/settings/alerts/webhooks");
      },
      () => history.push("/settings/alerts/webhooks")
    ),
  "/settings/certificates": () => {
    store.dispatch(certificatesListActions.initialize(CERTS_LIST_MODULE));
  },
  "/settings/certificates/create": () => store.dispatch(openCertificateModal()),
  "/settings/certificates/:uid/edit": ({ uid }) =>
    store.dispatch(openCertificateModal(uid)),

  [PATHS.SETTINGS.AUDIT_TRAILS]: () => {
    store.dispatch(auditTrailsListActions.initialize(AUDIT_TRAILS_LIST_MODULE));
  },
  [PATHS.SETTINGS.API_KEYS]: () => {
    store.dispatch(apikeysListActions.initialize(API_KEYS_LIST_MODULE));
    store.dispatch(usersMetaFetcher.fetch());
  },
  [PATHS.SETTINGS.CREATE_AUDIT_TRAIL]: () => {
    store.dispatch(openAuditTrailModal());
  },
  [PATHS.SETTINGS.CREATE_API_KEY]: () => {
    store.dispatch(openApiKeyModal());
    store.dispatch(fetchAllUsers());
  },
  [PATHS.SETTINGS.EDIT_API_KEY]: ({ uid }) => {
    store.dispatch(openApiKeyModal(uid));
  },
  [PATHS.SETTINGS.EDIT_AUDIT_TRAIL]: ({ uid }) => {
    store.dispatch(openAuditTrailModal(uid));
  },
  [PATHS.SETTINGS.MACROS]: () => {
    store.dispatch(macrosFormActions.init({ module: MACROS_FORM_MODULE }));
  },
  [PATHS.PROJECT_OVERVIEW.ROOT]: () => {
    store.dispatch(initProjectOverview());
  },
  [PATHS.WORKSPACES.ROOT]: ({ tab }) => {
    if (tab === "overview") {
      store.dispatch(workspaceListActions.initialize("workspaces"));
      store.dispatch(filtersFormActions.init({ module: FILTERS_FORM_MODULE }));
    }

    if (tab === "usagecost") {
      store.dispatch(initWorkspacesUsageCost());
    }
  },
  [PATHS.WORKSPACES.WORKSPACE_DETAILS_OVERVIEW]: async ({
    id,
    flag: overviewTab,
  }) => {
    await store.dispatch(getWorkspaceByUid(id));

    store.dispatch(initializeWorkloadsByTab(overviewTab));

    const pollers = tasks.workspaceWorkloadPollersByTab[overviewTab];
    (pollers || []).forEach((polling) => polling.start());

    await store.dispatch(
      workloadsFilters.init({ module: WORKLOADS_FILTERS_MODULE })
    );

    if (WORKLOADS_WITH_METRICS.includes(overviewTab)) {
      store.dispatch(onWorkspacePodsPeriodChange("6 hours"));
    }
  },
  [PATHS.WORKSPACES.BACKUPS]: async ({ id, flag }) => {
    await store.dispatch(getWorkspaceByUid(id));

    if (flag === "restores") {
      await store.dispatch(
        workspaceRestoresListActions.initialize(WORKSPACE_RESTORE_LIST_MODULE)
      );
      tasks.pollWorkspacesRestoreList.start();
    }
    if (flag === "backups") {
      await store.dispatch(
        workspaceBackupsListActions.initialize(WORKSPACE_BACKUP_LIST_MODULE)
      );
      tasks.pollWorkspacesBackupList.start();
    }
  },
  [PATHS.MY_API_KEYS.ROOT]: () => {
    store.dispatch(apikeysListActions.initialize(API_KEYS_LIST_MODULE));
  },
  [PATHS.MY_API_KEYS.CREATE]: () => {
    store.dispatch(openApiKeyModal());
  },
  [PATHS.MY_API_KEYS.EDIT]: ({ uid }) => {
    store.dispatch(openApiKeyModal(uid));
  },
};

// NOTE:
// the argument location contains the path you are going to navigate
// the state location contains the current path you are on
history.block((location) => {
  const state = store.getState();

  if (location.pathname.includes("auth")) {
    return;
  }

  if (
    history.location.pathname.includes("/profiles/") &&
    state.location?.params?.id &&
    state.location?.params?.id !== "create"
  ) {
    const profileModules = {
      system: systemProfileBuilderEditModule,
      cluster: profileBuilderEditModule,
    };

    const currentModule = profileModules[state.location?.params?.tab];

    const { getPackErrors, hasIncompleteLayers, hasUnsavedChanges } =
      currentModule.selectors;

    const hasErrors = getPackErrors(state).length !== 0;

    if (hasUnsavedChanges(state) || hasIncompleteLayers(state) || hasErrors)
      return i18next.t(
        "Are you sure you want to leave without saving your changes?"
      );
  }
});

function cleanUp() {
  stopAllPollingTasks();
  api.abortAllPending();
}

function stopAllPollingTasks() {
  Object.keys(tasks).forEach((key) => {
    if (key === "pollClusterLogStatus") {
      return;
    }
    tasks[key]?.stop?.();
  });
}

function getLocationMatch(path, location) {
  let match = matchPath(location.pathname, {
    path,
    exact: true,
    strict: true,
  });

  if (!match) {
    match = matchPath(location.pathname, {
      path: `${PROJECT_PREFIX}${path}`,
      exact: true,
      strict: true,
    });
  }

  if (!match) {
    match = matchPath(location.pathname, {
      path: `${ADMIN_PREFIX}${path}`,
      exact: true,
      strict: true,
    });
  }

  if (!match) {
    match = matchPath(location.pathname, {
      path: `${ADMIN_PREFIX_WITH_PROJECT}${path}`,
      exact: true,
      strict: true,
    });
  }

  return match;
}

export function onLocationChange(location, action) {
  if (action === "REPLACE") return;

  cleanUp();

  const projectMatch = matchPath(history.location.pathname, {
    path: "/projects/:projectUid/*",
    exact: true,
  });

  if (projectMatch) {
    store.dispatch(selectProjectFromRoute(projectMatch.params));
  }

  if (history.location.pathname.startsWith("/admin/")) {
    store.dispatch(selectProjectFromRoute({ projectUid: "ADMIN_VIEW" }));
  }

  Object.keys(MAP).forEach((path) => {
    const match = getLocationMatch(path, location);

    if (match) {
      store.dispatch({ type: "UPDATE_ROUTER_PARAMS", params: match.params });
      MAP[path](match.params, {
        isRouteMaintained: () => {
          const currentMatch = getLocationMatch(path, history.location);
          return currentMatch.path === match.path;
        },
      });
    }
  });
}

function startListening() {
  return history.listen(onLocationChange);
}

let listener = null;
export default function observeHistory() {
  listener = startListening();
  onLocationChange(history.location);
}

export function stopListening() {
  listener();
}
