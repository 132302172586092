import clusterFormActions from "../form";
import {
  getSelectedCredential,
  getVMwarePlacementOptions,
} from "state/cluster/selectors/create";
import { dnsFormActions, openInlineModal } from "state/dns/actions/create";
import { generateDomain } from "../utils";
import {
  datacentersFetcher,
  ipamFetcher,
  propertiesFetcher,
} from "state/cluster/services/create";
import { dnsMappingsFetcher } from "state/dns/services";

export function openDnsSettings() {
  return async function thunk(dispatch, getState) {
    const formData = getState().forms?.cluster?.data;
    const dns = formData.nodePools[0].domains[0].dns;
    const cloudAccount = getSelectedCredential(getState());
    const updates = {
      network: formData.nodePools[0].domains[0].network,
      datacenter: formData.datacenter,
      privateGatewayUid: cloudAccount.metadata.annotations.overlordUid,
    };

    await dispatch(
      openInlineModal((dns) => {
        dispatch(
          clusterFormActions.onChange({
            module: "cluster",
            name: "nodePools.0.domains.0.dns",
            value: dns,
          })
        );

        dispatch(
          clusterFormActions.validateField({
            name: `nodePools.0.domains.0.dns.spec.dnsName`,
            module: "cluster",
          })
        );
      }, dns?.metadata?.uid)
    );

    if (!dns) {
      dispatch(
        dnsFormActions.batchChange({
          module: "dnsMapping",
          updates,
        })
      );
    }
  };
}

function mapDomains(isMaster) {
  return function mappingFn(domain, index) {
    if (isMaster) {
      return { ...domain, ipamDisabled: index > 0 };
    }
    return domain;
  };
}

export function onDeleteDomain(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const poolIndex = pathParts[1];
    const domainIndex = pathParts[3];
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    let domains = [...nodePool.domains];
    domains.splice(domainIndex, 1);
    domains = domains.map(mapDomains(nodePool.isMaster));

    if (domains.length === 0) {
      domains.push(generateDomain());
    }
    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      domains,
    });

    const errorFields = ["cluster", "datastore", "network"].map(
      (field) => `nodePools.${poolIndex}.domains.${domainIndex}.${field}`
    );
    const formErrors = getState().forms.cluster.errors;
    const updatedErrors = formErrors.map((error) => {
      const shouldRemove = errorFields.includes(error.field);
      if (shouldRemove) {
        return { ...error, result: false };
      }

      return error;
    });

    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: updatedErrors,
      })
    );

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
  };
}

export function onAddDomain(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const poolIndex = pathParts[1];
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    let newDomain = generateDomain();
    if (nodePool.isMaster) {
      newDomain = {
        ...newDomain,
        parentPoolUid: nodePool.domains[0].parentPoolUid,
      };
    }

    let domains = [...nodePool.domains];
    domains.push(newDomain);
    domains = domains.map(mapDomains(nodePool.isMaster));

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      domains,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
  };
}

export function onNetworkChange(name, value) {
  return (dispatch, getState) => {
    const staticPlacementEnabled =
      getState().forms.cluster?.data.networkType === "staticIP";

    const pathParts = name.split(".");
    const domainIndex = pathParts[3];
    const poolIndex = pathParts[1];
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];
    const domains = [...nodePool.domains];
    domains.splice(domainIndex, 1, {
      ...domains[domainIndex],
      network: value,
    });

    if (
      !staticPlacementEnabled &&
      (nodePool.isMaster || nodePool.isControlPlane) &&
      domainIndex === "0"
    ) {
      const domain = domains[domainIndex];
      const properties = getVMwarePlacementOptions(getState());
      const selectedNetwork = properties[domain.cluster].networks.find(
        (option) => domain.network === option.value
      );

      if (selectedNetwork) {
        domains.splice(domainIndex, 1, {
          ...domains[domainIndex],
          dns: selectedNetwork.dnsMapping,
        });
      }
    }

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      domains,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: { nodePools },
      })
    );

    const relatedErrors = domains.reduce((accumulator, domain, index) => {
      if (domain.network === value && index !== domainIndex) {
        accumulator.push(index);
      }

      return accumulator;
    }, []);

    dispatch(
      clusterFormActions.validateField({
        name: relatedErrors.map(
          (index) => `nodePools.${poolIndex}.domains.${index}.network`
        ),
        module: "cluster",
      })
    );

    dispatch(
      clusterFormActions.validateField({
        name: `nodePools.${poolIndex}.domains.0.dns.spec.dnsName`,
        module: "cluster",
      })
    );
  };
}

export function onPoolChange(name, value, isMaster) {
  return (dispatch, getState) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name,
        value,
      })
    );

    if (isMaster) {
      const nodePools = [...getState().forms.cluster.data.nodePools].map(
        (nodePool) => {
          if (nodePool.isMaster) {
            return {
              ...nodePool,
              domains: nodePool.domains.map((domain) => ({
                ...domain,
                parentPoolUid: value,
              })),
            };
          }

          return nodePool;
        }
      );

      dispatch(
        clusterFormActions.batchChange({
          module: "cluster",
          updates: {
            nodePools,
          },
        })
      );
    }
  };
}

export function onDataclusterChange(name, cluster) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[3];
    const poolIndex = pathParts[1];
    const nodePools = [...getState().forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];
    const domains = [...nodePool.domains];

    const prevValue = domains[domainIndex].cluster;

    domains.splice(domainIndex, 1, {
      ...domains[domainIndex],
      cluster,
      datastore: "",
      network: "",
      resourcePool: "",
      dns: {},
    });
    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      domains,
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(propertiesFetcher.key(cluster).fetch());
    dispatch(dnsMappingsFetcher.fetch());

    if (prevValue !== "") {
      const relatedErrors = domains.reduce((accumulator, domain, index) => {
        if (domain.cluster === prevValue && index !== domainIndex) {
          accumulator.push(index);
        }

        return accumulator;
      }, []);

      dispatch(
        clusterFormActions.validateField({
          name: relatedErrors.map(
            (index) => `nodePools.${poolIndex}.domains.${index}.cluster`
          ),
          module: "cluster",
        })
      );
    }
  };
}

export function onDatacenterChange(datacenter) {
  return (dispatch, getState) => {
    const clusterType = getState().forms.cluster.data.clusterType;
    const updates = {
      datacenter,
    };

    if (clusterType !== "edge") {
      updates.folder = "";
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates,
      })
    );
  };
}

function selectCredentialEffect() {
  return function (dispatch) {
    dispatch(datacentersFetcher.fetch());
    dispatch(ipamFetcher.fetch());
  };
}

const effects = {
  selectCredentialEffect,
};

export default effects;
