import { createSelector } from "reselect";
import fetchers, { MODULES } from "./services";

function findRepositoryByUid(repos, repoUid) {
  return repos.find((repo) => repo.metadata.uid === repoUid);
}

export const getAllReposByPackType = createSelector(
  fetchers.repositoriesFetcher.selector,
  fetchers.helmRepositoriesFetcher.selector,
  fetchers.ociRepositoriesFetcher.selector,
  (state) => state.forms.layerPack?.data?.packType,
  (
    { result: repos = [] },
    { result: helmRepos = [] },
    { result: ociRepos = [] },
    packType
  ) => {
    if (!packType) return [];
    return packType === "helmChart"
      ? [
          ...(helmRepos || []),
          ...(ociRepos || []).map((repoInfo) => ({ kind: "oci", ...repoInfo })),
        ]
      : repos;
  }
);

export const getAvailableRepositories = createSelector(
  getAllReposByPackType,
  (repos) => {
    return (
      repos?.map((repo) => ({
        ...repo,
        label: repo.metadata.name,
        value: repo.metadata.uid,
      })) || []
    );
  }
);

export const arePacksLoading = createSelector(
  fetchers.clusterPacksFetcher.selector,
  (state) => state.forms?.importedPacks?.data?.clusterId,
  (result, clusterId) => result[clusterId]?.isLoading
);

export const getSelectedRepository = createSelector(
  (state) => state.forms[MODULES.PACK_MODULE]?.data?.repository,
  getAvailableRepositories,
  (repoUid, repos) => findRepositoryByUid(repos, repoUid)
);

export const getAllSystemRepositories = createSelector(
  fetchers.helmRepositoriesFetcher.selector,
  fetchers.ociRepositoriesFetcher.selector,
  ({ result: helmRepos = [] }, { result: ociRepos = [] }) => {
    return [
      ...(helmRepos || []),
      ...(ociRepos || []).map((repoInfo) => ({ kind: "oci", ...repoInfo })),
    ].map((repo) => ({
      ...repo,
      label: repo.metadata.name,
      value: repo.metadata.uid,
    }));
  }
);

export const getSelectedSystemRepository = createSelector(
  (state) => state.forms[MODULES.SYSTEM_MODULE]?.data?.repository,
  getAllSystemRepositories,
  (repoUid, repos) => findRepositoryByUid(repos, repoUid)
);

export const getSystemSelectedPack = createSelector(
  (state) => fetchers.systemChartNamesFetcher.selector(state)?.result?.items,
  (state) => state.forms[MODULES.SYSTEM_MODULE]?.data.pack,
  (items = [], packName) => {
    return items.find((pack) => pack.spec.name === packName);
  }
);
