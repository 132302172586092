export function generateDomain({ domain } = {}) {
  let base = domain;
  if (!domain) {
    base = {
      cluster: "",
      datastore: "",
      network: "",
      resourcePool: "",
      parentPoolUid: "",
      dns: {},
    };
  }

  return { ...base };
}
