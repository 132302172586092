import { createSelector } from "reselect";
import {
  packRegistriesFetcher,
  helmRegistriesFetcher,
  ociRepositoriesFetcher,
} from "./services";

export const getSelectedPackRegistry = createSelector(
  packRegistriesFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);

export const getSelectedHelmRegistry = createSelector(
  helmRegistriesFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);

export const getSelectedOCIRegistry = createSelector(
  ociRepositoriesFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);
