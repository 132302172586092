import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";

import Page from "components/common/layouts/Page";
import Link from "components/common/History/Link";
import noProjectsPath from "assets/images/login/no_projects.png";
import { white, lavender } from "utils/constants/colors";

const ContentWrap = styled.div`
  height: 504px;
  width: 1088px;
  background: ${white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const NoProjectsContent = styled.div`
  padding: 50px 63px;
  position: relative;

  h1 {
    font-size: 38px;
    line-height: 48px;
    color: rgb(77, 74, 190);
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    margin: 10px 0;
  }
`;

const Environment = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BackToAuthLink = styled(Link)`
  margin-top: 16px;
  font-size: 16px;
  color: ${lavender};
`;

function NoProjects() {
  return (
    <Page>
      <Wrap>
        <ContentWrap>
          <Environment src={noProjectsPath} alt="no projects environment" />
          <NoProjectsContent>
            <Trans>
              <h1> {"Sorry, no projects yet!"}</h1>
              <br />
              <p>
                {"You haven't been invited to any projects yet."}
                <br />
                {"Please contact your administrator."}
              </p>
            </Trans>
          </NoProjectsContent>
        </ContentWrap>
        <BackToAuthLink to="/auth">Back to auth page</BackToAuthLink>
      </Wrap>
    </Page>
  );
}

export default NoProjects;
