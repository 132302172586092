import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

import project_icon from "assets/icons/project_gray.svg";
import org_icon from "assets/images/org_icon.png";
import { blue, greenGray, fauxAliceBlue } from "utils/constants/colors";
import Permissions from "components/ui/Permissions";
import { TextButton } from "components/ui/Button";

//

const GroupLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #9fa9b7;

  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(218, 222, 227, 0.5);
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
`;

const Button = styled(TextButton)`
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${greenGray};
  background: #f7f8f9;
  box-shadow: inset 0px 1px 0px rgba(218, 222, 227, 0.5);
  height: 40px;
  font-weight: normal;
  border-radius: 0;
  :hover {
    background: ${fauxAliceBlue};
    color: ${blue};
  }
  ${(props) =>
    props.adminMode &&
    css`
      background: ${fauxAliceBlue};
      color: ${blue};
    `}

  svg {
    margin-right: 11px;
  }
`;

const SelectWrapper = styled.div`
  .ant-select {
    display: flex;
    align-items: center;
    width: 242px;
    height: 32px;
    background: #f7f8f9;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    color: #64748b;
  }

  .ant-select-selector:before {
    align-self: center;
    content: "";
    width: 18px;
    height: 18px;
    margin-right: 9px;
    background-image: url(${project_icon});
    background-repeat: no-repeat;
    background-size: 18px;
    border-radius: 3px;
    ${(props) =>
      props.isAdmin &&
      css`
        background-image: url(${org_icon});
      `}
  }

  .ant-select-selection-search {
    margin-left: 24px;
  }
`;

const StyledOrgIcon = styled.img`
  width: 15.9px;
  height: 15.9px;
  margin-right: 10px;
`;

const ContextDropdown = ({ selectProject, currentContext, projects }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const adminMode = !!currentContext?.isAdmin;

  function renderProject(context, index) {
    return (
      <Select.Option
        data-qa-uid={context.projectUid}
        data-qa-type="project"
        data-qa-index={index}
        key={context.guid}
        value={context.guid}
      >
        {context.project.metadata.name}
      </Select.Option>
    );
  }

  function renderDropdown(menu) {
    return (
      <>
        <GroupLabel>{t("Projects")} </GroupLabel>
        {menu}
        <Permissions isAdmin>
          <Button
            as="a"
            adminMode={adminMode}
            data-qa="admin-mode"
            onClick={() => {
              if (!adminMode) {
                selectProject("ADMIN_VIEW");
              }

              setOpen(false);
            }}
          >
            <StyledOrgIcon src={org_icon} /> {t("Tenant Admin")}
          </Button>
        </Permissions>
      </>
    );
  }

  return (
    <SelectWrapper isAdmin={adminMode}>
      <Select
        value={adminMode ? t("Tenant Admin") : currentContext?.guid}
        onChange={selectProject}
        dropdownClassName="sidebar-menu"
        bordered={false}
        dropdownRender={renderDropdown}
        open={open}
        onDropdownVisibleChange={setOpen}
        data-qa="context-switch"
        showSearch={true}
        optionFilterProp="children"
      >
        {projects?.map(renderProject)}
      </Select>
    </SelectWrapper>
  );
};

export default ContextDropdown;
